<template>
  <p class="text-content main-title">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle"
}
</script>

<style scoped lang="scss">
.text-content {
  &.main-title {
    font-size: 24px;
  }
}
</style>