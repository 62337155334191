<template>
  <modal-frame :main-title="mainTitle?.name || mainTitle"
               @submitInfo="submitInfo"
               :edit-active-alert="activeItemListState"
               :button-text="nameButton"
               :disabledButton="disabledButton">

      <input-main :active-glass="false"
                  :active-text-input="activeItem?.name"
                  :name-block="'modal-frame-container'"
                  @changeTextInput="changeTextInput"
                  :length-text-area="mainTitle?.length"
                  :info="infoInput"/>
  </modal-frame>
</template>

<script>
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import InputMain from "@/components/shared/InputMain.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "ModalArea",
  components: {ButtonMain, InputMain, ModalFrame, ButtonIcon, SubTitle},
  props: {
    mainTitle: {
      type: Object,
      required: true
    },
    activeItem: {
      default: undefined,
      required: true
    },
    infoInput: {
      type: Object,
      default: {
        id: 0,
        placeholder: 'Введите текст',
        dataIndex: 'text',
        type: 'textarea'
      }
    }
  },
  data() {
    return{
      textAlert: '',
      activeAlert: false,
      activeItemListState: false,
      textInput: '',
      nameButton: 'Сохранить',
      disabledButton: true
    }
  },
  mounted() {
    this.activeItemListState = this.changeActiveItemListState(this.activeItem)
  },
  methods: {
    changeTextInput(text) {
      this.textInput = text
      if(this.activeItemListState) {
        this.disabledButton = this.textInput === this.activeItem?.name
      } else {
        this.disabledButton = this.textInput.length === 0;
      }
    },
    submitInfo() {
        if(this.activeItem === undefined) {
          this.$emit('submitAddInfo', this.mainTitle.id, this.textInput)
        } else {
          this.$emit('submitEditInfo', this.activeItem.id, this.mainTitle.id, this.textInput)
        }
    },
  }
}
</script>

<style scoped lang="scss">


</style>