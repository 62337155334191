<template>
  <div class="switch-language-container row">
    <sub-title>{{mainTitle}}</sub-title>
    <check-box-main @changeActiveCheck="changeStateActiveLanguage"
                    :active-mount-check="activeMountCheck"
                    :name-check="0"/>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import CheckBoxMain from "@/components/shared/CheckBoxMain.vue";

export default {
  name: "SwitchLanguageTitle",
  components: {CheckBoxMain, SubTitle},
  props: {
    activeMountCheck: {
      type: Boolean
    }
  },
  data() {
    return {
      mainTitle: 'Английская версия'
    }
  },
  methods: {
    changeStateActiveLanguage(state) {
      this.$emit('changeStateActiveLanguage', state)
    }
  }
}
</script>

<style scoped lang="scss">
 .switch-language-container {
   width: 100%;
   justify-content: space-between;
   margin-bottom: 32px;
 }
</style>