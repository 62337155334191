<template>
  <div class="info-item-container row" :class="{'active-small' :  activeSmall}">
    <slot/>
  </div>
</template>

<script>
export default  {
  name: "FrameItem",
  props: {
    activeSmall: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.info-item-container {
  width: 100%;
  justify-content: space-between;
  padding: 16px 8px;
  border-radius: 10px;
  opacity: .6;
  background-color: rgba(1, 1, 1, 0);
  transition: opacity .5s ease;

  &:nth-child(n+1) {
    background-color: var(--color-field);
  }


    &.active-small {
       padding: 6px 16px 6px 8px;
     }

    &:hover {
       opacity: 1;
     }
}
</style>