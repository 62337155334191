<template>
  <label :for="'fileInput ' + activeInput" class="input-content" @mouseenter="focusFalse" @mouseleave="focusTrue">
    <input :accept="acceptFile" type="file" :id="'fileInput ' + activeInput"  @change="handleFileChange">
      <label :for="'fileInput ' + activeInput" class="icon-and-text-container">
        <slot/>
        <button-icon :img-name="'download'"/>
      </label>
  </label>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ModalCropImage from "@/components/entities/ModalCropImage.vue";
export default {
  name: "InputFile",
  components: {ModalCropImage, ButtonMain, ModalFrame, ButtonIcon},
  props: {
    activeInput: {
      required: true
    },
    acceptFile: {
      type: String,
      default: '.png, .jpg, .svg'
    }
  },
  data() {
    return {
      fileSrc: null,
    }
  },
  methods: {

    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileSrc = e.target.result;
          this.$emit('changeFile', this.fileSrc, file)
        };
        reader.readAsDataURL(file);
      }
    },
  },
}
</script>

<style scoped lang="scss">


.input-content {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  border: 2px dashed var(--color-stroke);
  background-color: var(--color-field);
  transition: opacity .5s ease;
  opacity: .6;
  white-space: pre-wrap;
  text-align: center;


  .icon-and-text-container {
    opacity: 0.6;
    line-height: 140%;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    .button-icon-container {
      margin-left: 24px;
    }
  }

  &:hover {
    opacity: 1;
  }
}


  #fileInput {
    position: absolute;
    z-index: 999;
  }

.input-content input[type="file"] {
  display: none;
}



</style>