<template>
  <div class="input-file-container active-photo-user-content">
    <transition name="fade-opacity-lazy">
      <div class="input-file-content row" v-if="!activeResult">
        <input-file :accept-file="'.png, .jpg, .svg, .webp'" :active-circle="activeCircle" :active-input="activeInput" @changeFile="handleFileChange">
          {{ imageTitlePhoto }}
        </input-file>
      </div>
      <div class="input-file-content column result" v-else>
        <div class="result-container">
          <img :src="fileCrossSrc" alt="result"/>
        </div>
        <input-file class="load"
                    :accept-file="'.png, .jpg, .svg, .webp'"
                    :active-input="activeInput"
                    @changeFile="handleFileChange"
                    @mouseenter="changeTitleResult"
                    @mouseleave="changeTitleResult">
          {{loadingTitle}}
        </input-file>
      </div>
    </transition>
    <transition name="fade-scale">
      <modal-crop-image :active-circle="activeCircle"
                        :active-rectangle="activeRectangle"
                        :active-square="false"
                        v-if="activeCropper"
                        :file-src="fileSrc"
                        @crossImage="crossImage"
                        @closeModal="closeModal"/>
    </transition>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ModalCropImage from "@/components/entities/ModalCropImage.vue";
import InputFile from "@/components/shared/InputFile.vue";
export default {
  name: "InputFileMainProfile",
  components: {InputFile, ModalCropImage, ButtonMain, ModalFrame, ButtonIcon},
  props: {
    activeInput: {
      default: 'photo'
    },
    activeImage: {
      type: String
    },
  },
  data() {
    return {
      focusParam: true,
      fileSrc: null,
      fileCrossSrc: null,
      cropper: null,
      activeCropper: false,
      croppedImageUrl: '',
      activeResult: false,
      imageTitlePhoto: 'Профиль\nЗагрузить фото',
      loadingTitle: 'Фотография загружена',
      fileTarget: '',
      activeResultTitle: false,
      activeCircle: false,
      activeRectangle: true,

      activeAvatar: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.activeImage !== undefined && this.activeImage !== null && this.activeImage !== '') {
        this.fileSrc = this.activeImage
        this.fileCrossSrc = this.activeImage
        this.activeResult = true
      }
    })
  },
  watch: {
    activeResultTitle() {
      if(this.activeResultTitle) {
        this.loadingTitle = 'Обновить фотографию'
      } else {
        this.loadingTitle = 'Фотография загружена'
      }
    }
  },
  methods: {

    closeModal() {
      this.activeCropper = false
      this.fileSrc = this.fileCrossSrc
      this.activeAvatar = false
      this.activeCircle = false;
      this.activeRectangle = true
    },

    handleFileChange(fileSrc, file) {
      this.fileSrc = fileSrc;
      this.fileTarget = file;
      this.activeCropper = true
    },

    crossImage(img) {
      this.activeCropper = false;
      this.activeResult = true;
      if(this.activeAvatar) {
        this.activeAvatar = false
        this.activeCircle = false;
        this.activeRectangle = true
        this.$emit('changeFileAvatar', {canvas: img, fileTarget: this.fileTarget})
      } else {
        this.fileCrossSrc = img.toDataURL();
        this.$emit('changeFilePhotoProfile', {canvas: img, fileTarget: this.fileTarget})
        setTimeout(() => {
          this.activeAvatar = true
          this.activeCropper = true;
          this.activeCircle = true;
          this.activeRectangle = false
        }, 250)
      }

    },

    changeTitleResult() {
      this.activeResultTitle = !this.activeResultTitle
    }
  },
}
</script>

<style scoped lang="scss">

.input-file-container {
  max-width: 400px;
  width: 100%;
  height: 586px;
  .input-file-content {
    max-width: 400px;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: space-between;
  }


  .input-content {
    &.load {
      height: 68px;
    }
  }

  .result-container {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;
    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  #fileInput {
    position: absolute;
    z-index: 999;
  }

  .input-file-content input[type="file"] {
    display: none;
  }

  &.active-photo-user-content {
    width: 100%;
    max-height: 586px;
    height: 100%;
    max-width: 374px;


    .input-file-content {
      height: 586px;
      max-width: 374px;
      width: 100%;

      &.result {
        width: 374px;
        height: 586px;
      }
    }

    .input-content {
      &.load {
        height: 32px;
      }
    }

    .result-container {
      height: 100%;
      max-width: 374px;
      width: 100%;
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 16px;
    }
  }
}



</style>