<template>
  <div class="image-input-container">
    <main-title>{{ mainTitle }} {{ additionalTitle }}</main-title>

    <frame-input-file @changeFile="changeFilePreview"
                      :active-post-content="true"
                      :active-circle="false"
                      :placeholder-text="textInputPreview + ' ' + additionalTitle"
                      :active-image="activePreview"/>

    <frame-more-input-file @changeMorePhoto="changeMorePhoto"
                           @activeDeletePhotoArray="activeDeletePhotoArray"
                           :placeholder-text="textInputMorePhoto + ' ' + additionalTitle"
                           :active-list-image="activeListImage"/>
  </div>
</template>

<script>
import FrameInputFile from "@/components/entities/FrameInputFile.vue";
import FrameMoreInputFile from "@/components/entities/FrameMoreInputFile.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";

export default {
  name: "CardPhotoNewsCase",
  components: {MainTitle, FrameMoreInputFile, FrameInputFile},
  props: {
    activePreview: {
      default: undefined
    },
    activeListImage: {
      type: Array
    },
    additionalTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      textInputPreview: 'Загрузить превью',
      textInputMorePhoto: 'Загрузить дополнительные фото',
      mainTitle: 'Фотографии',
      resultPreview: '',
      resultMorePhoto: [],
      activePreviewState: false,
      activeListImgState: false,
    }
  },


  watch: {
    disabledButton() {
      this.checkDisableButton();
    }
  },

  computed: {


    activeItemListState() {
      return this.activePreviewState || this.activeListImgState;
    },

    validActive() {
      return this.resultPreview !== '';
    },

    disabledButton() {
      if(this.activeItemListState) {
        let ifPreview = this.resultPreview !== this.activePreview;
        let ifMorePhoto = !this.deepEqual(this.resultMorePhoto, this.activeListImage)
        return !(ifPreview || ifMorePhoto);
      } else {
        return false;
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.checkDisableButton();
      this.activePreviewState = this.changeActiveItemListState(this.activePreview);
      this.activeListImgState = this.changeActiveItemListState(this.activeListImage);
      this.checkMountedItem();
    })

  },
  methods: {

    checkDisableButton() {
      this.$emit('changeDisableButton', this.disabledButton, 'photo', this.resultMorePhoto < 1)
      if(!this.disabledButton) {
        if (!this.activeItemListState) {
          this.$emit('changeData', this.resultPreview, this.resultMorePhoto)
        }
      }
    },

    activeDeletePhotoArray(arr) {
      this.$emit('activeDeletePhotoArray', arr)
    },

    changeFilePreview(fileSrc) {
      this.resultPreview = fileSrc;
      this.$emit('changeData', this.resultPreview, this.resultMorePhoto)
    },
    changeMorePhoto(fileArr) {
      this.resultMorePhoto = fileArr
      this.$emit('changeData', this.resultPreview, this.resultMorePhoto)
    },
    checkMountedItem() {
      if(this.activePreviewState) {
        this.resultPreview = this.activePreview
      }
      if(this.activeListImgState) {
        this.resultMorePhoto = this.activeListImage
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .image-input-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 32px;
  }
</style>