<template>
  <div class="modal-frame-container">
    <div class="modal-frame-content column">
      <transition name="fade-opacity-lazy">
        <sub-title class="alert-content" v-if="activeAlert">
          {{ textAlert }}
        </sub-title>
      </transition>
      <div class="main-title-container row" v-if="activeClose">
        <sub-title>{{mainTitle}}</sub-title>
        <button-icon @click="closeModal" :img-name="'cross'"/>
      </div>
      <div class="main-title-container row" v-else>
        <main-title> {{ mainTitle }} </main-title>
      </div>
      <slot/>
      <button-main :active-button="false"
                   @click="submitInfo"
                   :disabled-button="disabledButton">
        {{buttonText}}
      </button-main>
    </div>
    <transition name="fade-opacity-lazy">
      <div v-if="activeModal" class="modal-background" @click.self="closeModal">
      </div>
    </transition>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
export default {
  name: "ModalFrame",
  components: {MainTitle, ButtonMain, ButtonIcon, SubTitle},
  props: {
    activeClose: {
      type: Boolean,
      default: true
    },
    mainTitle: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    disabledButton: {
      type: Boolean
    },
    editActiveAlert: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeModal: false,
      textAlert: '',
      activeAlert: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.activeModal = true
    },350)
  },
  methods: {
    closeModal() {
      if(this.activeClose) {
        this.activeModal = false
        setTimeout(() => {
          this.$emit('closeModal')
        }, 400)
      }
    },
    submitInfo() {
      if(this.disabledButton) {
        if (!this.editActiveAlert) {
          this.textAlert = 'Введите данные!'
        } else {
          this.textAlert = 'Внесите изменения!'
        }
        this.activeAlert = true;
        setTimeout(() => {
          this.activeAlert = false;
        }, 500)
      } else {
        this.activeModal = false
        setTimeout(() => {
          this.$emit('submitInfo')
        }, 400)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-frame-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 998;
    background-color: rgba(1,1,1,.5);
  }

  .modal-frame-content {
    margin: 0 16px;
    z-index: 999;
    max-width: 646px;
    width: 100%;
    padding: 32px;
    background-color: var(--color-background);
    border-radius: 32px;
    border: 3px solid var(--color-stroke);
    position: relative;


    .alert-content {
      position: absolute;
      top: 16px;
      left: auto;
      color: var(--color-stroke);
    }

    .main-title-container {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;

      padding: 16px 0;
    }

    .button-icon-container {
      opacity: .6;
      transition: opacity .5s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .main-button-container {
      align-self: center;
      margin-top: 24px;
    }
  }
}

@media (max-width: 769px) {
  .modal-frame-container {
    .modal-frame-content {
      padding: 18px;

      .alert-content {
        top: 9px;
      }

      .main-title-container {
        padding: 8px 0;
      }
    }
  }
}

@media (max-width: 375px) {
  .modal-frame-container {
    .modal-frame-content {
      padding: 16px;

      .alert-content {
        top: 8px;
      }
    }
  }
}

@media (max-width: 375px) {
  .modal-frame-container {
    .modal-frame-content {
      padding: 14px;

      .alert-content {
        top: 7px;
      }
    }
  }
}

</style>