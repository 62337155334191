<template>
  <div class="text-menu-container" :class="{'active' : activeTextMenu}">
    <button-icon :img-name="infoText.img"/>
    <transition name="fade-translate-x">
      <text-menu v-if="activeText"
                 :active-text-menu="activeTextMenu">
        {{infoText.text}}
      </text-menu>
    </transition>
  </div>
</template>

<script>
import TextMenu from "@/components/shared/TextMenu.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "IconTextMenu",
  components: {ButtonIcon, TextMenu},
  props: {
    infoText: {
      type: Object,
      required: true
    },
    activeTextMenu: {
      type: Boolean,
      required: true
    },
    activeText: {
      type: Boolean,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
  .text-menu-container {
    cursor: pointer;
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    opacity: .6;
    transition: opacity .5s ease;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    .button-icon-container {
      margin-right: 20px;
    }
  }

</style>