<template>
    <div class="frame-pagination-container row">
      <vue-awesome-paginate
          :total-items="numPagination"
          v-model="activeNum"
          :items-per-page="5"
          @update:model-value="changePage"
          :max-pages-shown="3"/>
    </div>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import {VueAwesomePaginate} from "vue-awesome-paginate";
export default {
  name: "FramePagination",
  components: {VueAwesomePaginate, MainParagraph, ButtonMain},
  props: {
    numPagination: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeNum: 1,
    }
  },
  mounted() {
    this.activeNum = this.currentPage
  },
  watch: {
    currentPage() {
      this.activeNum = this.currentPage
    }
  },
  methods: {
    changePage() {
      console.log(this.activeNum)
      this.$emit('changePage', this.activeNum)
    }
  }
}
</script>

<style scoped lang="scss">
.frame-pagination-container {
  margin-top: 16px;
  align-self: center;
}
</style>