<template>
  <div class="news-screen-container main-container" v-if="!isLoading">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                   @cancelInfo="activeModal = false"
                   :sub-title="activeModalTitle"/>
    </transition>

    <list-frame class="news"
                :num-pagination="totalCountNewsAll"
                :current-page="Number(page)"
                :active-focus="true"
                :config-list="'pagination'"
                :active-text-input="search"
                @changeSearchText="changeSearchText"
                @editItemInfo="editItemList"
                @addObject="changeStateAddItem"
                @deleteBackendInfo="deleteBackendInfo"
                @changePageList="checkInfoPage"
                :setting-list="settingList"
                :name-block="nameBlock"
                :name-button="mainNameButton"
                :active-add-object="activeState"
                :info-list="translationsList"
                :main-title-d-o-m="mainTitle"/>
    <transition name="fade-translate-x">
    <card-add-info-with-editor v-if="activeState"
                               @addResult="addResult"
                               :additional-sub-title="'новости'"
                               :active-item-list="activeItemNews"
                               :input-info="inputInfo"
                               :related-person-info-list="activePersonItem"
                               :related-practice-info-list="activePracticeItem"/>
    </transition>
  </div>
</template>

<script>
import ListFrame from "@/components/entities/FrameListContainer.vue";
import CardNewsCase from "@/components/entities/CardNewsCase.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import SwitchLanguageTitle from "@/components/entities/SwitchLanguageTitle.vue";
import CardRelated from "@/components/entities/ListInfo/CardRelated.vue";
import CardAddInfoWithEditor from "@/components/entities/CardAddInfoWithEditor.vue";
import {mapActions, mapGetters} from "vuex";
import ModalAlert from "@/components/entities/ModalAlert.vue";

export default {
  name: "NewsScreen",
  components: {ModalAlert, CardAddInfoWithEditor, CardRelated, SwitchLanguageTitle, SubTitle, CardNewsCase, ListFrame},
  props: ['page', 'search'],
  data() {
    return {
      mainTitle: 'Список новостей',
      searchText: '',
      nameButtonType: ' новость',
      nameButtonCRUD: 'Добавить',
      nameBlock: 'news',
      titlePractice: 'Связанные практики',
      titlePerson: 'Связанные персоны',

      activeModalTitle: '',
      typeTitle: 'Новость',
      activeModal: false,
      activeEnding: 'а',

      activeState: false,

      settingList: {
        activeTitle: true,
        activeInput: true,
        activeSmallIcon: false,
        activeEdit: true,
        activeButton: true,
      },

      activeItemNews: undefined,

      activeEn: false,

      inputInfo: [
        {
          id: 0,
          placeholder: 'Введите текст',
          dataIndex: 'name',
          type: 'textarea',
        },
        {
          id: 1,
          placeholder: 'Введите текст',
          dataIndex: 'shortName',
          type: 'textarea',
        }
      ],
      idItem: -1
    }
  },


  async created() {
    await this.checkInfo();
  },

  watch: {
    idItem() {
      if(this.idItem !== -1) {
        this.activeItemNews =  this.findById(this.newsAll, this.idItem);
      } else {
        this.activeItemNews = undefined
      }
    },
    async searchText() {
      await this.checkInfoPage(1);
    },
  },

  computed: {
    ...mapGetters(['newsAll', 'practiceAll', 'getUsersAll', 'totalCountNewsAll']),

    mainNameButton() {
      return this.nameButtonCRUD.concat(this.nameButtonType);
    },

    translationsList() {
      try {
        return this.checkKeysList(this.newsAll, 'translations','name','ru');
      } catch (e) {
        return undefined
      }
    },

    activePracticeItem() {
      return this.activeItemNews?.practices?.map(item => item.id);
    },

    activePersonItem() {
      return this.activeItemNews?.users?.map(item => item.id);
    },
  },
  methods: {
    ...mapActions(['getNews', 'getUsers', 'getPractice']),
    changeStateActiveLanguage() {
      this.activeEn = !this.activeEn;
    },


    async checkInfo() {
      let promisePractice;
      if(this.practiceAll === undefined) {
        promisePractice = await this.getPractice()
      }
      let promiseUsers;
      if(this.getUsersAll === undefined) {
        promiseUsers = await this.getUsers()
      }
      try {
        await Promise.all([promisePractice, promiseUsers, this.getNews({
          params: {
            limit: this.$store.state.DEFAULT_PAGE_LIMIT,
            page: this.page,
            search: this.search || this.searchText
          }
        })])
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING(false)
        this.IS_LOADING_SEARCH(false)
      }
    },

    async checkInfoPage(currentPage) {
      if(this.searchText === '') {
        this.IS_LOADING_SEARCH(true)
        this.$router.push({name: 'news', params: {page: currentPage}}).then(async () => {
          this.$store.dispatch('IS_LOADING', false)
          await this.checkInfo();
        })
      } else {
        await this.checkInfoSearch(currentPage);
      }

    },
    async checkInfoSearch(currentPage) {
      this.$router.push({name: 'news-search', params: {page: currentPage, search: this.searchText}}).then(async () => {
        this.$store.dispatch('IS_LOADING', false)
        await this.checkInfo();
      })
    },

    addResult(item, typeCRUD, disabledButton) {
      this.addResultPost(this.idItem, item, typeCRUD, disabledButton, 'POST', this.activeItemNews);
    },

    async deleteBackendInfo(id) {
      await this.deleteBackendInfoNewsCase(id)
    },

  }
}
</script>

<style scoped lang="scss">
.news-screen-container {
  .add-news-container {
    width: 100%;
    .news-item-container {
      width: 100%;
    }
  }

}
</style>