<template>
  <div class="login-screen-container">
    <div class="logo-container">
      <transition name="fade-opacity-lazy">
        <img v-if="activeColor" :src="getUrl(imgName + '-dark.svg')" alt="logo">
        <img v-else :src="getUrl(imgName + '-light.svg')" alt="logo">
      </transition>
    </div>

    <div class="login-frame-container">
      <transition name="fade-opacity-lazy">
      <sub-title class="error-message-content" v-if="statusErrorActive">{{ textError }}</sub-title>
      </transition>
      <div class="title-container">
        <p class="title-text">{{ loginText }}</p>
        <check-box-theme :name-check="`screen`"/>
      </div>

      <form @submit.prevent="submitData" class="login-input-container">

        <input-main v-for="info in infoInput"
                    :key="info.id"
                    class="password-content"
                    :active-glass="false"
                    :info="info"
                    @changeTextInput="changeTextInput"
                    :name-block="'login-input-container'"/>
        <button-main :active-button="activeButton"
                     :disabled-button="!isValid">
          {{ buttonText }}
        </button-main>
      </form>
    </div>
  </div>
</template>

<script>
import CheckBoxSub from "@/components/shared/CheckBoxSub.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import InputMain from "@/components/shared/InputMain.vue";
import CheckBoxTheme from "@/components/entities/CheckBoxTheme.vue";
import {api} from "@/providers/api";
import {currentAuth, setCurrentUser, setUserAuthToken} from "@/providers/auth";
import SubTitle from "@/components/shared/Text/SubTitle.vue";

export default {
  name: "LoginScreen",
  components: {SubTitle, CheckBoxTheme, InputMain, ButtonMain, CheckBoxSub},
  data() {
    return {
      imgName: 'logo',
      activeButton: false,
      statusErrorActive: false,

      loginText: 'Личный кабинет',
      buttonText: 'Войти',

      textError: '',

      infoInput: [
        {
          id: 0,
          placeholder: 'Email',
          type: 'email',
          dataIndex: 'email'
        },
        {
          id: 1,
          placeholder: 'Пароль',
          type: 'password',
          dataIndex: 'password'
        }
      ],

      dataLog: {
        email: '',
        password: '',
      },
    }
  },

  mounted() {
    this.IS_LOADING(false)
    this.checkAuth();
  },
  computed: {
    isValid() {
      return this.isValidInput(this.dataLog)
    },
    isEmailValid() {
      return this.dataLog.email !== '';
    },
    isPasswordValid() {
      return this.dataLog.password !== '';
    },
  },
  methods: {
    checkAuth() {
      if(currentAuth.loggedIn) {
        this.$router.push('/admin/user-profile');
      }
    },
    changeTextInput(text, position) {
      this.dataLog[position] = text;
    },


    async submitData() {
      this.IS_LOADING(true)
      if (this.isValid) {
        try {
          let response = await api.auth.authControllerLogin({
            email: this.dataLog.email,
            password: this.dataLog.password
          }).then(r => r.data);
          setUserAuthToken(response.token);
          await setCurrentUser(await api.auth.authControllerGetMe().then(r => r.data).finally(this.$router.push({path: '/admin/user-profile'})))
        } catch (err) {
          this.checkError(err.response.data.message);
        } finally {
          this.IS_LOADING(false)
        }
      } else {
        this.IS_LOADING(false)
        this.checkValid();
      }
    },

    checkValid() {
      if(!this.isPasswordValid && !this.isEmailValid) {
        this.checkError(2)
      } else if( !this.isPasswordValid) {
        this.checkError(1)
      } else {
        this.checkError(0)
      }
    },

    checkError(statusCode) {
      switch (statusCode) {
        case 0:
          this.textError = 'Введите почту!'
           break;
        case 1:
          this.textError = 'Введите пароль!'
          break;
        case 2:
          this.textError = 'Введите все данные!'
          break;
        case 'user not found':
          this.textError = 'Такого пользователя не существует!'
          break;
        case 'wrong password':
          this.textError = 'Неверный пароль!'
          break;
      }
      this.statusErrorActive = true
      setTimeout(() => {
        this.statusErrorActive = false
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">

.login-screen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 18px;
  max-width: 1447px;
  justify-content: space-between;
  align-items: center;


  .logo-container {
    position: relative;
    max-width: 447px;
    max-height: 733px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .login-frame-container {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-between;
    max-width: 448px;
    width: 100%;

    .error-message-content {
      top: -32px;
      position: absolute;
      color: var(--color-stroke)
    }

    .title-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .title-text {
        color: var(--color-stroke)
      }

      .check-box-container {
        margin-right: 3px;
      }
    }

    .login-input-container {
      width: 100%;
      margin-bottom: 32px;
      position: relative;



      .main-button-container {
        margin: 0 auto;
      }

      .input-container {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .login-screen-container {
    flex-direction: column;
    justify-content: center;

    .logo-container {
      max-width: 200px;
      max-height: 400px;
      margin-bottom: 32px;
    }

    .login-frame-container {
      margin: 0;
    }
  }
}

@media (max-width: 375px) {
  .login-screen-container {
    justify-content: center;

    .logo-container {
      max-width: 150px;
      max-height: 350px;
    }
  }
}

@media (max-width: 320px) {
  .login-screen-container {
    justify-content: center;

    .logo-container {
      max-width: 100px;
      max-height: 300px;
    }
  }
}

</style>
