<template>
  <div class="main-menu-container" :class="{'not-active-title': !activeMaxWidth}">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                     @cancelInfo="activeModal = false"
                     :sub-title="'Тема сайта успешно изменена'"/>
    </transition>

    <div class="main-menu-text-container">
      <button-icon class="item"
                   @click="closeTextMenu"
                   :img-name="'burger'"/>
      <icon-text-menu class="item" v-for="item in activeMenuItem"
                      :key="item.id"
                      @click="changeActiveTextMenu(item.id, item.path)"
                      :active-text="activeTitleText"
                      :active-text-menu="item.id === activeText"
                      :info-text="item"/>
    </div>

      <div class="main-menu-theme-container" v-if="activeTheme !== ''">
        <transition name="fade-opacity-lazy">
        <div class="main-menu-theme-content" v-if="activeTitleText">
          <div class="theme-container site" v-if="activeRole === 'ADMIN'">
            <button-icon :img-name="'site-theme'"/>
            <main-paragraph>{{themeSite}}</main-paragraph>
            <check-box-sub @changeActiveCheck="changeSiteTheme"
                           :local-active="activeTheme"
                           :name-check="'site'"/>
          </div>
          <div class="theme-container admit">
            <button-icon :img-name="'admin-theme'"/>
            <main-paragraph>{{themeAdmin}}</main-paragraph>
            <check-box-theme :name-check="'admin'"/>
          </div>
        </div>

        <div class="main-menu-theme-content" v-else>
          <div class="theme-container site" v-if="activeRole === 'ADMIN'">
            <button-icon :img-name="'site-theme'"/>
            <check-box-sub @changeActiveCheck="changeSiteTheme"
                           :local-active="activeTheme"
                           :name-check="'site'"/>
          </div>
          <div class="theme-container admin">
            <button-icon :img-name="'admin-theme'"/>
            <check-box-theme :name-check="'admin'"/>
          </div>
        </div>
        </transition>
      </div>
  </div>
</template>

<script>
import LogoIcon from "@/components/shared/LogoIcon.vue";
import IconTextMenu from "@/components/shared/IconTextMenu.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import CheckBoxSub from "@/components/shared/CheckBoxSub.vue";
import CheckBoxTheme from "@/components/entities/CheckBoxTheme.vue";
import {mapActions, mapGetters} from "vuex";
import {ROLE, removeUserAuthToken} from "@/providers/auth";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import {api} from "@/providers/api";
import ModalAgreement from "@/components/entities/ModalAgreement.vue";
import ModalAlert from "@/components/entities/ModalAlert.vue";

export default {
  name: "ControllerMain",
  components: {
    ModalAlert,
    ModalAgreement, ButtonMain, CheckBoxTheme, CheckBoxSub, MainParagraph, ButtonIcon, IconTextMenu, LogoIcon},
  props: {
    innerWidth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeModal: false,
      nameButtonExit: 'Выйти',
      activeText: 0,
      activeTitleText: true,
      activeMaxWidth: true,
      themeSite: 'Тема сайта',
      themeAdmin: 'Тема админки',
      widthActive: 250,
      infoMenu: [
        {
          id: 0,
          text: 'Личная страница',
          img: 'user-page',
          path: '/admin/user-profile'
        },
        {
          id: 1,
          text: 'Список сотрудников',
          img: 'user-list',
          path: '/admin/user-list'
        },
        {
          id: 2,
          text: 'Новости',
          name: 'news',
          img: 'news',
          path: '/admin/news/1'
        },{
          id: 3,
          text: 'Кейсы',
          name: 'case',
          img: 'cases',
          path: '/admin/case/1'
        },
        {
          id: 4,
          text: 'Отрасли',
          img: 'areas',
          name: 'industry',
          path: '/admin/industry/1'
        },
        {
          id: 5,
          text: 'Практики',
          img: 'practices',
          name: 'practice',
          path: '/admin/practice/1'
        },
        {
          id: 6,
          text: 'Услуги',
          img: 'services',
          name: 'services',
          path: '/admin/services/1'
        },
        {
          id: 7,
          text: 'Партнеры',
          img: 'partners',
          path: '/admin/partners'
        },
        {
          id: 8,
          text: 'Рейтинг',
          img: 'rating',
          path: '/admin/rating'
        },

        {
          id: 9,
          text: 'Инструкция',
          img: 'information',
          path: 'https://visioners.notion.site/IM-Legal-a4a11031f97542eea6a528de6adfb888?pvs=4'
        },

        {
          id: 10,
          text: 'Выйти',
          img: 'exit',
          path: ''
        }
      ],
    }
  },

  mounted() {
    this.checkActiveId();
  },

  computed: {

    activeTheme() {
      return this.$store.state.activeTheme;
    },

    activeRole() {
      return ROLE;
    },

    activeMenuItem() {
        switch(ROLE) {
          case 'ADMIN':
            return this.infoMenu;
          case 'PERSON':
            return [this.infoMenu[0], this.infoMenu[9], this.infoMenu[10]];
          case 'MODERATOR':
            return [this.infoMenu[0], this.infoMenu[2], this.infoMenu[3], this.infoMenu[9], this.infoMenu[10]];
        }
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MOBILE_MENU', 'ACTIVE_THEME']),


    changeActiveTextMenu(id, path) {
      if(path === '') {
        removeUserAuthToken();
        this.$router.go(0)
      } else if (path === 'https://visioners.notion.site/IM-Legal-a4a11031f97542eea6a528de6adfb888?pvs=4') {
        window.open('https://visioners.notion.site/IM-Legal-a4a11031f97542eea6a528de6adfb888?pvs=4', '_blank');
      } else {
        this.activeText = id
        this.$router.push({path: path})
      }
      if(this.innerWidth < 769) {
        this.ACTIVE_MOBILE_MENU(false)
      }
    },
    closeTextMenu() {
      if(this.activeMaxWidth) {
        this.activeTitleText = !this.activeTitleText
        setTimeout(() => {
          this.activeMaxWidth = false
        }, 500)
      } else {
        this.activeMaxWidth = true
        setTimeout(() => {
          this.activeTitleText = !this.activeTitleText
        }, 500)
      }

    },

    async changeSiteTheme(state) {
      if(state) {
        await api.admin.userAdminControllerSetTheme({theme: 'DARK'}).then(r => {
          this.ACTIVE_THEME()
          this.activeModal = true
        })
      } else {
        await api.admin.userAdminControllerSetTheme({theme: 'LIGHT'}).then(r => {
          this.ACTIVE_THEME()
          this.activeModal = true
        })
      }
    },

    checkActiveId() {
      if(this.$route.path.includes('add-user') || this.$route.path.includes('edit-user')) {
        this.activeText = 1
      } else {
        this.infoMenu.forEach(item => {
          if(item.path === this.$route.path || this.$route.path.includes(item?.name)) {
            this.activeText = item.id
          }
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
  .main-menu-container {
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    padding: 32px;
    height: 100%;
    width: 100%;
    max-width: 245px;
    //transition-delay: .5s;

    &.not-active-title {
      max-width: 140px;
    }

    .main-menu-text-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .button-icon-container {
        opacity: .6;
        transition: opacity .5s ease;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      .item {
        margin-bottom: 16px;

        &:nth-last-child(2) {
          margin-top: 32px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }


    .main-menu-theme-container {
      margin-bottom: 64px;
      position: relative;
      .main-menu-theme-content {
        .theme-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 190px;
          margin-bottom: 32px;

          .text-content {
            width: 96px;
            margin-right: 16px;
          }

          .button-icon-container {
            margin-right: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .text-content {
            opacity: .6;
          }
        }
      }

    }
  }

  @media (min-width: 769px) {
    .main-menu-container {
      transition: max-width .5s ease;
    }
  }

  @media (max-width: 769px) {
    .main-menu-container {
      max-width: 100%;
      z-index: 996;
      position: fixed;
      padding: 18px;
      .main-menu-text-container {
        margin-bottom: 18px;
        .button-icon-container {
          display: none;
        }

        .item {
          margin-bottom: 18px;


          &:nth-last-child(2) {
            margin-top: 18px;
          }
          &:last-child {
            margin-bottom: 24px;
          }
        }
      }

      .main-menu-theme-container {
        .main-menu-theme-content {
          .theme-container {
            width: 250px;
            .text-content {
              width: 125px;
              &.main-text {
                font-size: 18px;
              }
            }
          }
        }
      }

    }
  }

  @media (max-width: 375px) {
    .main-menu-container {
      padding: 0 16px 16px;
      .main-menu-text-container {
        margin-bottom: 18px;
        .button-icon-container {
          display: none;
        }

        .item {
          margin-bottom: 14px;
          &:nth-last-child(2) {
            margin-top: 14px;
          }
          &:last-child {
            margin-bottom: 18px;
          }
        }
      }

      .main-menu-theme-container {
        .main-menu-theme-content {
          .theme-container {
            margin-bottom: 24px;

            .text-content {
              &.main-text {
                font-size: 16px;
              }
            }
          }
        }

      }

    }
  }

  @media (max-width: 320px) {
    .main-menu-container {
      padding: 0 16px 16px;
      .main-menu-text-container {
        margin-bottom: 8px;
        .item {
          margin-bottom: 8px;

          &:nth-last-child(2) {
            margin-top:  8px;
          }
          &:last-child {
            margin-bottom: 11px;
          }
        }
      }

      .main-menu-theme-container {
        .main-menu-theme-content {
          .theme-container {
            margin-bottom: 10px;

            .text-content {
              &.main-text {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
</style>