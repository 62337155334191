<template>
  <p class="text-content large">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "LargeTitle"
}
</script>

<style scoped lang="scss">
  .text-content {
    &.large {
      font-size: 32px;
    }
  }
</style>