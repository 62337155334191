<template>
  <div class="card-add-info-container ">

    <div class="item-info-container editor">
      <card-news-case @changeData="changeData"
                      @changeDisableButton="changeDisabledButton"
                      :active-language="'ru'"
                      :active-item="ruItemActive"
                      :input-info="inputInfo"
                      :additional-title="additionalSubTitle"/>

      <switch-language-title @changeStateActiveLanguage="changeStateActiveLanguage"
                             :active-mount-check="activeEn"/>

      <transition name="fade-translate-x">
        <card-news-case v-if="activeEn"
                        @changeDisableButton="changeDisabledButton"
                        @changeData="changeData"
                        :active-language="'en'"
                        :active-item="enItemActive"
                        :input-info="inputInfo"
                        :additional-title="additionalSubTitle"/>
      </transition>
    </div>

    <date-publication @changeDatePublication="changeDatePublication"
                      :active-value="activeItemList?.publishDate"/>

    <card-photo-news-case @changeDisableButton="changeDisabledButton"
                          @activeDeletePhotoArray="activeDeletePhotoArray"
                          @changeData="changePhotoData"
                          :active-preview="activeItemList?.preview"
                          :active-list-image="activeItemList?.photos"
                          :additional-title="additionalSubTitle"/>



    <div class="related-card-container practice">
      <card-related @changeRelatedCard="changeRelatedCardPractice"
                    @checkLengthResultRelatedCard="checkLengthPractice"
                    :name-block="'practice'"
                    :search-request-name="'title'"
                    :active-info-list="relatedPracticeInfoList"
                    :empty-info-list="practiceAll"
                    :main-title="titlePractice"/>
    </div>
    <div class="related-card-container person">
      <card-related @changeRelatedCard="changeRelatedCardPerson"
                    @checkLengthResultRelatedCard="checkLengthPerson"
                    :name-block="'person'"
                    :name-modification-arr="'profiles'"
                    :name-modification-arr-property="'fullName'"
                    :search-request-name="'title'"
                    :empty-info-list="getUsersAll"
                    :active-info-list="relatedPersonInfoList"
                    :main-title="titlePerson"/>
    </div>


    <transition name="fade-opacity-lazy" mode="out-in">
    <div class="button-submit-container" v-if="isEmptyArr">
      <transition name="fade-opacity-lazy">
        <sub-title class="alert-content" v-if="activeStateAlert">
          {{ alertText }}
        </sub-title>
      </transition>
      <button-main @click="addResultEmptyArr"
                   :active-button="false"
                   :disabled-button="true">
        {{ nameButton }}
      </button-main>
    </div>
    <div class="button-submit-container" v-else>
      <transition name="fade-opacity-lazy">
        <sub-title class="alert-content" v-if="activeStateAlert">
          {{ alertText }}
        </sub-title>
      </transition>
        <button-main @click="addResult"
                     :active-button="false"
                     :disabled-button="disabledButton">
          {{ nameButton }}
        </button-main>
    </div>
  </transition>

  </div>
</template>


<script>
import CardRelated from "@/components/entities/ListInfo/CardRelated.vue";
import SwitchLanguageTitle from "@/components/entities/SwitchLanguageTitle.vue";
import CardNewsCase from "@/components/entities/CardNewsCase.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import DropdownDate from "@/components/shared/DropdownDate.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import DatePublication from "@/components/entities/DatePublication.vue";
import {mapGetters} from "vuex";
import CardPhotoNewsCase from "@/components/entities/CardPhotoNewsCase.vue";
import {transliterate} from "transliteration";

export default {
  name: "CardAddInfoWithEditor",
  components: {
    CardPhotoNewsCase,
    DatePublication,
    SubTitle, VueDatePicker,DropdownDate, ButtonMain, CardNewsCase, SwitchLanguageTitle, CardRelated},
  props: {
    additionalSubTitle: {
      type: String,
      required: true
    },
    inputInfo: {
      type: Array,
      required: true
    },

    relatedPersonInfoList: {
      default: undefined,
      required: true
    },
    relatedPracticeInfoList: {
      default: undefined,
      required: true
    },

    activeItemList: {
      default: undefined,
      required: true
    },
  },
  data() {
    return {
      disabledButton: true,
      activeEn: false,
      activeItemListState: false,
      titlePractice: 'Связанные практики',
      titlePerson: 'Связанные персоны',
      nameButton: 'Сохранить',
      datePublication: '',
      disabledButtonRuEn: {
        ru: true,
        en: true,
        personRelated: true,
        practiceRelated: true,
        datePublication: true
      },

      isEmptyPhoto: false,
      isEmptyPerson: false,
      isEmptyPractice: false,

      deletePhotoId: [],

      resultTranslationsRu: {},
      resultTranslationsEn: {},
      resultPreview: '',
      resultMorePhoto: [],
      resultPerson: [],
      resultPractice: [],


      alertText: '',
      activeStateAlert: false,
    }
  },

  mounted() {
    this.checkMountedItem();
  },

  computed: {
    ...mapGetters(['practiceAll', 'getUsersAll']),


    enItemActive() {
      if(this.activeItemListState) {
        return this.checkLangCode(this.activeItemList,
                                  'en',
                                 'translations',
                                'lang');
      }
    },
    ruItemActive() {
      if(this.activeItemListState) {
        return this.checkLangCode(this.activeItemList,
                                  'ru',
                                'translations',
                               'lang');
      }
    },
    isEmptyArr() {
      return (this.isEmptyPractice) || (this.isEmptyPerson);
    }
},

  methods: {

    activeDeletePhotoArray(arr) {
      this.deletePhotoId = arr;
    },


    changePhotoData(preview, photos) {
      this.resultPreview = preview
      this.resultMorePhoto = photos
    },

    changeDatePublication(item, state) {
      this.datePublication = item
      this.disabledButtonRuEn.datePublication = state
      this.checkAllLangDisabledButton();
    },

    changeRelatedCardPractice(item, state) {
      this.disabledButtonRuEn.practiceRelated = state
      this.resultPractice = item
      this.checkAllLangDisabledButton();
    },

    changeRelatedCardPerson(item, state) {
      this.disabledButtonRuEn.personRelated = state
      this.resultPerson = item
      this.checkAllLangDisabledButton();
    },

    checkLengthPractice(length) {
      this.isEmptyPractice = length
    },

    checkLengthPerson(length) {
      this.isEmptyPerson = length
    },


    changeDisabledButton(state, key, lengthArr) {
      if(key !== 'photo') {
        this.disabledButtonRuEn[key] = state
        this.checkAllLangDisabledButton();
      } else if(key === 'photo' && this.activeItemListState) {
        this.disabledButtonRuEn[key] = state
        this.checkAllLangDisabledButton();
      }
      // if(state === 'photo') {
      //   if(this.activeItemListState) {
      //     this.isEmptyPhoto = false
      //   } else {
      //     this.isEmptyPhoto = state
      //   }
      // }
    },

    checkAllLangDisabledButton() {
      if(this.activeItemListState) {
        if(this.activeEn && this.enItemActive !== undefined) {
          console.log('Активна модалка, активен англ')
          Object.values(this.disabledButtonRuEn).includes(false)
              ? this.disabledButton = false
              : this.disabledButton = true
        } else if (!this.activeEn && this.enItemActive !== undefined) {
          console.log('Не активна модалка, активен англ')
          this.disabledButton = false
        } else if (!this.activeEn && this.enItemActive === undefined) {
          console.log('Не активна модалка, не активен англ')
          this.disabledButton = this.countDisabledButton();
        } else if (this.activeEn && this.enItemActive === undefined) {
          console.log('Активна модалка, не активен англ')
          !this.disabledButtonRuEn.en
                ? this.disabledButton = false
                : this.disabledButton = true
        }
      } else {
        Object.values(this.disabledButtonRuEn).every(item => item !== true)
            ? this.disabledButton = false
            : this.disabledButton = true
      }
    },

    countDisabledButton() {
      let countFalse = 0;
      for (const key in this.disabledButtonRuEn) {
        if (this.disabledButtonRuEn[key] === false) {
          countFalse++;
        }
      }
      return countFalse < 2;
    },

    changeStateActiveLanguage(state) {
      this.activeEn = state;
      if (this.enItemActive !== undefined) {
        this.resultTranslationsEn = this.enItemActive;
        this.disabledButtonRuEn.en = this.activeEn
      } else if (this.resultTranslationsEn !== undefined && !this.activeEn) {
        this.resultTranslationsEn = undefined
        this.disabledButtonRuEn.en = false
      } else {
        this.resultTranslationsEn = undefined
        this.disabledButtonRuEn.en = false
      }
      this.checkAllLangDisabledButton()
    },

    checkMountedItem() {
      this.activeItemListState = this.changeActiveItemListState(this.activeItemList);
      if(!this.activeItemListState || this.enItemActive === undefined) {
        this.activeEn = false
        this.disabledButtonRuEn.en = false
      } else {
        this.activeEn = true
      }

      if(this.activeItemListState) {
        this.resultPerson = this.activeItemList?.users.map(item => item.id)
        this.resultPractice = this.activeItemList?.practices.map(item => item.id)
        this.datePublication = this.activeItemList?.publishDate
      }
    },

    changeData(textResult, lang) {
      switch (lang) {
        case 'ru':
          this.resultTranslationsRu = textResult;
          break;
        case 'en':
          this.resultTranslationsEn = textResult;
          break;
      }
    },


    addResultEmptyArr() {
      if(this.activeItemListState) {
          this.isEmptyPerson ? this.alertText = 'Заполните блок персон' :
            this.isEmptyPractice ? this.alertText = 'Заполните блок практик' :
                this.alertText = 'Для обновления данных внесите изменения.'
      } else {
          this.isEmptyPerson ? this.alertText = 'Заполните блок персон' :
            this.isEmptyPractice ? this.alertText = 'Заполните блок практик' :
                this.alertText = 'Введите все данные!'
      }

      this.activeStateAlert = true;
      setTimeout(() => {
        this.activeStateAlert = false;
      }, 500)
    },

    addResult() {
      if(this.disabledButton) {
        this.checkAlert();
      } else {
          let resultTextObj = {};
          if(!this.activeEn) {
            resultTextObj = {
              translations: [this.resultTranslationsRu]
            }
          } else {
            resultTextObj = {
              translations: [this.resultTranslationsRu, this.resultTranslationsEn]
            }
          }
          let slugUpdate = '';
          if(this.activeItemListState) {
            if(!this.disabledButtonRuEn.ru) {
              slugUpdate = this.resultTranslationsRu?.name
            } else {
              slugUpdate = this.ruItemActive?.name
            }
          } else {
            slugUpdate = this.resultTranslationsRu?.name
          }
          this.$emit('addResult', {
            deletePhotoId: this.deletePhotoId,
            translations: resultTextObj.translations,
            publishDate: this.datePublication,
            preview: this.resultPreview,
            photo: this.resultMorePhoto,
            users: this.resultPerson,
            practices: this.resultPractice,
            slug: transliterate(slugUpdate, { replace: {' ': '-'}}),
          }, this.activeItemListState, this.disabledButtonRuEn)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card-add-info-container  {
  padding: 24px;
  position: relative;
  width: 100%;

  .button-submit-container {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;

    .alert-content {
      position: absolute;
      bottom: -32px;
    }
  }



  .related-card-container {
    background-color: var(--color-background);
    margin-bottom: 32px;
  }
}

@media (max-width: 769px) {
  .card-add-info-container {
    padding: 0;

    .button-submit-container {

      .alert-content {
        bottom: -24px;
      }
    }
  }
}

</style>