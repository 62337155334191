<template>
  <p class="text-content sub">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "SubTitle"
}
</script>

<style scoped lang="scss">
.text-content {
  &.sub {
    line-height: normal;
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .text-content {
    &.sub {
      line-height: normal;
      font-size: 14px;
    }
  }
}
</style>