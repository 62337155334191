import {ApiClient} from '@/api/api.client';

export const api = new ApiClient({
    baseURL: process.env.VUE_APP_API_URL,
    securityWorker: (securityData) => ({headers: {'Authorization': `Bearer ${securityData}`}})
});

export function isApiAuthTokenSet(token) {
    return api.securityData === token
}

export function setApiAuthToken(token) {
    api.setSecurityData(token);
}

export function removeApiAuthToken() {
    api.setSecurityData(undefined);
}
