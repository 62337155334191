<template>
  <frame-item :active-small="true">
    <button-state-icon @click="changeStateButton"
                       :img-name="'border-star'"
                       :active-button="activeButton"/>
    <main-paragraph v-if="titleButton !== undefined">{{titleButton}}</main-paragraph>
  </frame-item>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import ButtonStateIcon from "@/components/shared/ButtonStateIcon.vue";
import FrameItem from "@/components/shared/FrameItem.vue";

export default {
  name: "RadioButton",
  components: {FrameItem, ButtonStateIcon, MainParagraph},
  props: {
    activeButton: {
      type: Boolean,
      required: true
    },
    titleButton: {
      type: String,
      required: true
    }
  },
  methods: {
    changeStateButton() {
      this.$emit('changeStateButton')
    }
  }
}
</script>

<style scoped lang="scss">
.info-item-container {
  justify-content: flex-start;

  .button-state-icon-container {
    margin-right: 8px;
  }
}
</style>