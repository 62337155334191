<template>
  <div class="login-screen-container">
    <transition name="fade-opacity-lazy">
      <modal-frame button-text="Перейти на главную страницу"
                   v-if="activeModal"
                   @submitInfo="routToHome"
                   main-title="Пароль успешно изменён"
                   :active-close="false"/>
    </transition>

    <div class="logo-container">
      <transition name="fade-opacity-lazy">
        <img v-if="activeColor" :src="getUrl(imgName + '-dark.svg')" alt="logo">
        <img v-else :src="getUrl(imgName + '-light.svg')" alt="logo">
      </transition>
    </div>

    <div class="login-frame-container">
      <transition name="fade-opacity-lazy">
        <sub-title class="error-message-content" v-if="statusErrorActive">{{ textError }}</sub-title>
      </transition>
      <div class="title-container">
        <p class="title-text">{{ passwordTitleText }}</p>
        <div class="title-icon-container">
          <check-box-theme :name-check="`screen`"/>
          <div class="title-show-pass-container" @click="showPassword">
            <button-icon v-if="isShowPassword" img-name="eyes"/>
            <button-icon v-else img-name="not-eyes"/>
          </div>
        </div>
      </div>

      <form @submit.prevent="submitData" class="login-input-container">

        <input-main v-for="info in infoInput"
                    :key="info.id"
                    class="password-content"
                    :active-glass="false"
                    :info="info"
                    @changeTextInput="changeTextInput"
                    :name-block="'login-input-container'"/>
        <button-main :active-button="activeButton"
                     @click="submitInfo"
                     :disabled-button="disabledButton">
          {{ buttonText }}
        </button-main>
      </form>
    </div>
  </div>
</template>

<script>
import {currentAuth, removeUserAuthToken} from "@/providers/auth";
import CheckBoxTheme from "@/components/entities/CheckBoxTheme.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import {api} from "@/providers/api";
import ModalFrame from "@/components/entities/ModalFrame.vue";

export default {
  name: "PasswordScreen",
  components: {ModalFrame, ButtonIcon, ButtonMain, InputMain, SubTitle, CheckBoxTheme},
  data() {
    return {
      imgName: 'logo',
      activeButton: false,
      statusErrorActive: false,

      passwordTitleText: 'Обновить пароль',
      buttonText: 'Обновить',

      isShowPassword: false,

      textError: '',

      infoInput: [
        {
          id: 0,
          placeholder: 'Пароль',
          type: 'password',
          dataIndex: 'password'
        },
        {
          id: 1,
          placeholder: 'Повторите пароль',
          type: 'password',
          dataIndex: 'passwordDuplicate'
        }
      ],

      dataLog: {
        password: '',
        passwordDuplicate: '',
      },

      resetPasswordToken: '',
      activeModal: false
    }
  },

  mounted() {
    this.IS_LOADING(false)
    this.checkAuth();
    this.resetPasswordToken = this.$route.query.reset_password_token
  },
  computed: {
    isValid() {
      return this.isValidInput(this.dataLog)
    },
    isPasswordValid() {
      return this.dataLog.password !== '';
    },
    isPasswordDuplicateValid() {
      return this.dataLog.passwordDuplicate !== '';
    },
    isEqual() {
      if(this.isValid) {
        return this.dataLog.password === this.dataLog.passwordDuplicate
      } else {
        return false;
      }
    },

    disabledButton() {
      return !this.isValid || !this.isEqual;
    }
  },
  methods: {

    routToHome() {
      this.activeModal = false
      setTimeout(() => {
        this.$router.push('/')
      }, 250)
    },

    showPassword() {
      this.isShowPassword = !this.isShowPassword
      if(this.isShowPassword) {
        this.infoInput[0].type = 'text'
        this.infoInput[1].type = 'text'
      } else {
        this.infoInput[0].type = 'password'
        this.infoInput[1].type = 'password'
      }
    },

    checkAuth() {
      if(currentAuth.loggedIn) {
        removeUserAuthToken();
      }
    },

    changeTextInput(text, position) {
      this.dataLog[position] = text;
    },

    async submitInfo() {
      if(this.disabledButton) {
        this.checkValid();
      } else {
        try {
          await api.auth.authControllerResetPasswordConfirm({
            reset_token: this.resetPasswordToken
          }, {
            password: this.dataLog.password.trim()
          }).then(r => {
            this.activeModal = true;
          })
        } catch (e) {
          this.checkError(e.response.data.statusCode)
        }
      }
    },

    checkValid() {
      if(!this.isPasswordValid && !this.isPasswordDuplicateValid) {
        this.checkError(2)
      } else if( !this.isPasswordDuplicateValid) {
        this.checkError(1)
      } else if (!this.isPasswordValid){
        this.checkError(0)
      } else {
        this.checkError(3)
      }
    },

    checkError(statusCode) {
      switch (statusCode) {
        case 0:
          this.textError = 'Введите пароль!'
          break;
        case 1:
          this.textError = 'Введите повторно пароль!'
          break;
        case 2:
          this.textError = 'Введите все данные!'
          break;
        case 3:
          this.textError = 'Пароль должен совпадать!'
          break;
      }
      if(statusCode > 3) {
        this.textError = 'Ошибка'
      }
      this.statusErrorActive = true
      setTimeout(() => {
        this.statusErrorActive = false
      }, 500)
    },

    submitData() {
      if(this.disabledButton) {
        this.checkValid();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-screen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 18px;
  max-width: 1447px;
  justify-content: space-between;
  align-items: center;


  .logo-container {
    position: relative;
    max-width: 447px;
    max-height: 733px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .login-frame-container {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-between;
    max-width: 448px;
    width: 100%;

    .error-message-content {
      top: -32px;
      position: absolute;
      color: var(--color-stroke)
    }

    .title-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .title-icon-container {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 8px;
        align-items: center;

        .title-show-pass-container {
          cursor: pointer;
          opacity: .6;
          transition: opacity .5s ease;

          &:hover {
            opacity: 1;
          }
        }
      }

      .title-text {
        color: var(--color-stroke)
      }

      .check-box-container {
        margin-right: 3px;
      }
    }

    .login-input-container {
      width: 100%;
      margin-bottom: 32px;
      position: relative;



      .main-button-container {
        margin: 0 auto;
      }

      .input-container {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .login-screen-container {
    flex-direction: column;
    justify-content: center;

    .logo-container {
      max-width: 200px;
      max-height: 400px;
      margin-bottom: 32px;
    }

    .login-frame-container {
      margin: 0;
    }
  }
}

@media (max-width: 375px) {
  .login-screen-container {
    justify-content: center;

    .logo-container {
      max-width: 150px;
      max-height: 350px;
    }
  }
}

@media (max-width: 320px) {
  .login-screen-container {
    justify-content: center;

    .logo-container {
      max-width: 100px;
      max-height: 300px;
    }
  }
}
</style>