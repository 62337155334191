<template>
  <div class="card-edit-container">
    <list-frame @changeStateRadioButton="changeStateRadioButton"
                @addObject="addObject"
                @editItemInfo="editItemInfo"
                :active-button-title="false"
                @deleteBackendInfo="deleteBackendInfo"
                :active-radio-button="false"
                :info-list="infoList"
                :name-button="nameButton"
                :setting-list="settingList"
                :main-title-d-o-m="propertyItem.title"/>
  </div>
</template>

<script>
import ListFrame from "@/components/entities/FrameListContainer.vue";

export default {
  name: "CardEdit",
  components: {ListFrame},
  props: {
    infoList: {
      type: Array,
    },
    propertyItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      nameButton: 'Добавить',
      settingList: {
        activeTitle: false,
        activeInput: false,
        activeSmallIcon: true,
        activeEdit: true,
        activeButton: true,
        activeEditDelete: true
      }
    }
  },
  methods: {
    addObject() {
      this.$emit('addObject', this.propertyItem.id)
    },
    editItemInfo(id) {
      this.$emit('editItemInfo', id, this.propertyItem.id)
    },
    deleteBackendInfo(id) {
      this.$emit('deleteBackendInfo', id, this.propertyItem.id)
    },
    changeStateRadioButton(id, state) {
      this.$emit('changeStateRadioButton', id, state)
    },
  }
}
</script>

<style scoped lang="scss">
.card-edit-container {
  width: 100%;
}
</style>