import {api} from "@/providers/api";

export const userModules = {

    state: () => ({
        activeMe: undefined,
        infoUser: undefined,
        activeUser: {},
        infoEducationLevel: []
    }),
    getters:{
        getUsersAll: (state) => {
            return state.infoUser
        },

        getEducationLevelAll: (state) => {
            return state.infoEducationLevel;
        },

        getMeInfo: (state) => {
            return state.activeMe;
        },

        getActiveUser: (state) => {
            return state.activeUser;
        }

    },
    mutations: {
        SET_ME(state, userInfo) {
            state.activeMe = userInfo
        },
        SET_USERS(state, userInfo) {
            state.infoUser = userInfo
        },
        SET_ACTIVE_USER(state, userInfo) {
            state.activeUser = userInfo
        },
        SET_EDUCATION_LEVEL(state, userInfo) {
            state.infoEducationLevel = userInfo
        },
    },
    actions: {
        async getUsers({commit}, { params } = { params: {}}) {
            let response = await api.admin.userAdminControllerGetUsers({
                ...params
            }).then(r => r.data);
            commit('SET_USERS', response?.data)
        },
        async getActiveUser({commit}, { params, id } = { params: {}, id: id}) {
            let response = await api.admin.userAdminControllerGetUser(id).then(r => r.data)
            if(response !== null && response !== undefined) {
                commit('SET_ACTIVE_USER', response)
                return response
            } else {
                this.$router.push('/admin/user-list');
            }
        },
        async getEducationLevel({commit}, { params } = { params: {}}) {
            let response = await api.admin.userAdminControllerGetLevels({
                ...params
            }).then(r => r.data);
            commit('SET_EDUCATION_LEVEL', response)
        },
        async getMe({commit}, { params } = { params: {}}) {
            let response = await api.auth.authControllerGetMe({
                ...params
            }).then(r => r.data);
            commit('SET_ME', response)
            return response
        },
    }
}