<template>
  <div class="list-frame-container column">
    <div class="main-title-container row" :class="{'sub-list' : !settingList.activeInput}">
      <main-title v-if="settingList.activeTitle">
        {{ mainTitleDOM }}
      </main-title>
      <sub-title v-else>
        {{ mainTitleDOM }}
      </sub-title>
      <button-main class="active-title" v-if="settingList.activeButton && activeButtonTitle"
                                     @click="addObject"
                                     :disabled-button="false"
                                     :active-button="activeAddObject">
      <transition name="fade-opacity-lazy">
        <span :key="nameButton">
           {{ nameButton }}
        </span>
      </transition>
    </button-main>
    </div>

    <input-main v-if="settingList.activeInput"
                @changeTextInput="changeTextInput"
                :activeFocus="activeFocus"
                :active-glass="true"
                :active-text-input="activeTextInput"
                :name-block="nameBlock"
                :info="infoInput"/>

    <info-list-content @changeStateRadioButton="changeStateRadioButton"
                       @changePageList="changePage"
                       @deleteBackendInfo="deleteBackendInfo"
                       @editItemInfo="editItemInfo"
                       :num-pagination="numPagination"
                       :current-page="currentPage"
                       :info-list="infoList"
                       :config-list="configList"
                       :active-edit-delete="settingList.activeEditDelete"
                       :active-radio-button="activeRadioButton"
                       :active-small="settingList.activeSmallIcon"
                       :active-edit="settingList.activeEdit"/>
    <button-main v-if="settingList.activeButton && !activeButtonTitle"
                 @click="addObject"
                 :disabled-button="false"
                 :active-button="activeAddObject">
      <transition name="fade-opacity-lazy">
        <span :key="nameButton">
           {{ nameButton }}
        </span>
      </transition>
    </button-main>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import InfoListContent from "@/components/shared/InfoListContent.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";

export default {
  name: "FrameList",
  components: {SubTitle, ButtonMain, InfoListContent, InputMain, MainTitle},
  props: {
    numPagination: {
      type: Number
    },
    configList: {
      type: String,
      default: 'scroll'
    },

    activeTextInput: {
      type: String
    },

    activeFocus: {
      type: Boolean,
      default: false
    },

    currentPage: {
      type: Number,
      required: true
    },

    activeButtonTitle: {
      type: Boolean,
      default: true
    },

    mainTitleDOM: {
      type: String,
      required: true
    },
    settingList: {
      type: Object,
      required: true
    },
    infoList: {
      required: true
    },
    nameButton: {
      type: String,
    },
    activeAddObject: {
      type: Boolean,
    },
    nameBlock: {
      type: String
    },
    activeRadioButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      infoInput: {
        id: 0,
        placeholder: 'Введите запрос',
        type: 'text',
        dataIndex: 'search',
      },
    }
  },
  methods: {
    changePage(id) {
      this.$emit('changePageList', id)
    },
    changeTextInput(text) {
      this.$emit('changeSearchText', text)
    },
    addObject() {
      this.$emit('addObject')
    },
    deleteBackendInfo(id) {
      console.log(id)
      this.$emit('deleteBackendInfo', id)
    },
    editItemInfo(id) {
      this.$emit('editItemInfo', id)
    },
    changeStateRadioButton(id) {
      this.$emit('changeStateRadioButton', id)
    },
  }
}
</script>

<style scoped lang="scss">
  .list-frame-container {
    width: 100%;

    .main-title-container {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 16px;

      &.sub-list {
        margin-bottom: 8px;
      }
    }

    .input-container {
      margin-bottom: 16px;
    }

    .main-button-container {
      align-self: center;
      margin-top: 16px;

      &.active-title {
        margin-top: 0;
      }
    }
  }
</style>