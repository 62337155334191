<template>
  <list-frame @changeSearchText="changeSearchText"
              @changeStateRadioButton="changeStateRadioButton"
              :active-radio-button="true"
              :main-title-d-o-m="mainTitle"
              :name-block="nameBlock"
              :setting-list="settingList"
              :info-list="sortedInfo"
              />
</template>

<script>
import ListFrame from "@/components/entities/FrameListContainer.vue";

export default {
  name: "CardRelated",
  components: {ListFrame},
  props: {
    mainTitle: {
      type: String,
      required: true
    },
    emptyInfoList: {
      required: true,
      default: undefined
    },
    activeInfoList: {
      required: true,
      default: undefined
    },
    nameBlock: {
      type: String,
      required: true
    },
    nameModificationArr: {
      type: String,
      default: 'modifications'
    },
    nameModificationArrProperty: {
      type: String,
      default: 'name'
    }
  },
  data() {
    return {
      searchText: '',
      settingList: {
        activeTitle: true,
        activeInput: true,
        activeSmallIcon: true,
        activeEdit: false,
        activeButton: false,
        activeEditDelete: false
      },
      isDisableButton: true,
      resultRadioButtonList: []
    }
  },
  mounted() {
    if(!this.isEmptyActiveList) {
        this.resultRadioButtonList = JSON.parse(JSON.stringify(this.activeInfoList));
    }
    this.checkLengthArr()
  },

  watch: {
    isEmpty() {
     this.$emit('checkLengthResultRelatedCard', this.isEmpty)
    }
  },

  computed: {

    isEmpty() {
      return this.resultRadioButtonList < 1;
    },

    infoList() {
      if(this.emptyInfoList !== undefined && this.activeInfoList !== null) {
        let modificationsArr = JSON.parse(JSON.stringify(this.emptyInfoList.map(item => ({
          id: item.id,
          item: item?.[this.nameModificationArr],
          state: false
        }))));
        let stateArr;

        if(!this.isEmptyActiveList) {
          stateArr = modificationsArr.map(item => ({
            id: item.id,
            name: item.item?.[0]?.[this.nameModificationArrProperty] || 'empty name',
            state: this.activeInfoList.includes(item.id)
          }));
          return stateArr.sort((itemOne, itemTwo) => {
            if (itemOne.state && !itemTwo.state) {
              return -1;
            }
            if (!itemOne.state && itemTwo.state) {
              return 1;
            }
            return 0;
          })
        } else {
          stateArr = modificationsArr.map(item => ({
            id: item.id,
            name: item.item?.[0]?.[this.nameModificationArrProperty] || 'empty name',
            state: false
          }));
          return stateArr;
        }
      } else {
        return undefined;
      }

    },
    isEmptyActiveList() {
      return !(this.activeInfoList !== undefined && this.activeInfoList !== null);
    },
    sortedInfo() {
      this.IS_LOADING_SEARCH(false)
      if(this.searchText !== '') {
        return this.infoList.filter(item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
      } else {
        return this.infoList;
      }
    }
  },

  methods: {
    checkLengthArr() {
      this.$emit('checkLengthResultRelatedCard', this.isEmpty)
    },

    changeStateRadioButton(id) {
      let findIndex = this.resultRadioButtonList.findIndex(index => id === index)
      if(findIndex === -1) {
        this.resultRadioButtonList.push(id)
      } else {
        this.resultRadioButtonList.splice(findIndex, 1)
      }
      if(this.isEmptyActiveList) {
        this.isDisableButton = this.isEmpty
      } else {
        if(this.isEmpty) {
          this.isDisableButton = true
        } else {
          this.isDisableButton = this.compareArrays(this.resultRadioButtonList.sort(), this.activeInfoList.sort())
        }
      }
      this.$emit('changeRelatedCard', this.resultRadioButtonList, this.isDisableButton)
    }
  }
}
</script>

<style scoped lang="scss">
.list-frame-container {
  width: 100%;
}
</style>