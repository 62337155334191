import {api} from "@/providers/api";

export const serviceModules = {
    state: () => ({
        servicesList: [],
        totalCountService: 0,
        // currentPage: 0
    }),
    getters:{
        serviceAll: (state) => {
            return state.servicesList
        },
        totalCountServiceAll: (state) => {
            return state.totalCountService
        },
        // currentPageAll: (state) => {
        //     return state.currentPage
        // }
    },
    mutations: {
        SET_SERVICE(state, serviceInfo) {
            state.servicesList = serviceInfo
        },
        SET_TOTAL_COUNT_SERVICE(state, serviceInfo) {
            state.totalCountService = serviceInfo
        },
        // SET_CURRENT_PAGE(state, serviceInfo) {
        //     state.currentPage = serviceInfo
        // },
    },
    actions: {
        async getService({commit}, { params } = { params: {}}) {
            let response = await api.service.serviceControllerGet({
                ...params
            }).then(r => r.data);
            commit('SET_SERVICE', response?.data)
            commit('SET_TOTAL_COUNT_SERVICE', response?.meta?.total)
            // commit('SET_CURRENT_PAGE', response?.meta?.currentPage)
        }
    }
}