<template>
  <div class="modal-alert-container">
    <div class="modal-alert-content">
      <sub-title>{{ subTitle }}</sub-title>
      <slot/>
      <div class="button-agreement-container row">
        <button-main :active-button="false"
                     :disabled-button="false"
                     @click="cancelInfo">
          {{ textButton }}
        </button-main>
      </div>
    </div>
    <transition name="fade-opacity-lazy">
      <div v-if="activeModal" class="modal-background" @click.self="cancelInfo">
      </div>
    </transition>
  </div>
</template>

<script>
import ModalFrame from "@/components/entities/ModalFrame.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "ModalAlert",
  components: {ButtonMain, SubTitle, ModalFrame},
  props: {
    subTitle: {
      type: String,
      required: true
    },
  },
  mounted() {
    setTimeout(() => {
      this.activeModal = true
    },350)
  },
  data() {
    return {
      textButton: "Готово",
      activeModal: false
    }
  },
  methods: {
    cancelInfo() {
      this.activeModal = false
      setTimeout(() => {
        this.$emit('cancelInfo')
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.modal-alert-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 998;
    background-color: rgba(1,1,1,.5);
  }

  .modal-alert-content {
    margin: 0 16px;
    z-index: 999;
    width: 100%;
    background-color: var(--color-background);
    border: 3px solid var(--color-stroke);
    max-width: 412px;
    border-radius: 8px;
    padding: 24px;

    .text-content {
      white-space: pre-wrap;
      text-align: center;
      margin-bottom: 24px;
    }

    .button-agreement-container {
      width: 100%;
      justify-content: center;
    }
  }
}



@media (max-width: 765px) {
  .modal-alert-container {
    .modal-alert-content {
      padding: 14px;
      border: 1px solid var(--color-stroke);
    }
  }
}

@media (max-width: 375px) {
  .modal-alert-container {
    .modal-alert-content {
      padding: 14px;
    }
  }
}

@media (max-width: 320px) {
  .modal-alert-container {
    .modal-alert-content {
      padding: 12px;
    }
  }
}
</style>