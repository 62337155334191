<template>
  <button class="main-button-container"
       :class="{'disabled' : disabledButton, 'active' : activeButton}">
      <slot/>
  </button>
</template>

<script>
export default {
  name: "ButtonMain",
  props: {
    disabledButton: {
      type: Boolean,
      required: true
    },
    activeButton: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
button {
  font-family: 'Raleway-Regular', sans-serif;
  color: var(--color-text);
  font-size: 14px;
}
  .main-button-container {
    display: flex;
    justify-content: center;
    max-height: 32px;
    align-items: center;
    transition: opacity .5s ease;
    max-width: fit-content;
    background: var(--color-field);
    border: solid 1px var(--color-stroke);
    border-radius: 8px;
    opacity: .6;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: .2;
      cursor: default;
    }

    &.active {
      border: none;
      opacity: 1;
    }

    .main-button-content {
      font-size: 14px;
    }
  }

  @media (max-width: 769px) {
    button {
      font-size: 12px;
    }
  }
</style>