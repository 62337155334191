<template>
    <modal-agreement @cancelInfo="cancelModal"
                     @agreeInfo="deleteBackend"
                     :sub-title="deleteInfoTitle"/>
</template>

<script>
import ModalAgreement from "@/components/entities/ModalAgreement.vue";

export default {
  name: "ModalDelete",
  components: {ModalAgreement},
  data() {
    return {
      deleteInfoTitle: 'Вы действительно хотите удалить данные?\nОтменить действие будет невозможно',
      idDelete: -1
    }
  },
  methods: {
    deleteBackend() {
      this.$emit('deleteBackend')
    },
    cancelModal() {
      this.$emit('cancelModal')
    }
  }
}
</script>

<style scoped>

</style>