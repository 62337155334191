<template>
  <div class="user-card-container row">
    <div class="left-content row">
      <div class="user-info-container row">
        <avatar-user :img-text= "userInfo?.avatar || getUrlUser('avatar-default.png')"/>
        <div class="name-mail-container column">
          <main-paragraph>{{ activeRuFullName }}</main-paragraph>
          <main-paragraph>{{ userInfo?.email }}</main-paragraph>
        </div>
      </div>
      <div class="user-auth-container row">
        <drop-down-list @changeDropdownList="checkActiveRole" :active-modal-logic="true" :info-list="infoList"/>
        <button-main @click="changePassword(userInfo)"
                     :active-button="false"
                     :disabled-button="false">
          {{ passwordTextButton }}
        </button-main>
      </div>
    </div>


      <div class="user-button-container row">
        <button-edit-delete :small-icon="false"
                            :active-edit="true"
                            @editInfo="editInfo(userInfo.id)"
                            @deleteInfo="deleteInfo(userInfo.id)"/>
      </div>
  </div>
</template>

<script>
import AvatarUser from "@/components/shared/AvatarUser.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ButtonStateIcon from "@/components/shared/ButtonStateIcon.vue";
import DropDownList from "@/components/shared/DropDownList.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ButtonEditDelete from "@/components/entities/ButtonEditDelete.vue";

export default {
  name: "CardUser",
  components: {ButtonEditDelete, ButtonIcon, DropDownList, ButtonStateIcon, ButtonMain, MainParagraph, AvatarUser},
  props: {
    userInfo: {
      type: Object,
      required: true
    },
    activeDropdownChange: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      passwordTextButton: 'Сбросить пароль',
      defaultAvatar: "user-1.png",
      selected: '',
      infoList: {
        placeholder: 'Роль пользователя',
        infoText: ['Администратор', 'Сотрудник', 'Репортер'],
        infoTextEn: ['ADMIN', 'PERSON', 'MODERATOR'],
      },
      resultUser: {},
    }
  },
  mounted() {
    this.checkActivePlaceholder();
  },
  computed: {
    activeRuFullName() {
      return this.userInfo?.profiles?.find(item => item.lang === 'ru')?.fullName
    },
  },
  methods: {
    checkActivePlaceholder() {
      if(this.userInfo.adminRole !== undefined) {
        switch(this.userInfo.adminRole) {
          case 'ADMIN':
            this.infoList.placeholder = this.infoList.infoText[0];
            break;
          case 'PERSON':
            this.infoList.placeholder = this.infoList.infoText[1];
            break;
          case 'MODERATOR':
            this.infoList.placeholder = this.infoList.infoText[2];
            break;

        }
      }
    },

    checkActiveRole(id) {
      this.$emit('changeRole', this.userInfo.id, this.infoList.infoTextEn[id])
    },

    changePassword(user) {
      this.$emit('changePassword', user?.id, user?.email)
    },
    deleteInfo(id) {
      this.$emit('deleteInfo', id)
    },
    editInfo(id) {
      this.$emit('editInfo', id)
    }
  }
}
</script>

<style scoped lang="scss">
 .user-card-container {
   width: 100%;
   justify-content: space-between;
   align-items: center;
   row-gap: 64px;

   .left-content {
      column-gap: 64px;
     .user-info-container {
       align-items: center;
       .avatar-user-container {
         margin-right: 24px;
       }

       .name-mail-container {
         opacity: .6;

         .text-content {
           margin-bottom: 8px;

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }

     .user-auth-container {
       column-gap: 32px;
       max-width: 380px;
       width: 100%;
       .drop-down-list-container {
         width: 167px;
       }
     }
   }



   .user-button-container {
     justify-content: space-between;
   }
 }

 @media (max-width: 1200px) {
   .user-card-container {
     width: 100%;
     justify-content: space-between;
     align-items: flex-start;
     row-gap: 64px;

     .left-content {
       flex-direction: column;
       row-gap: 16px;

       .user-auth-container {
         flex-direction: column;
         row-gap: 16px;
       }
     }
   }
 }
</style>