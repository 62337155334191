<template>
  <div class="card-add-info-container">
    <div class="card-add-item ru">
      <large-title>{{ mainTitleLanguageRU }}</large-title>
      <card-title-input @changeInfo="changeInfoRU"
                        :active-language="'ru'"
                        :lang-name="'langCode'"
                        :length-input-title="lengthInputTitle"
                        :input-info="inputInfo"
                        :active-main-title="ruItemActive?.name"
                        :active-sub-title="ruItemActive?.text"
                        :main-title-d-o-m="mainTitle + additionalTitle"
                        :sub-title-d-o-m="subTitle + additionalTitle"/>
    </div>
    <div class="card-add-item en">
      <large-title>{{ mainTitleLanguageEN }}</large-title>
      <card-title-input @changeInfo="changeInfoEN"
                        :active-language="'en'"
                        :lang-name="'langCode'"
                        :input-info="inputInfo"
                        :length-input-title="lengthInputTitle"
                        :active-main-title="enItemActive?.name"
                        :active-sub-title="enItemActive?.text"
                        :main-title-d-o-m="mainTitle + additionalTitle"
                        :sub-title-d-o-m="subTitle + additionalTitle"/>
    </div>
    <div class="related-card-container">
      <slot/>
    </div>
    <div class="button-submit-container">
    <transition name="fade-opacity-lazy">
      <sub-title class="alert-content" v-if="activeStateAlert">
        {{ alertText }}
      </sub-title>
    </transition>
    <button-main @click="addResult"
                 :active-button="false"
                 :disabled-button="disabledButton">
      {{ nameButton }}
    </button-main>
    </div>
  </div>
</template>

<script>
import CardTitleInput from "@/components/entities/CardTitleInput.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import {transliterate} from "transliteration";

export default {
  name: "CardAddInfo",
  props: {
    additionalTitle: {
      type: String,
      required: true
    },
    activeItemList: {
      default: undefined,
      required: true
    },
    lengthInputTitle: {
      type: Number,
      default: 40
    },
    nameId: {
      required: true,
      type: String
    },
    activeRelatedListButton: {
      default: undefined
    },
  },
  components: {LargeTitle, SubTitle, ButtonMain, MainTitle, CardTitleInput},
  data() {
    return {
      mainTitle: 'Название ',
      subTitle: 'Описание ',
      mainTitleLanguageEN: 'Английский',
      mainTitleLanguageRU: 'Русский',
      nameButton: 'Сохранить',
      resultModification: [],
      alertText: '',

      inputInfo: [
        {
          id: 0,
          placeholder: 'Введите текст',
          dataIndex: 'name',
          type: 'textarea',
        },
        {
          id: 1,
          placeholder: 'Введите текст',
          dataIndex: 'text',
          type: 'textarea',
        }
      ],


      resultModificationRu: {
        name: '',
        text: ''
      },
      resultModificationEn: {
        name: '',
        text: ''
      },

      activeStateAlert: false,
      activeItemListState: false
    }
  },

  computed: {
    enItemActive() {
      if(this.activeItemListState) {
        return this.checkLangCode(this.activeItemList,
            'en',
            'modifications',
            'langCode');
      }
    },
    ruItemActive() {
      if(this.activeItemListState) {
        return this.checkLangCode(this.activeItemList,
            'ru',
            'modifications',
            'langCode');
      }
    },

    validEditInfoRu() {
      return this.resultModificationRu.name !== ''
          && this.resultModificationRu.text !== ''
    },
    validEditInfoEn() {
      return this.resultModificationEn.name !== ''
          && this.resultModificationEn.text !== ''
    },


    validInfo() {
      return this.validEditInfoRu && this.validEditInfoEn;
    },

    disabledButton() {
      if(this.activeRelatedListButton !== undefined) {
        if(this.activeItemListState) {
          return !((this.compareObjects(this.ruItemActive, this.resultModificationRu)
                  || this.compareObjects(this.enItemActive, this.resultModificationEn)
                  || !this.activeRelatedListButton)
                  && this.validInfo);
        } else {
          return !(this.isValidLang(this.resultModificationRu, this.resultModificationEn) &&
              !this.activeRelatedListButton);
        }
      } else {
        if(this.activeItemListState) {
          return !((this.compareObjects(this.ruItemActive, this.resultModificationRu)
              || this.compareObjects(this.enItemActive, this.resultModificationEn))
              && this.validInfo);
        } else {
          return !this.isValidLang(this.resultModificationRu, this.resultModificationEn);
        }
      }
    }
  },

  mounted() {
    this.checkMountedItem();
  },

  methods: {

    isValidLang(itemOne, itemTwo) {
      return this.isValidInput(itemOne)
          &&
          this.isValidInput(itemTwo)
    },

    checkMountedItem() {
      this.activeItemListState = this.changeActiveItemListState(this.activeItemList);

      if(this.activeItemListState
          && this.ruItemActive !== undefined
          && this.enItemActive !== undefined) {
        this.resultModificationRu = JSON.parse(JSON.stringify(this.ruItemActive));
        this.resultModificationEn = JSON.parse(JSON.stringify(this.enItemActive));

      }
    },

    changeInfoRU(item) {
      const keys = Object.keys(item);
      for (const key of keys) {
        this.resultModificationRu[key] = item[key]
      }
    },
    changeInfoEN(item) {
      const keys = Object.keys(item);
      for (const key of keys) {
        this.resultModificationEn[key] = item[key]
      }
    },


    addResult() {
      if(this.disabledButton) {
        this.checkAlert();
      } else {
        let slug = transliterate(this.resultModificationEn?.name, { replace: {' ': '-'}});
        if(this.resultModificationRu[this.nameId] !== undefined) {

          delete this.resultModificationRu[this.nameId]
          delete this.resultModificationEn[this.nameId]
        }

        let resultObj = {
          modifications: [this.resultModificationRu, this.resultModificationEn],
          slug: slug
        };
        this.$emit('addResult', resultObj, this.activeItemListState)
      }
    }
  }
}
</script>

<style scoped lang="scss">



.card-add-info-container {
  position: relative;
  width: 100%;
  padding: 24px;

  .card-add-item {
    margin-bottom: 32px;
    .text-content {
      margin-bottom: 32px;
    }
  }

  .related-card-container {
    margin-bottom: 32px;
  }


  .button-submit-container {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    .alert-content {
      position: absolute;
      bottom: -32px;
    }
  }
}

@media (max-width: 769px) {
  .card-add-info-container {
    padding: 0;

    .button-submit-container {
      .alert-content {
        bottom: -24px;
      }
    }
  }
}
</style>