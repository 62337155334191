<template>
  <div class="info-list-container column" :class="{'active-small' : activeSmall,
  'scroll' : configList === 'scroll',
  'static' : configList === 'static',
  'pagination' : configList === 'pagination'}">
    <transition-group name="fade-translate-x">
      <div class="info-list-content row" v-for="(info,index) in infoList" :key="index">
        <info-list-item :info-text="info?.name || info?.text?.text || info?.text || info"
                        :state-item="checkResultItemRadio(info?.id)"
                        :info-id="info?.id || index"
                        @editInfo="editItemInfo"
                        :active-edit-delete="activeEditDelete"
                        @deleteInfo="changeStateModalDelete(info?.id || index)"
                        @changeStateRadioButton="changeStateRadioButton"
                        :active-radio-button="activeRadioButton"
                        :active-small="activeSmall"
                        :active-ability-edit="activeEdit"/>
      </div>
    </transition-group>

    <transition name="fade-opacity-lazy">
      <frame-pagination v-if="configList === 'pagination' && numPagination > 0"
                        :current-page="currentPage"
                        @changePage="changePage"
                        :num-pagination="numPagination"/>
    </transition>


    <transition name="fade-scale">
      <modal-delete v-if="activeDeleteModal"
                    @deleteBackend="deleteBackendInfo"
                    @cancelModal="changeStateModalDelete"/>
    </transition>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ButtonStateIcon from "@/components/shared/ButtonStateIcon.vue";
import ButtonEditDelete from "@/components/entities/ButtonEditDelete.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import InfoListItem from "@/components/entities/InfoListItem.vue";
import ModalDelete from "@/components/entities/ModalDelete.vue";
import FramePagination from "@/components/entities/FramePagination.vue";

export default {
  name: "InfoListContent",
  components: {FramePagination, ModalDelete, InfoListItem, MainParagraph, ButtonEditDelete, ButtonStateIcon, ButtonIcon},
  props: {
      infoList: {
        default: null,
        required: true
      },

    currentPage: {
      type: Number,
      required: true
    },

    configList: {
        type: String,
        default: 'static'
    },

    numPagination: {
        type: Number
    },

    activeSmall: {
        type: Boolean,
      required: true
    },
    activeEdit: {
        type: Boolean,
      required: true
    },
    activeRadioButton: {
      type: Boolean,
      required: true
    },
    activeEditDelete: {
        type: Boolean,
        default: true,
    }
  },
  data() {
    return {
      activeDeleteModal: false,
      activeEducation: false,
      deleteId: 0,
      resultListRadioButton: []
    }
  },
  mounted() {
    if(this.activeRadioButton) {
      this.resultListRadioButton = JSON.parse(JSON.stringify(this.infoList));
    }
  },
  computed: {
    defaultTotalCountInfo() {
      return this.$store.state.DEFAULT_PAGE_LIMIT;
    },
    //
    // countPagePagination() {
    //   return Math.ceil(this.numPagination / this.defaultTotalCountInfo);
    // }
  },

  methods: {
    changePage(id) {
        this.$emit('changePageList', id)
    },

    editItemInfo(id) {
      this.$emit('editItemInfo', id)
    },

    checkResultItemRadio(itemParent) {
      return this.resultListRadioButton.find(item => item.id === itemParent)?.state
    },

    changeStateRadioButton(id, state) {
      let index = this.resultListRadioButton.findIndex(item => item.id === id);
      this.resultListRadioButton[index].state = state;
      this.$emit('changeStateRadioButton', id)
    },

    deleteBackendInfo() {
      this.$emit('deleteBackendInfo', this.deleteId)
      this.changeStateModalDelete()
    },

    changeStateModalDelete(item) {
      this.deleteId = item
      this.activeDeleteModal = !this.activeDeleteModal;
    }
  }
}
</script>

<style scoped lang="scss">
  .info-list-container {
    overflow-y: hidden;
    width: 100%;


    &.scroll {
      max-height: 192px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      height: 100%;
      max-width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
      width: 1px;
      border-radius: 30%;
    }


    &::-webkit-scrollbar-thumb {
      width: 7px;
      background-color: var(--scrollbar-color);
      border-radius: 4px;

    }

    &::-webkit-scrollbar-thumb:hover{
      background-color: var(--scrollbar-color-hover);
    }

    &.active-small {
      max-height: 96px;
    }

    .info-list-content {
      width: 100%;

      &:nth-child(n + 2) {
        .info-item-container {
          margin: 3px 0;
        }
      }

      &:nth-child(2n) {
        .info-item-container {
          background-color: rgba(1, 1, 1, 0);
        }
      }
    }
  }
</style>