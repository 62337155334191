<template>
  <div class="input-more-file-container">
    <transition name="fade-opacity-lazy">
      <div class="input-more-file-content" v-if="!activeResult">
        <input-file :active-input="activeLanguage"
                    @changeFile="handleFileChange">
          {{ placeholderText }}
        </input-file>
      </div>
      <div class="input-more-file-content column result" v-else>
        <div class="result-container row">
          <transition-group name="fade-translate-y">
            <div class="result-content" v-for="(item, index) in resultInputArray" :key="index">
              <div class="cross-container" @click="deleteImage(item?.img?.id, index)" >
                <button-icon :img-name="'cross'"/>
              </div>
              <img :src="item?.dataURL || activeUrlImg + item?.img?.name" alt="result"/>
            </div>
          </transition-group>

        </div>
        <input-file class="load" :active-input="activeLanguage"
                    @changeFile="handleFileChange"
                    @mouseenter="changeTitleResult"
                    @mouseleave="changeTitleResult">
          {{ loadingTitle }}
        </input-file>
      </div>
    </transition>
    <transition name="fade-scale">
      <modal-crop-image v-if="activeCropper"
                        :active-rectangle="false"
                        :active-circle="false"
                        :active-square="true"
                        :file-src="fileSrc"
                        @crossImage="crossImage"
                        @closeModal="closeModal"/>
    </transition>
  </div>
</template>

<script>
import InputFile from "@/components/shared/InputFile.vue";
import ModalCropImage from "@/components/entities/ModalCropImage.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "FrameMoreInputFile",
  components: {ButtonIcon, ModalCropImage, InputFile},
  props: {
    placeholderText: {
      type: String,
      required: true
    },
    activeListImage: {
      type: Array
    },
    activeLanguage: {
      type: String
    },
  },
  data() {
    return {
      focusParam: true,
      fileSrc: null,
      fileCrossSrc: null,
      cropper: null,
      activeCropper: false,
      croppedImageUrl: '',
      activeResult: false,
      loadingTitle: 'Фото загружено',

      resultInputArray: [],
      deleteImgArray: [],

      activeId: 0,
      activeUrl: '',
      activeResultTitle: false
    }
  },
  mounted() {
    let fullPathRouter = this.$route.fullPath
    let url = window.location.href
    let indexPath = url.indexOf(fullPathRouter)
    let sliceUrl = url.slice(0, indexPath)
    this.$nextTick(() => {
      if (this.activeListImage !== undefined && this.activeListImage !== null && this.activeListImage.length !== 0) {
        this.mountImage();
      }
    })
  },
  computed: {
    activeUrlImg() {
      let fullPathRouter = this.$route.fullPath
      let url = window.location.href
      let indexPath = url.indexOf(fullPathRouter)
      let sliceUrl = url.slice(0, indexPath)
      if(sliceUrl === 'https://admin.imlegal.ru') {
        return 'https://s3.imlegal.ru/im-legal/'
      } else {
        return 'https://s3.roseblade.xyz/im-legal-dev/'
      }
    }
  },
  watch: {
    activeResultTitle() {
      if(this.activeResultTitle) {
        this.loadingTitle = 'Добавить ещё фотографии'
      } else {
        this.loadingTitle = 'Фото загружено'
      }
    }
  },
  methods: {

    deleteImage(idDB, idIndex) {

      if(this.activeListImage !== undefined) {
        this.deleteImgArray.push(idDB)
        this.$emit('activeDeletePhotoArray', this.deleteImgArray);
      }
      if (idIndex !== -1) {
        this.resultInputArray.splice(idIndex, 1);
      } else {
        console.log("Элемент не найден");
      }
      this.$emit('changeMorePhoto', this.resultInputArray);
      if(this.resultInputArray.length === 0) {
        this.activeResult = false
      }
    },

    mountImage() {
      this.activeListImage.forEach((item,index) => {
        this.addImg(item)
      })
      this.activeId = this.activeListImage.length
      this.activeResult = true
    },

    closeModal() {
      this.activeCropper = false
      this.fileSrc = this.fileCrossSrc
    },

    handleFileChange(file) {
      this.fileSrc = file
      this.activeCropper = true
    },

    crossImage(img) {
      this.activeCropper = false;
      this.activeResult = true;
      this.fileCrossSrc = img.toDataURL();
      this.addImg({canvas: img, fileTarget: this.fileSrc}, this.fileCrossSrc)
      this.activeId = this.activeId + 1;
    },

    addImg(info, url) {
      this.resultInputArray.push({
        img: info,
        dataURL: url
      })
      this.$emit('changeMorePhoto', this.resultInputArray);
    },

    changeTitleResult() {
      this.activeResultTitle = !this.activeResultTitle
    }
  },
}
</script>

<style scoped lang="scss">
.input-more-file-container {
  width: 100%;
  max-height: 600px;

  .input-more-file-content {
    display: flex;
    width: 100%;
    height: 64px;
    align-items: center;
    justify-content: space-between;

    &.result {
      height: 350px;
    }
  }
}



.input-content {
  &.load {
    height: 32px;
  }
}

.result-container {
  max-width: 1080px;
  height: 320px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 16px;

  .result-content {
    position: relative;
    margin-right: 8px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;

    .cross-container {
      background-color: var(--color-field);
      border: 1px solid var(--color-stroke);
      padding: 4px;
      border-radius: 5px;
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: .6;
      cursor: pointer;
      transition: opacity .5s ease;

      &:hover {
        opacity: 1;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}


::-webkit-scrollbar {
  max-height: 7px;
  width: 100%;
}


::-webkit-scrollbar-track {
  background: var(--scrollbar-color-track-x);
  height: 1px;
  border-radius: 30%;
}


::-webkit-scrollbar-thumb {
  height: 7px;
  background-color: var(--scrollbar-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: var(--scrollbar-color-hover);
}

@media (max-width: 769px) {
  .result-container {
    max-width: 100%;
    width: auto;
  }
}

</style>