
import {mapActions} from "vuex";

export default {
    methods: {
        ...mapActions(['IS_LOADING']),
        focusTrue() {
            this.focusParam = true;
        },
        focusFalse() {
            this.focusParam = false;
        },

        getUrl(picture) {
            return require('../assets/' + picture)
        },

        getUrlIcon(picture) {
            return require('../assets/icons/' + picture)
        },

        getUrlUser(picture) {
            return require('../assets/avatar/' + picture)
        },

        getCustomUrl(picture, url) {
            return require('../assets/' + url + picture)
        },

        checkActiveTitleText() {
            if(this.activeLanguage === 'en') {
                this.mainTitleLanguage = 'Английский'
                this.subTitleLanguage = {
                    en: "en",
                    ru: "англ"
                }
            } else {
                this.mainTitleLanguage = 'Русский'
                this.subTitleLanguage = {
                    en: "ru",
                    ru: "рус"
                }
            }
        },

        changeStateAddItem() {
            this.nameButtonCRUD = 'Добавить'
            if(this.idItem === -1) {
                this.activeState = !this.activeState
            } else {
                this.idItem = -1
                this.changeActiveStateItem();
            }
        },


        editItemList(id) {
            if(!this.activeState) {
                this.editList(id);
            } else {
                if(this.idItem === -1) {
                    this.editList(id);
                    this.changeActiveStateItem();
                } else {
                    this.editList(id);
                }
            }
        },

        editList(id) {
            if(this.idItem === id) {
                this.nameButtonCRUD = 'Добавить'
                this.activeState = false
                this.idItem = -1
            } else {
                this.nameButtonCRUD = 'Редактировать'
                this.changeActiveStateItem();
                this.idItem = id
            }
        },

        changeActiveStateItem() {
            this.activeState = false
            setTimeout(() => {
                this.activeState = true
            }, 500)
        },

        changeActiveItemListState(arr) {
            return arr !== undefined && arr !== null;
        },

        checkAlert() {
            if(this.activeItemListState) {
                this.alertText = 'Для обновления данных внесите изменения.'
            } else {
                this.alertText = 'Введите все данные!'
            }
            this.activeStateAlert = true;
            setTimeout(() => {
                this.activeStateAlert = false;
            }, 500)
        }
    },
    computed: {
        activeColor() {
            return this.$store.state.activeColor
        }
    }
}