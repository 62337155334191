<template>
    <div class="wrapper">
      <header-main/>
      <main>
        <transition name="fade-translate-y-card-revert">
          <controller-main v-if="activeController && activeMobile" :inner-width="innerWidth"/>
        </transition>
        <controller-main v-if="!activeMobile" :inner-width="innerWidth"/>
        <Loader v-if="isLoading || isLoadingSearch"/>
          <router-view/>
      </main>
    </div>
</template>

<script>
import ControllerMain from "@/components/widgets/ControllerMain.vue";
import HeaderMain from "@/components/widgets/HeaderMain.vue";
import {mapActions} from "vuex";
import Loader from "@/components/Loader.vue";

export default {
  name: "MainLayout",
  components: {Loader, HeaderMain, ControllerMain},
  data() {
    return {
      activeMobileController: true,
      innerWidth: 0,
      activeMobile: false,
    }
  },
  async created() {
    this.checkInfo();
  },
  mounted() {
    this.checkSize();
    addEventListener('resize', this.checkSize)
  },
  computed: {
    activeController() {
      return this.$store.state.activeMobileMenu;
    },
    isLoadingSearch() {
      return this.$store.state.isLoadingSearch;
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MOBILE_MENU', 'ACTIVE_THEME']),
    checkSize() {
      this.innerWidth = window.innerWidth
      if(this.innerWidth > 769) {
        this.activeMobile = false
        this.ACTIVE_MOBILE_MENU(true)
      } else {
        this.activeMobile = true
        this.ACTIVE_MOBILE_MENU(false)
      }
    },
    async checkInfo() {
      await this.ACTIVE_THEME();
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {

  main {
    display: flex;
    flex-direction: row;
  }
}

.mobile {
  display: none;
}
@media (max-width: 769px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>