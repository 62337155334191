<template>
  <div class="case-news-container" :class="activeLanguage === 'en' ? 'en' : 'ru'">
    <large-title>{{mainTitleLanguage}}</large-title>
    <card-title-input :input-info="inputInfo"
                      :lang-name="'lang'"
                      :length-input-title="200"
                      @changeInfo="changeTextInfo"
                      :active-main-title="resultCard?.name"
                      :active-sub-title="resultCard?.shortName"
                      :active-language="activeLanguage"
                      :main-title-d-o-m="mainTitle + additionalTitle"
                      :sub-title-d-o-m="subTitle"/>
    <div class="editor-text-container">
      <sub-title>
        {{ editorTitle }} {{ additionalTitle }}
      </sub-title>

      <editor-container :active-item="resultCard?.content"
                        @changeEditorContent="changeEditorText"/>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import InputFile from "@/components/shared/InputFile.vue";
import EditorContainer from "@/components/entities/EditorContainer.vue";
import CardTitleInput from "@/components/entities/CardTitleInput.vue";
import FrameMoreInputFile from "@/components/entities/FrameMoreInputFile.vue";
import FrameInputFile from "@/components/entities/FrameInputFile.vue";

export default {
  name: "CardNewsCase",
  components: {
    FrameInputFile,
    FrameMoreInputFile, CardTitleInput, EditorContainer, InputFile, SubTitle, LargeTitle, MainTitle},
  props: {
    activeLanguage: {
      type: String,
      required: true
    },
    additionalTitle: {
      type: String,
      required: true
    },
    inputInfo: {
      type: Array,
      required: true
    },
    activeItem: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      mainTitleLanguage: '',
      mainTitle: 'Заголовок ',
      subTitle: 'Подзаголовок',
      editorTitle: 'Текст',
      activeItemListState: false,
      resultCard: {},

      resultTranslationCard: {
        name: '',
        shortName: '',
        content: '',
        lang: ''
      },
    }
  },

  computed: {
    isValid() {
      return this.isValidInput(this.resultTranslationCard);
    },
    disabledButton() {
      if(this.activeItemListState) {
        const { postId, ...withoutId } = this.resultCard;
        if(this.isValid) {
          return !(this.compareObjects(this.resultTranslationCard, withoutId));
        } else {
          return true
        }
      } else {
        return !this.isValid
      }
    }
  },


  watch: {
    disabledButton() {
      this.$emit('changeDisableButton', this.disabledButton, this.activeLanguage)
      if(!this.disabledButton) {
        this.$emit('changeData', this.resultTranslationCard, this.activeLanguage)
      }
    }
  },

  mounted() {
    this.$emit('changeDisableButton', this.disabledButton, this.activeLanguage)
    this.checkActiveTitleText();
    this.$nextTick(() => {
      this.activeItemListState = this.changeActiveItemListState(this.activeItem);
      this.checkMountedItem();
    })
  },

  methods: {
    changeEditorText(text) {
      this.resultTranslationCard.content = text
    },
    checkMountedItem() {
      if(this.activeItemListState) {
        this.resultCard = this.activeItem;
      }
    },
    changeTextInfo(item) {
      const keys = Object.keys(item);
      for (const key of keys) {
        this.resultTranslationCard[key] = item[key]
      }
    },
  }

}
</script>

<style scoped lang="scss">
.case-news-container {
  width: 100%;
  margin-bottom: 32px;

  .text-content {
    &.large {
      margin-bottom: 32px;
    }
    &.sub {
      margin-bottom: 8px;
    }
  }

  .input-file-container {
    margin-bottom: 16px;
  }

  .input-more-file-container {
    margin-bottom: 16px;
  }

  .input-content {
    height: 64px;
  }
}
</style>