<template>
  <check-box-sub  @changeActiveCheck="toggleTheme"
                  :local-active="localTheme"
                  :name-check="nameCheck"/>
</template>

<script>
import CheckBoxSub from "@/components/shared/CheckBoxSub.vue";

export default {
  name: "CheckBoxTheme",
  components: {CheckBoxSub},
  props: {
    nameCheck: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localTheme: false
    }
  },
  mounted() {
    this.localTheme = localStorage.getItem('theme') === 'dark-theme'
  }
}
</script>

<style scoped>

</style>