<template>
<div class="input-container" @click="focusInput" :class="{'textarea' : info.type === 'textarea', 'focus' : focused}">
    <div class="letter-counter" v-if="info.type === 'textarea'">
      <p>{{this.countLetter}} | {{lengthTextArea}}</p>
    </div>
    <button-icon v-if="activeGlass" :img-name="'glass'"/>
    <input :type="info.type"
           @focus="focused = true"
           @blur="focused = false"
        :placeholder="info.placeholder"
        v-if="info.type !== 'textarea'"
        v-model="textInput">

    <textarea class="textarea"
              ref="textareaMessage"
              @focus="focused = true"
              @blur="focused = false"
              :placeholder="info.placeholder"
              :maxlength="lengthTextArea"
              v-model="textInput" v-else></textarea>
</div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "InputMain",
  components: {ButtonIcon},
  props: {
      info: {
      type: Object,
      required: true
    },
    lengthTextArea: {
        type: Number
    },
    submitActive: {
        type: Boolean
    },
    activeFocus: {
      type: Boolean,
      default: false
    },
    nameBlock: {
        type: String
    },
    activeGlass: {
        type: Boolean,
      required: true
    },
    activeTextInput: {
        type: String,
        default: null
    },
  },

  mounted() {
    this.checkActiveTextInput();
  },

  data() {
    return {
      textInput: '',
      countLetter: 0,
      focused: false,
    }
  },
  watch: {
    textInput() {
      this.countLetter = this.textInput.length
      this.$emit('changeTextInput', this.textInput.trim(), this.info.dataIndex)
    },
    submitActive() {
      if(this.submitActive) {
        this.textInput = ''
      }
    },
    activeTextInput() {
      this.checkActiveTextInput();
    }
  },
  methods: {
    checkActiveTextInput() {
      if(this.activeTextInput !== null) {
        this.textInput = this.activeTextInput
      }
      if(this.activeFocus) {
        this.focusInput();
      }
    },

    focusInput() {
      let block = document.querySelector("." + this.nameBlock)
      if(this.info.type !== 'textarea') {
        let input = block.querySelectorAll("input")
        input[this.info.id].focus()
      } else {
        let textarea = block.querySelectorAll("textarea")
        textarea[this.info.id].focus()
      }
    },
  }
}
</script>

<style scoped lang="scss">

  .input-container {
    cursor: text;
    display: flex;
    justify-content: center;
    background: var(--color-field);
    font-family: 'Raleway-Regular', sans-serif;
    color: var(--color-text);
    line-height: 100%;
    position: relative;
    transition: opacity .5s ease;
    border: 1px solid var(--color-stroke);
    padding: 8px 16px;
    border-radius: 24px;
    opacity: .6;


    &.textarea {
      padding: 8px;
      border-radius: 8px;
    }

    &:hover {
      opacity: 1;
    }

    &.focus {
      opacity: 1;
    }

    .button-icon-container {
      position: absolute;
      height: 16px;
      width: 16px;
      right: 8px;
    }

    .letter-counter {
      color: var(--color-text);
      font-family: 'Raleway-Regular', sans-serif;
      position: absolute;
      opacity: .6;
      bottom: 8px;
      right: 8px;
    }


    .important-input {
      position: relative;
    }

    & input:autofill {
      background-color: rgba(1,1,1,0) !important;
    }


    & input, textarea {
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-family: 'Raleway-Regular', sans-serif;
      color: var(--color-text);
      background-color: rgba(1,1,1,0);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .textarea {
      resize: none;
    }
  }


</style>