<template>
  <Loader v-if="isLoading"/>
  <router-view/>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "EmptyLayout",
  components: {Loader}
}
</script>

<style scoped>

</style>