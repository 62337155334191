<template>
  <modal-frame class="modal-education-container"
               @submitInfo="addInfoData"
               :button-text="nameButton"
               :edit-active-alert="activeItemListState"
               :main-title="mainTitle + subTitleLanguage.ru"
               :disabled-button="disabledButton">

    <div class="modal-education-content column">
      <info-list-item @editInfo="editInfoUniversity"
                      @deleteInfo="deleteInfoUniversity"
                      :info-id="0"
                      :active-edit-delete="true"
                      :active-radio-button="false"
                      :active-input-frame="false"
                      :active-small="true"
                      :active-ability-edit="activeMainTitle"
                      :info-text="resultEducationsList?.universityName"/>
      <div class="year-list-container row">
        <dropdown-date>
          <VueDatePicker v-model="resultEducationsList.startDate"
                         year-picker
                         :placeholder="placeholderStartYear">
          </VueDatePicker>
        </dropdown-date>
        <dropdown-date>
          <VueDatePicker v-model="resultEducationsList.endDate"
                         year-picker
                         :placeholder="placeholderEndYear">
          </VueDatePicker>
        </dropdown-date>
      </div>
      <drop-down-list @changeDropdownList="changeDropdownList"
                      :info-list="infoLevelEducation"/>
      <div class="textarea-container" v-for="(item, index) in infoInput"
                                      :class="`${item.dataIndex}`"
                                      :key="index">
        <input-main @changeTextInput="changeTextArea"
                    :name-block="item.dataIndex"
                    :active-glass="false"
                    :active-text-input="resultEducationsList[item.dataIndex]"
                    :info="item"
                    :length-text-area="item.length"/>
      </div>
    </div>

  </modal-frame>
</template>

<script>
import ModalFrame from "@/components/entities/ModalFrame.vue";
import InputMain from "@/components/shared/InputMain.vue";
import DropDownList from "@/components/shared/DropDownList.vue";
import DropdownDate from "@/components/shared/DropdownDate.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import InfoListItem from "@/components/entities/InfoListItem.vue";
import {mapGetters} from "vuex";
export default {
  name: "ModalEducation",
  components: {InfoListItem, DropdownDate, DropDownList, InputMain, ModalFrame, VueDatePicker},
  props: {
    subTitleLanguage: {
      type: Object,
      required: true
    },
    activeItem: {
      default: undefined,
      required: true
    }
  },
  data() {
    return {
      activeItemListState: false,
      resultEducationsList: {
        educationLevelId: -1,
        startDate: '',
        endDate: '',
        lang: '',
        universityName: '',
        specialityName: '',
        description: ''
      },

      nameButton: 'Добавить',

      mainTitle: 'Образование – ',
      levelEducationPlaceholder: 'Уровень образования',

      placeholderStartYear: 'Год начала обучения',
      placeholderEndYear: 'Год окончания',
      infoInput: [
        {
          id: 0,
          placeholder: 'Название университета',
          dataIndex: 'universityName',
          type: 'textarea',
          length: 100,
        },
        {
          id: 0,
          placeholder: 'Название специальности',
          dataIndex: 'specialityName',
          type: 'textarea',
          length: 100,
        },
        {
          id: 0,
          placeholder: 'Уровень квалификации',
          dataIndex: 'description',
          type: 'textarea',
          length: 100,
        },
      ],

    }
  },

  mounted() {
    this.activeItemListState = this.changeActiveItemListState(this.activeItem)
    this.checkMountedItem();
  },
  computed: {
    ...mapGetters(['getEducationLevelAll']),

    activeMainTitle() {
      return this.resultEducationsList.universityName === ''
    },

    infoLevelEducation() {
      return {
        placeholder: this.levelEducationPlaceholder,
        infoText: this.activeLangEducationLevel
      }
    },

    activeLangEducationLevel() {
      let activeLang = this.subTitleLanguage.en === 'en' ? 'eng' : 'ru'
      return this.getEducationLevelAll.map(item =>  {
          return {
            id: item.id,
            name: item[activeLang]
          }
        })
    },

    isValid() {
      let resultString = [this.resultEducationsList.universityName, this.resultEducationsList.specialityName]
      return Object.values(resultString).every(v => v.length)
    },
    disabledButton() {
      if(this.activeItemListState) {
        return !this.compareObjects(this.resultEducationsList, this.activeItem.name);
      } else {
        return !(this.isValid
            && this.resultEducationsList.educationLevelId !== -1
            && (this.resultEducationsList.startDate  !== '')
            && (this.resultEducationsList.endDate  !== ''));
      }
    }
  },
  methods: {

    deleteInfoUniversity() {
      this.resultEducationsList.universityName = ''
    },

    checkMountedItem() {
      if(this.activeItemListState) {
        this.nameButton = 'Обновить'
        this.resultEducationsList = JSON.parse(JSON.stringify(this.activeItem.name));
        let startDate = new Date(this.resultEducationsList.startDate)
        let endDate = new Date(this.resultEducationsList.endDate)
        this.placeholderStartYear = startDate.getFullYear();
        this.placeholderEndYear = endDate.getFullYear();
        this.levelEducationPlaceholder = this.findById(this.infoLevelEducation.infoText, this.resultEducationsList.educationLevelId)?.name
      }
    },

    changeDropdownList(id) {
      this.resultEducationsList.educationLevelId = id;
    },

    editInfoUniversity(text) {
      let block = document.querySelector(".textarea-container")
      let input = block.querySelector(".input-container textarea")
      input.focus()
    },

    changeTextArea(textInput, dataIndex) {
      this.resultEducationsList[dataIndex] = textInput
      console.log(dataIndex)
    },

    addInfoData() {
      this.resultEducationsList.lang = this.subTitleLanguage.en;
      if(typeof this.resultEducationsList.startDate === 'number') {
        let startDate = new Date()
        startDate.setFullYear(this.resultEducationsList.startDate)
        this.resultEducationsList.startDate = startDate.toISOString();
      }
      if(typeof this.resultEducationsList.endDate === 'number') {
        let endDate = new Date()
        endDate.setFullYear(this.resultEducationsList.endDate)
        this.resultEducationsList.endDate = endDate.toISOString();
      }
      if(this.activeItem === undefined) {
        this.$emit('submitAddInfo', this.resultEducationsList)
      } else {
        this.$emit('submitEditInfo', this.activeItem.id, this.resultEducationsList)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-education-container {

  .modal-education-content {
    height: 312px;
    justify-content: space-between;

    .info-item-container {
      height: 24px;
    }

    .drop-down-list-container {
      max-width: 576px;
      width: 100%;
      position: relative;
    }

    .year-list-container {
      justify-content: space-between;
      .dropdown-container {
        max-width: 275px;

        &:first-child {
          margin-right: 8px;
        }
      }
    }

  }
}

</style>