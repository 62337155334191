<template>
  <header class="main-header-container">
    <logo-icon/>
    <profile-header/>
  </header>
</template>

<script>
import LogoIcon from "@/components/shared/LogoIcon.vue";
import ProfileHeader from "@/components/entities/ProfileHeader.vue";

export default {
  name: "HeaderMain",
  components: {ProfileHeader, LogoIcon},
}
</script>

<style scoped lang="scss">
  .main-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    width: 100%;
    position: relative;
    z-index: 997;
    background-color: var(--color-background);
  }

  @media (max-width: 769px) {
    .main-header-container {
      padding: 18px;
    }
  }
  @media (max-width: 375px) {
    .main-header-container {
      padding: 16px;
    }
  }

  @media (max-width: 320px) {
    .main-header-container {
      padding: 14px;
    }
  }
</style>