<template>
  <div class="avatar-user-container">
    <img :src="imgText" alt="avatar">
  </div>
</template>

<script>
export default {
  name: "AvatarUser",
  props: {
    imgText: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .avatar-user-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 50%;
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
    }
  }
</style>