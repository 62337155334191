<template>
  <div class="logo-icon-container">
    <p class="logo-icon-content">
      {{logoText}}
    </p>
  </div>
</template>

<script>
export default {
  name: "LogoIcon",
  data() {
    return {
      logoText: 'IM LEGAL'
    }
  }
}
</script>

<style scoped lang="scss">
  .logo-icon-container {
    padding: 8px 0;
    cursor: pointer;
    .logo-icon-content {
        color: var(--color-text);
        font-family: 'CormorantUnicase-Bold', sans-serif;
        font-size: 20px;
      text-transform: uppercase;
    }
  }
</style>