<template>
  <div class="card-add-info-container" :class="activeLanguage === 'en' ? 'en' : 'ru'">
      <large-title v-if="!activeRating">{{ mainTitleLanguage }}</large-title>
      <main-title>{{ subTitleDOM }}</main-title>
      <input-main @changeTextInput="changeTextInput"
                  :active-glass="false"
                  :activeTextInput="resultPartnersList.name"
                  :length-text-area="40"
                  :info="inputInfo"
                  :name-block="activeLanguage"/>
  </div>

</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";

export default {
  name: "CardPartnersText",
  components: {LargeTitle, InputMain, MainTitle},
  props: {
    activeLanguage: {
      type: String,
      required: true
    },
    activePartnersList: {
      default: undefined,
      required: true
    },
    activeRating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputInfo: {
          id: 0,
          placeholder: 'Введите текст',
          type: 'textarea',
          dataIndex: 'name'
      },
      activeItemListState: false,
      mainTitleLanguage: '',
      subTitleLanguage: {},
      resultPartnersList: {
        lang: '',
        name: ''
      }
    }
  },
  mounted() {
    this.checkActiveTitleText();
    this.$nextTick(() => {
      this.checkMountedItem();
    })
  },

  watch: {

    disabledButton() {
      this.$emit('changeData', this.resultPartnersList, this.disabledButton)
    }
  },

  computed: {
    subTitleDOM() {
      if(this.activeRating) {
        return 'Название рейтинга';
      } else {
        return 'Название партнера';
      }
    },

    disabledButton() {
      if(this.activeItemListState) {
        if(this.resultPartnersList.name !== '') {
          return this.resultPartnersList.name === this.activePartnersList.name;
        } else {
          return true
        }
      } else {
        return this.resultPartnersList.name === '';
      }
    },
  },

  methods: {
    checkMountedItem() {
      this.activeItemListState = this.changeActiveItemListState(this.activePartnersList);
      if(this.activeItemListState) {
        this.resultPartnersList = JSON.parse(JSON.stringify(this.activePartnersList));
      } else {
        this.resultPartnersList.lang = this.subTitleLanguage.en;
      }
    },
    changeTextInput(item, dataIndex) {
      this.resultPartnersList.name = item;
    }
  },

}
</script>

<style scoped lang="scss">
.card-add-info-container {
  width: 100%;
  margin-bottom: 32px;

  .text-content {
    &.large {
      margin-bottom: 32px;
    }
    &.main-title {
      margin-bottom: 12px;
    }
  }
}
</style>