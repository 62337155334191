<template>
  <div class="services-screen-container main-container" v-if="!isLoading">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                   @cancelInfo="activeModal = false"
                   :sub-title="activeModalTitle"/>
    </transition>

    <list-frame class="services"
                :num-pagination="totalCountServiceAll"
                :current-page="Number(page)"
                :active-focus="true"
                :config-list="'pagination'"
                :active-text-input="search"
                @changeSearchText="changeSearchText"
                @editItemInfo="editItemList"
                @addObject="changeStateAddItem"
                @deleteBackendInfo="deleteBackendInfo"
                @changePageList="checkInfoPage"
                :setting-list="settingList"
                :main-title-d-o-m="mainTitle"
                :name-block="nameBlock"
                :name-button="mainNameButton"
                :active-add-object="activeState"
                :info-list="modificationsList"/>
    <transition name="fade-translate-x">
    <card-add-info v-if="activeState"
                   @addResult="addResult"
                   :length-input-title="100"
                   :name-id="'serviceId'"
                   :additional-title="'услуги'"
                   :active-related-list-button="isActiveRelatedButton"
                   :active-item-list="activeServiceItem">
      <div class="services-item-container practice">
        <card-related @changeRelatedCard="changeRelatedCard"
                      :name-block="'practice'"
                      :search-request-name="'slug'"
                      :active-info-list="activePracticeItem"
                      :empty-info-list="practiceAll"
                      :main-title="titlePractice"/>
      </div>
    </card-add-info>
    </transition>
  </div>
</template>

<script>
import ListFrame from "@/components/entities/FrameListContainer.vue";
import CardAddInfo from "@/components/entities/CardAddInfo.vue";
import CardRelated from "@/components/entities/ListInfo/CardRelated.vue";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/providers/api";
import store from "@/store";
import ModalAlert from "@/components/entities/ModalAlert.vue";

export default {
  name: "ServicesScreen",
  props: ['page', 'search'],
  components: {ModalAlert, CardRelated, CardAddInfo, ListFrame},
  data() {
    return {
      mainTitle: 'Список услуг',
      searchText: '',

      nameButtonType: ' услугу',
      nameButtonCRUD: 'Добавить',
      currentPage: 0,


      activeModal: false,
      activeModalTitle: '',

      idItem: -1,
      activeState: false,
      isActiveRelatedButton: true,

      settingList: {
        activeTitle: true,
        activeInput: true,
        activeSmallIcon: false,
        activeEdit: true,
        activeButton: true,
      },

      activeServiceItem: undefined,

      nameBlock: 'services',
      titlePractice: 'Связанные практики',
      resultPracticeList: []
    }
  },

  watch: {
    async searchText() {
      await this.checkInfoPage(1);
    },
    idItem() {
      if(this.idItem !== -1) {
        this.isActiveRelatedButton = true
        this.activeServiceItem =  this.findById(this.serviceAll, this.idItem);
        this.resultPracticeList = this.activePracticeItem;
      } else {
        this.isActiveRelatedButton = true
        this.activePracticeItem = undefined
        this.resultPracticeList = []
      }
    }
  },

  async created() {
    await this.checkInfo();
  },



  computed: {
    ...mapGetters(['serviceAll', 'practiceAll', 'totalCountServiceAll']),

    modificationsList() {
      try {
        return this.checkKeysList(this.serviceAll, 'modifications','name','ru');
      } catch (e) {
        return undefined
      }
    },

    mainNameButton() {
      return this.nameButtonCRUD.concat(this.nameButtonType);
    },

    activePracticeItem() {
      return this.activeServiceItem?.practices.map(item => item.id);
    },
  },

  methods: {
    ...mapActions(['getService', 'getPractice', 'IS_LOADING_SEARCH']),
    addObject() {
      this.activeStateService = !this.activeStateService
    },

    async checkInfo() {
      try {
        let promisePractice;
        if(this.practiceAll === undefined) {
          promisePractice = await this.getPractice()
        }
        await Promise.all([this.getService({
          params: {
            limit: this.$store.state.DEFAULT_PAGE_LIMIT,
            page: this.page,
            search: this.search || this.searchText
          }
        }), promisePractice])
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING(false)
        this.IS_LOADING_SEARCH(false)
      }
    },

    async checkInfoPage(currentPage) {
        if(this.searchText === '') {
          this.IS_LOADING_SEARCH(true)
          this.$router.push({name: 'service', params: {page: currentPage}}).then(async () => {
            this.$store.dispatch('IS_LOADING', false)
            await this.checkInfo();
          })
        } else {
          await this.checkInfoSearch(currentPage);
        }

    },
    async checkInfoSearch(currentPage) {
        this.$router.push({name: 'service-search', params: {page: currentPage, search: this.searchText}}).then(async () => {
        this.$store.dispatch('IS_LOADING', false)
        await this.checkInfo();
      })
    },

    addResult(item, typeCRUD) {
      this.IS_LOADING_SEARCH(true)
      let resultList = {
        modifications: item.modifications,
        slug: item.slug,
        practices: this.resultPracticeList,
      }
      if(typeCRUD) {
        this.editInfoService(resultList);
      } else {
        this.addInfoService(resultList);
      }
    },

    async editInfoService(item) {
      try {
        await api.service.serviceControllerPatch(this.idItem, {...item}).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.nameButtonCRUD = 'Добавить'
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Услуга успешно отредактирована'
        this.activeModal = true
      }
    },
    async addInfoService(item) {
      try {
        await api.service.serviceControllerCreate({...item}).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Услуга успешно добавлена'
        this.activeModal = true
      }
    },

    async deleteBackendInfo(id) {
      this.IS_LOADING_SEARCH(true)
      try {
        await api.service.serviceControllerDelete(id).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Услуга успешно удалена'
        this.activeModal = true
      }
    },

    changeRelatedCard(item, state) {
      this.resultPracticeList = item;
      this.isActiveRelatedButton = state
    },
  }
}
</script>

<style scoped>

</style>