import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import colorTheme from './mixins/colorTheme'
import reusedCode from "@/mixins/reusedCode";
import arrayMethods from "@/mixins/CRUDMethods";
import CRUDNewsCase from "@/mixins/CRUDNewsCase";
import CRUDMainProfile from "@/mixins/CRUDMainProfile";
import VueAwesomePaginate from "vue-awesome-paginate";

createApp(App).use(store).use(router).use(VueAwesomePaginate).mixin(colorTheme).mixin(reusedCode).mixin(arrayMethods).mixin(CRUDNewsCase).mixin(CRUDMainProfile).mount('#app')
