<template>
  <div class="button-state-icon-container">
    <div class="button-state-icon-content desktop" @mouseenter="focusTrue" @mouseleave="focusFalse">
      <div class="button-state-icon-content dark" v-if="activeColor">
        <transition name="fade-opacity-lazy">
          <img v-if="activeButton" :src="getUrlIcon(imgName + '-active-dark.svg')" alt="icon">
          <img v-else-if="!activeButton && !focusParam" :src="getUrlIcon(imgName + '-dark.svg')" alt="icon">
          <img v-else-if="!activeButton && focusParam" :src="getUrlIcon(imgName + '-hover-dark.svg')" alt="icon">
        </transition>
      </div>
      <div class="button-state-icon-content light" v-else>
        <transition name="fade-opacity-lazy">
          <img v-if="activeButton" :src="getUrlIcon(imgName + '-active-light.svg')" alt="icon">
          <img v-else-if="!activeButton && !focusParam" :src="getUrlIcon(imgName + '-light.svg')" alt="icon">
          <img v-else-if="!activeButton && focusParam" :src="getUrlIcon(imgName + '-hover-light.svg')" alt="icon">
        </transition>
      </div>
    </div>
    <div class="button-state-icon-content tablet-mobile" @mouseenter="focusTrue" @mouseleave="focusFalse">
      <div class="button-state-icon-content dark" v-if="activeColor">
        <transition name="fade-opacity-lazy">
          <img v-if="activeButton" :src="getUrlIcon(imgName + '-active-dark.svg')" alt="icon">
          <img v-else :src="getUrlIcon(imgName + '-dark.svg')" alt="icon">
        </transition>
      </div>
      <div class="button-state-icon-content light" v-else>
        <transition name="fade-opacity-lazy">
          <img v-if="activeButton" :src="getUrlIcon(imgName + '-active-light.svg')" alt="icon">
          <img v-else :src="getUrlIcon(imgName + '-light.svg')" alt="icon">
        </transition>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ButtonStateIcon",
  props: {
    activeButton: {
      type: Boolean,
      required: true
    },
    imgName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      focusParam: false
    }
  },
}
</script>

<style scoped lang="scss">
 .button-state-icon-content {
   position: relative;
   cursor: pointer;
   width: 16px;
   height: 16px;

   &.desktop {
     display: block;
   }
   &.tablet-mobile {
     display: none;
   }

   & img {
     width: 100%;
     height: 100%;
   }
 }

 @media (max-width: 1500px) {
   .button-state-icon-content {
     &.desktop {
       display: none;
     }
     &.tablet-mobile {
       display: block;
     }
   }
 }
</style>