<template>
  <div class="dropdown-container">
    <slot/>
    <button-icon :img-name="'arrow'"/>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "DropdownDate",
  components: {ButtonIcon},

}
</script>

<style scoped lang="scss">
  .dropdown-container {
    position: relative;
    width: 100%;
    opacity: .6;
    z-index: 10;
    transition: opacity .5s ease;
    &:hover {
      opacity: 1;
    }

    .button-icon-container {
      pointer-events: none;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
</style>