<template>
  <div class="header-profile-container">
    <button-icon :img-name="'burger'" @click="openMobileMenu"/>
    <avatar-user :img-text="getMeInfo?.avatar || getUrlUser('avatar-default.png')"/>
    <main-paragraph>{{ getMeInfo?.email }}</main-paragraph>
  </div>
</template>

<script>
import AvatarUser from "@/components/shared/AvatarUser.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileHeader",
  components: {ButtonIcon, MainParagraph, AvatarUser},
  async created() {
    await this.checkInfo();
  },
  computed: {
    ...mapGetters(['getMeInfo']),
    activeMobileMenu() {
      return this.$store.state.activeMobileMenu;
    },
  },

  methods: {
    ...mapActions(['ACTIVE_MOBILE_MENU', 'getMe']),
    async checkInfo() {
      if(this.getMeInfo === undefined) {
        try {
          await this.getMe();
        } catch (e) {
          console.log(e)
        }
      }

    },
    openMobileMenu() {
      if(this.activeMobileMenu) {
        this.ACTIVE_MOBILE_MENU(false)
      } else {
        this.ACTIVE_MOBILE_MENU(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .header-profile-container {
   display: flex;

   flex-direction: row-reverse;
   align-items: center;

   .text-content {
     opacity: .6;
   }

   .avatar-user-container {
     margin-left: 24px;
   }

   .button-icon-container {
     display: none;
     opacity: .6;
     transition: opacity .5s ease;
     cursor: pointer;

     &:hover {
       opacity: 1;
     }
   }
 }


 @media (max-width: 769px) {
   .header-profile-container {
     .text-content {
       display: none;
     }

     .avatar-user-container {
       margin-right: 24px;
       margin-left: 0;
     }

     .button-icon-container {
       display: block;
     }
   }
 }
</style>