import {api} from "@/providers/api";

 const POST_TYPE = "POST"
export const newsModules = {
    state: () => ({
        newsList: [],
        totalCountNews: 0,
        // currentPage: 0
    }),
    getters:{
        newsAll: (state) => {
            return state.newsList
        },
        totalCountNewsAll: (state) => {
            return state.totalCountNews
        },
        // currentPageAll: (state) => {
        //     return state.currentPage
        // }
    },
    mutations: {
        SET_NEWS(state, newsInfo) {
            state.newsList = newsInfo
        },
        SET_TOTAL_COUNT_NEWS(state, industryInfo) {
            state.totalCountNews = industryInfo
        },
        // SET_CURRENT_PAGE(state, industryInfo) {
        //     state.currentPage = industryInfo
        // },
    },
    actions: {
        async getNews({commit},  { params } = {params: {}}) {
                let response = await api.post.postControllerGet(POST_TYPE, {
                    ...params
                }).then(r => r.data);
            commit('SET_NEWS', response.data)
            commit('SET_TOTAL_COUNT_NEWS', response?.meta?.total)
            // commit('SET_CURRENT_PAGE', response?.meta?.currentPage)
        }
    }
}