import {api} from "@/providers/api";

const POST_TYPE = "CASE"
export const caseModules = {
    state: () => ({
        caseList: [],
        totalCountCase: 0,
        // currentPage: 0
    }),
    getters:{
        caseAll: (state) => {
            return state.caseList
        },
        totalCountCaseAll: (state) => {
            return state.totalCountCase
        },
        // currentPageAll: (state) => {
        //     return state.currentPage
        // }
    },
    mutations: {
        SET_CASE(state, caseInfo) {
            state.caseList = caseInfo
        },
        SET_TOTAL_COUNT_CASE(state, caseInfo) {
            state.totalCountCase = caseInfo
        },
        // SET_CURRENT_PAGE(state, caseInfo) {
        //     state.currentPage = caseInfo
        // },
    },
    actions: {
        async getCase({commit}, { params } = { params: {}}) {
            let response = await api.post.postControllerGet(POST_TYPE, {
                ...params
            }).then(r => r.data);
            commit('SET_CASE', response?.data)
            commit('SET_TOTAL_COUNT_CASE', response?.meta?.total)
            // commit('SET_CURRENT_PAGE', response?.meta?.currentPage)
        }
    }
}