<template>
  <div class="login-successful-screen main-container">
    <large-title>{{ mainTitle }}</large-title>
    <button-main @click="routTo"
                 :active-button="false"
                 :disabled-button="false">
      {{ buttonText }}
    </button-main>
  </div>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "LoginSuccessfulScreen",
  components: {ButtonMain, LargeTitle},
  data() {
    return {
      buttonText: "Перейти на главную страницу"
    }
  },
  mounted() {
    this.IS_LOADING(false)
  },
  computed: {
    mainTitle() {
      switch(this.$route.name) {
        case 'alert-screen':
          return "Вы авторизованы!"
        case 'not-found-page':
          return "Страница не найдена."
      }
    }
  },
  methods: {
    routTo() {
      this.$router.push('/admin/user-profile');
    }
  }
}
</script>

<style scoped lang="scss">
  .login-successful-screen {
    justify-content: center;
    row-gap: 16px;
    height: 100vh;
    width: 100%;

  }
</style>