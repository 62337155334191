<template>
    <div class="main-profile-screen main-container" v-if="!isLoading">

      <div class="main-profile-photo-content">

        <input-file-main-profile class="user-item-container photo"
                                 @changeFilePhotoProfile="changeFilePhotoProfile"
                                 @changeFileAvatar="changeFileAvatar"
                                 :active-image="activeInfoUser?.profilePhoto"/>
      </div>

      <div class="user-item-container team">
                    <card-team @changeTeam="changeTeam"
                     :active-team="activeInfoUser?.roles"/>
      </div>

      <div class="user-item-container practice">
        <card-related  @changeRelatedCard="changePracticeCard"
                       :name-block="'practice'"
                       :main-title="titlePractice"
                       :empty-info-list="practiceAll"
                       :active-info-list="activePracticeItem"/>
      </div>

      <div class="user-item-container email">
        <main-title>{{ emailTitle }}</main-title>
        <input-main :name-block="'email'"
                    @changeTextInput="changeEmail"
                    :active-text-input="activeInfoUser?.email"
                    :length-text-area="256"
                    :active-glass="false"
                    :info="infoInputEmail"/>
      </div>

      <card-main-profile @changeData="changeDataRu"
                         :active-language="'ru'"
                         :active-user-list="ruLangUserInfo"/>

      <card-main-profile @changeData="changeDataEn"
                         :active-language="'en'"
                         :active-user-list="enLangUserInfo"/>

      <div class="button-submit-container">
        <transition name="fade-opacity-lazy">
          <sub-title class="alert-content" v-if="activeStateAlert">
            {{ alertText }}
          </sub-title>
        </transition>
        <button-main :active-button="false"
                     @click="addResult"
                     :disabled-button="disabledButton">
          {{mainButtonName}}
        </button-main>
      </div>
    </div>
</template>

<script>
import FrameInputFile from "@/components/entities/FrameInputFile.vue";
import CardTeam from "@/components/entities/CardRadioButtonTeam.vue";
import CardRelated from "@/components/entities/ListInfo/CardRelated.vue";
import CardMainProfile from "@/components/entities/CardMainProfile.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import Loader from "@/components/Loader.vue";

import {mapActions, mapGetters} from "vuex";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import {transliterate} from "transliteration";
import InputFileMainProfle from "@/components/entities/InputFileMainProfile.vue";
import InputFileMainProfile from "@/components/entities/InputFileMainProfile.vue";
import InputMain from "@/components/shared/InputMain.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";

export default {


  props: ['id'],
  name: "MainProfileScreen",
  components: {
    MainTitle,
    InputMain,
    InputFileMainProfile,
    InputFileMainProfle,
    SubTitle,
    Loader,
    ButtonMain,
    CardMainProfile,
    CardRelated,
    CardTeam,
    FrameInputFile},
  data() {
    return {
      activeStateAlert: false,
      alertText: '',
      activeInfoUser: {},
      activeIndexUser: 0,
      resultAvatar: '',
      resultProfilePhoto: '',

      mainButtonName: 'Сохранить',
      mainTitle: 'Данные о персоне',
      emailTitle: 'Почта',
      titlePractice: 'Связанные практики',
      infoInput: {
        id: 0,
        placeholder: 'Введите запрос',
        type: 'text',
        dataIndex: 'search'
      },

      infoInputEmail: {
        id: 0,
        placeholder: 'Введите email',
        type: 'textarea',
        dataIndex: 'email'
      },

      resultRuMainProfile: undefined,
      resultEnMainProfile: undefined,
      resultPractice: [],
      resultEmail: '',

      resultRolesList: undefined,

    }
  },

  computed: {
    ...mapGetters(['practiceAll', 'getMeInfo']),


    activePracticeItem() {
      return this.activeInfoUser?.practices?.map(item => item.id);
    },

    ruLangUserInfo() {
        if(this.activeItemListState) {
          let result =  this.checkLangUserInfo(this.activeInfoUser, 'ru');
          result.publication = this.checkKeysListLang(this.activeInfoUser?.publication, 'translations', 'text', 'ru')
          return result;
        } else {
          return undefined
        }

    },
    enLangUserInfo() {
        if(this.activeItemListState) {
          let result =  this.checkLangUserInfo(this.activeInfoUser, 'en');
          result.publication = this.checkKeysListLang(this.activeInfoUser?.publication, 'translations', 'text', 'en')
          return result;
        } else {
          return undefined
        }
    },

    disabledButton() {
      return this.resultEmail === '';
    },

    compareRuEdit() {
      let obj = {
        profiles: this.ruLangUserInfo.profiles,
        publication: this.ruLangUserInfo.publication,
        education: this.ruLangUserInfo.educations
      }
      return this.deepEqual(obj, this.resultRuMainProfile)
    },

    compareEnEdit() {
      let obj = {
        profiles: this.enLangUserInfo.profiles,
        publication: this.enLangUserInfo.publication,
        education: this.enLangUserInfo.educations
      }
      return this.deepEqual(obj, this.resultEnMainProfile)
    },


    activeItemListState() {
      return this.changeActiveItemListState(this.activeInfoUser);
    },


  },

  watch: {
    '$route.name'() {
      this.checkInfo();
    }
  },

  async created() {
    await this.checkInfo();
  },

  methods: {
    ...mapActions(["getActiveUser", "getMe", 'getPractice', 'getEducationLevel']),

    changePracticeCard(item, disableButton) {
      this.resultPractice = item
    },

    async checkInfo() {
      try {
        switch (this.$route.name) {
          case 'edit-profile':
            this.activeIndexUser = Number(this.id);
            this.activeInfoUser = await this.getActiveUser({params: {}, id: this.activeIndexUser})
            break;
          case 'main-user-profile':
            this.activeInfoUser = await this.getMeInfo;
            break;
          case 'add-profile':
            this.activeInfoUser = undefined;
            break;
        }
        await Promise.all([this.getPractice(), this.getEducationLevel()]).then(async r => {
          await this.IS_LOADING(false);
        })
      } catch (e) {
        console.log(e)
      }
    },

    changeEmail(item) {
      this.resultEmail = item
    },

    changeTeam(item) {
      this.resultRolesList = item;
    },

    changeFileAvatar(item) {
      this.resultAvatar = item;
    },

    changeFilePhotoProfile(item) {
      this.resultProfilePhoto = item;
    },

    changeDataRu(itemProfiles, itemPublication, itemEducation, disableButton) {
      this.resultRuMainProfile = {
        profiles: itemProfiles,
        publication: itemPublication,
        educations: itemEducation
      }
    },

    changeDataEn(itemProfiles, itemPublication, itemEducation, disableButton) {
      this.resultEnMainProfile = {
        profiles: itemProfiles,
        publication: itemPublication,
        educations: itemEducation
      }
    },

    addResult() {
      if(this.disabledButton) {
        this.alertText = 'Введите почту!'
        this.activeStateAlert = true;
        setTimeout(() => {
          this.activeStateAlert = false;
        }, 500)
      } else {
        this.resultRuMainProfile?.educations.filter(item => {
          delete item?.educationLevel;
          delete item?.userId;
          delete item?.id;
        })
        this.resultEnMainProfile?.educations.filter(item => {
          delete item?.educationLevel;
          delete item?.userId;
          delete item?.id;
        })
        let result = {}
        result = {
          profile: {
            roles: this.resultRolesList,
            educations: [...this.resultRuMainProfile?.educations, ...this.resultEnMainProfile?.educations],
            profiles: [this.resultRuMainProfile?.profiles, this.resultEnMainProfile?.profiles],
            slug: transliterate(this.resultRuMainProfile?.profiles?.fullName, { replace: {' ': '-'}}),
            email: this.resultEmail
          },
          activePublication: {
            ru: this.ruLangUserInfo?.publication,
            en: this.enLangUserInfo?.publication
          },
          avatar: this.resultAvatar,
          profilePhoto: this.resultProfilePhoto,
          publication: {
            ru: this.resultRuMainProfile?.publication,
            en: this.resultEnMainProfile?.publication
          },
          practice: this.resultPractice
        }
        this.addResultMainProfile(this.activeInfoUser?.id, result, this.activeInfoUser !== undefined)
      }

    },
  }
}
</script>

<style scoped lang="scss">
.main-profile-screen {
  align-self: flex-start;
  width: 100%;
  margin-bottom: 24px;

  .main-profile-photo-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 586px;
    column-gap: 24px;
    margin-bottom: 32px;
  }


  .user-item-container {
    width: 100%;
    margin-bottom: 32px;


    &.avatar {
      align-self: center;
    }

    &.email {
      .text-content {
        margin-bottom: 16px;
      }
    }
  }

  .button-submit-container {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;

    .alert-content {
      position: absolute;
      bottom: -32px;
    }
  }
}

@media (max-width: 769px) {
  .main-profile-screen {
    .main-profile-photo-content {
      align-items: center;
      height: 100%;
      flex-direction: column;
      row-gap: 24px;
    }

    .button-submit-container {

      .alert-content {
        bottom: -24px;
      }
    }
  }
}
</style>