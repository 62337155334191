<template>
  <frame-item :active-small="activeSmall" :class="{'active-input' : activeEditInput}">
    <radio-button class="radio-button-content"
                  v-if="activeRadioButton"
                  @changeStateButton="changeStateRadioButton"
                  :title-button="infoText"
                  :active-button="stateItem"/>

    <div class="list-text-content" v-else>
      <input ref="inputList"
             v-if="activeInputFrame & activeEditInput"
             v-model="activeTextEdit"
             type="text">
      <main-paragraph v-else>
        {{ infoText }}
      </main-paragraph>
    </div>

    <slot/>

    <button-edit-delete v-if="activeEditDelete"
                        @deleteInfo="deleteInfo"
                        @editInfo="editInfo"
                        :active-edit="activeAbilityEdit"
                        :small-icon="true"/>
  </frame-item>
</template>

<script>
import ButtonEditDelete from "@/components/entities/ButtonEditDelete.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import FrameItem from "@/components/shared/FrameItem.vue";
import RadioButton from "@/components/shared/RadioButton.vue";

export default {
  name: "InfoListItem",
  components: {RadioButton, FrameItem, MainParagraph, ButtonEditDelete},
  props: {
    infoText: {
      type: String,
      required: true
    },
    stateItem: {
      default: false
    },
    infoId: {
      type: Number,
      required: true
    },
    activeSmall: {
      type: Boolean,
      required: true
    },
    activeAbilityEdit: {
      type: Boolean,
      required: true
    },
    activeEditDelete: {
      type: Boolean
    },
    activeInputFrame: {
      type: Boolean,
      default: false
    },
    activeRadioButton: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      activeEditInput: false,
      activeTextEdit: '',
    }
  },
  watch: {
    activeTextEdit() {
      this.$emit('editTextInputReactive', this.activeTextEdit);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.infoText.length > 0) {
        this.activeTextEdit = this.infoText;
      }
    })

  },
  methods: {
    changeStateRadioButton() {
      console.log(this.stateItem)
      this.$emit('changeStateRadioButton', this.infoId, !this.stateItem);
    },


    editInfo() {
      this.changeStatusActiveEdit();
      this.$emit('editInfo', this.infoId, this.activeTextEdit)
    },
    deleteInfo() {
      this.activeTextEdit = ''
      this.changeStatusActiveEdit();
      this.$emit('deleteInfo', this.infoId)
    },

    changeStatusActiveEdit() {
      this.activeEditInput = !this.activeEditInput;
      if(this.activeEditInput && this.activeInputFrame) {
        this.$nextTick(() => {
          this.$refs.inputList.focus();
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
input {
  color: var(--color-text);
  font-size: 14px;
  width: 100%;
  background-color: rgba(1,1,1,0);
  font-family: 'Raleway-Regular', sans-serif;
}

.info-item-container {
  padding: 16px 8px;
  border-radius: 10px;
  opacity: .6;
  background-color: var(--color-field);
  transition: opacity .5s ease;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &.active-input {
    opacity: 1;
  }

  .list-text-content {
    width: 100%;
  }

  &.radio-button-content {
    padding: 0 !important;
    background-color: rgba(0,0,0,0);
    width: auto;
    justify-content: left;
  }

  &.active-small {
    padding: 8px 16px 8px 8px;
  }

  &:hover {
    opacity: 1;
  }
}
</style>