<template>
  <div class="input-file-container" :class="{'active-post-content' : activePostContent,
                                             'active-photo-user-content': activeRectangle,
                                             'active-rating-content' : activeRating,
                                             'active-partners-content' : activePartnersContent,}">
    <transition name="fade-opacity-lazy">
      <div class="input-file-content row" v-if="!activeResult">
        <input-file :accept-file="acceptFile" :active-circle="activeCircle" :active-input="activeInput" @changeFile="handleFileChange">
          {{ placeholderText }}
        </input-file>
      </div>
      <div class="input-file-content column result" v-else>
        <div class="result-container">
          <img :src="fileCrossSrc" v-if="activeCroppedModal" alt="result"/>
          <div  v-html="fileCrossSrc"  v-else-if="!activeCroppedModal && !typeOfWithoutCross"/>
          <img :src="fileCrossSrc" v-else alt="result"/>
        </div>
        <input-file class="load"
                    :accept-file="acceptFile"
                    :active-input="activeInput"
                    @changeFile="handleFileChange"
                    @mouseenter="changeTitleResult"
                    @mouseleave="changeTitleResult">
          {{loadingTitle}}
        </input-file>
      </div>
    </transition>
    <transition name="fade-scale">
      <modal-crop-image :active-circle="activeCircle"
                        :active-rectangle="activeRectangle"
                        :active-square="activeSquare"
                        v-if="activeCropper"
                        :file-src="fileSrc"
                        @crossImage="crossImage"
                        @closeModal="closeModal"/>
    </transition>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ModalCropImage from "@/components/entities/ModalCropImage.vue";
import InputFile from "@/components/shared/InputFile.vue";
export default {
  name: "FrameInputFile",
  components: {InputFile, ModalCropImage, ButtonMain, ModalFrame, ButtonIcon},
  props: {
    activeInput: {
      default: 'photo'
    },

    activeRating: {
      type: Boolean,
      default: false
    },

    activeCroppedModal: {
      type: Boolean,
      default: true
    },
    activeDelete: true,

    acceptFile: {
      type: String,
      default: '.png, .jpg, .svg'
    },
    placeholderText: {
      type: String,
      required: true
    },
    activeImage: {
      type: String
    },
    activePostContent: {
      type: Boolean,
      default: false,
    },
    activePartnersContent: {
      type: Boolean,
      default: false
    },
    activeCircle: {
      type: Boolean,
      default: true,
    },
    activeSquare: {
      default: false
    },
    activeRectangle: {
      default: false
    }
  },
  data() {
    return {
      focusParam: true,
      fileSrc: null,
      fileCrossSrc: null,
      cropper: null,
      activeCropper: false,
      croppedImageUrl: '',
      activeResult: false,
      loadingTitle: 'Фотография загружена',
      fileTarget: '',
      activeResultTitle: false,
      typeOfWithoutCross: false
    }
  },
  mounted() {
    this.checkActivePhoto();
  },
  watch: {
    activeResultTitle() {
      if(this.activeResultTitle) {
        this.loadingTitle = 'Обновить фотографию'
      } else {
        this.loadingTitle = 'Фотография загружена'
      }
    },
  },
  methods: {
    checkActivePhoto() {
      if(this.activeImage !== undefined && this.activeImage !== null && this.activeImage !== '') {
        this.fileSrc = this.activeImage
        this.fileCrossSrc = this.activeImage
        this.activeResult = true
        this.$nextTick(() => {
          let parentBlock = document.querySelector('.result-container')
          let childBlock = parentBlock.querySelector('svg')
          if (childBlock !== null) {
            childBlock.setAttribute('width', '100%')
            childBlock.setAttribute('height', '100%')
          }
        })

      }
    },

    closeModal() {
      this.activeCropper = false
      this.fileSrc = this.fileCrossSrc
    },

    handleFileChange(fileSrc, file) {
      this.fileSrc = fileSrc;
      this.fileTarget = file;
      if(this.activeCroppedModal) {
        this.activeCropper = true
      } else {
        this.typeOfWithoutCross = true
        this.fileCrossSrc = fileSrc;
        this.activeResult = true;
        this.$emit('changeFile', this.fileTarget)
      }
    },

    crossImage(img) {
      this.activeCropper = false;
      this.activeResult = true;
      this.fileCrossSrc = img.toDataURL();
      this.$emit('changeFile', {canvas: img, fileTarget: this.fileTarget})
    },

    changeTitleResult() {
      this.activeResultTitle = !this.activeResultTitle
    }
  },
}
</script>

<style scoped lang="scss">

.input-file-container {
  max-width: 400px;
  width: 100%;
  height: 400px;
  .input-file-content {
    max-width: 400px;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: space-between;
  }


  .input-content {
     &.load {
       height: 68px;
     }
   }

  .result-container {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;

    .cross-container {
      background-color: var(--color-field);
      border: 1px solid var(--color-stroke);
      padding: 4px;
      border-radius: 5px;
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: .6;
      cursor: pointer;
      transition: opacity .5s ease;

      &:hover {
        opacity: 1;
      }
    }

    & img {
      width: 100%;
      height: 100%;
    }

    & div {
      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  #fileInput {
    position: absolute;
    z-index: 999;
  }

  .input-file-content input[type="file"] {
    display: none;
  }


  &.active-post-content {
    width: 100%;
    max-height: 600px;
    height: auto;
    max-width: 100%;


    .input-file-content {
      height: 64px;
      max-width: 100%;
      width: 100%;

      &.result {
        height: 350px;
      }
    }

    .input-content {
      &.load {
        height: 32px;
      }
    }

    .result-container {
      height: 300px;
      max-width: 540px;
      width: 100%;
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 16px;
    }
  }

  &.active-partners-content {
    width: 100%;
    max-height: 250px;
    height: auto;
    max-width: 250px;

    .input-file-content {
      height: 250px;
      max-width: 250px;
      width: 100%;

      &.result {
        width: 250px;
        height: 250px;
      }
    }

    .input-content {
      &.load {
        height: 32px;
      }
    }

    .result-container {
      height: 200px;
      max-width: 200px;
      width: 100%;
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 16px;

      &.active-rating {
        max-width: 100%;
      }
    }
  }

  &.active-rating-content {
    display: flex;
    justify-content: center;
    max-width: 100%;
    .input-file-content {

      .input-content {
        &.load {
          max-width: 250px;
        }
      }

      &.result {
        max-width: 100%;
        width: 100%;
      }
    }

    .result-container {
      max-width: 100%;

      & img {
        object-fit: contain;
      }
    }
  }




  &.active-photo-user-content {
    width: 100%;
    max-height: 500px;
    height: 100%;
    max-width: 300px;


    .input-file-content {
      height: 500px;
      max-width: 300px;
      width: 100%;

      &.result {
        width: 300px;
        height: 500px;
      }
    }

    .input-content {
      &.load {
        height: 32px;
      }
    }

    .result-container {
      height: 100%;
      max-width: 256px;
      width: 100%;
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 16px;
    }
  }
}


@media  (max-width: 769px) {
  .input-file-container {

    &.active-post-content {
      .input-file-content {

        &.result {
          height: auto;
        }
      }
      .result-container {
        height: auto;
      }
    }
  }
}



</style>