<template>
  <div class="card-team-container">
    <sub-title>{{mainTitle}}</sub-title>
    <div class="card-type-team-container">
      <radio-button v-for="item in typeTeamInfo"
                    @changeStateButton="changeStateTeam(item.id)"
                    :title-button="item.name"
                    :active-button="item.active"/>
    </div>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import ButtonStateIcon from "@/components/shared/ButtonStateIcon.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import RadioButton from "@/components/shared/RadioButton.vue";

export default {
  name: "CardRadioButtonTeam",
  components: {RadioButton, MainParagraph, ButtonStateIcon, SubTitle},
  props: {
    activeTeam: {
      type: Array
    }
  },
  mounted() {
    this.checkActiveTeam();
  },
  data() {
    return {
      mainTitle: 'Связанные вкладки – Страница «Команда»',
      typeTeamInfo: [
        {
          id: 0,
          name: 'Партнеры',
          nameDB: 'PARTNER',
          active: false
        },
        {
          id: 1,
          name: 'Руководители практик',
          nameDB: 'LEADER',
          active: false
        },
        {
          id: 2,
          name: 'Сотрудники',
          nameDB: 'EMPLOYEES',
          active: false
        }
      ]
    }
  },
  methods: {
    checkActiveTeam() {
      if(this.activeTeam !== undefined) {
        this.activeTeam.forEach(item => {
          let indexType = this.typeTeamInfo.findIndex(itemType => itemType.nameDB.includes(item))
          this.typeTeamInfo[indexType].active = true
        })
        this.$emit('changeTeam', this.typeTeamInfo.filter(item => item.active === true).map(item => item.nameDB))
      }
    },
    changeStateTeam(id) {
      let counter = 0;
      this.typeTeamInfo.forEach(item => {
        if(item.active) {
          counter++;
        }
      })
      if(counter > 1) {
        this.typeTeamInfo[id].active = false
      } else {
        this.typeTeamInfo[id].active = !this.typeTeamInfo[id].active
      }
      this.$emit('changeTeam', this.typeTeamInfo.filter(item => item.active === true).map(item => item.nameDB))
    }
  }
}
</script>

<style scoped lang="scss">
  .card-team-container {
    width: 100%;

    .text-content {
      margin-bottom: 16px;
    }

    .card-type-team-container {
      width: 100%;

      .info-item-container {
        margin: 2px 0;
        &:nth-child(2n){
          background-color: rgba(0,0,0,0);
        }

        &:first-child {
          margin: 0 0 2px;
        }

        &:last-child {
          margin: 2px 0 0;
        }
      }



    }
  }
</style>