<template>
  <div class="partners-screen-container main-container" v-if="!isLoading">
    <transition name="fade-scale">
      <modal-agreement v-if="activeModal"
                       :active-change-text="true"
                       @cancelInfo="changeModal"
                       @agreeInfo="checkCRUDmethods"
                       :sub-title="modalTitle"/>
    </transition>
    <div class="main-title-container row">
      <main-title>
        {{ mainTitleDOM }}
      </main-title>
<!--      <button-main class="active-title"-->
<!--                   :active-button="false"-->
<!--                   @click="openCRUDBlock"-->
<!--                   :disabled-button="false">-->
<!--        {{ saveButton }}-->
<!--      </button-main>-->
    </div>
    <div class="info-list-container column" >
        <info-list-item v-for="item in resultInfo"
                        :active-radio-button="false"
                        :active-edit-delete="false"
                        :active-ability-edit="false"
                        :active-input-frame="false"
                        :active-small="false"
                        :info-id="item.id"
                        :info-text="item.ru">
          <div class="button-list-icon-container">
            <button-icon @click="changeModal(item.id, 'hide')" v-if="!item.hide" img-name="eyes"/>
            <button-icon @click="changeModal(item.id, 'hide')" v-else img-name="not-eyes"/>
<!--            <button-icon @click="changeModal(item.id, 'delete')" img-name="trash-can"/>-->
          </div>
    </info-list-item>
    </div>
<!--    <transition name="fade-translate-x">-->
<!--      <card-partners v-if="activeState"-->
<!--                     :active-rating="true"-->
<!--                     @addResult="addResult"-->
<!--                     :active-item-list="undefined"/>-->
<!--    </transition>-->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InfoListItem from "@/components/entities/InfoListItem.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ModalAgreement from "@/components/entities/ModalAgreement.vue";
import {api} from "@/providers/api";
import CardPartners from "@/components/entities/CardPartners.vue";

export default {
  name: "RatingsScreen",
  components: {CardPartners, ModalAgreement, ButtonMain, MainTitle, ButtonIcon, InfoListItem},
  data() {
    return {
      mainTitleDOM: 'Рейтинги',
      saveButton: 'Добавить рейтинг',
      resultInfo: [],
      modalTitle: 'Вы действительно хотите скрыть/показать рейтинг?',
      activeModal: false,
      activeId: -1,
      activeState: false,
      typeCRUD: ''
    }
  },
  created() {
    this.checkInfo();
  },

  computed: {
    ...mapGetters(['ratingAll']),

    modalTitle() {
      if(this.typeCRUD === 'delete') {
        return 'Вы действительно хотите удалить рейтинг?\nОтменить действие будет невозможно'
      } else {
        return 'Вы действительно хотите скрыть/показать рейтинг?'
      }
    },

    activeItem() {
      return this.ratingAll.find(item => item.id === this.activeId);
    }
  },

  methods: {
    ...mapActions(['getRating', 'IS_LOADING_SEARCH']),
    async checkInfo() {
      try {
        await Promise.all([this.getRating()])
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING(false)
        this.resultInfo = JSON.parse(JSON.stringify(this.ratingAll));
      }
    },

   async checkCRUDmethods() {
     this.IS_LOADING_SEARCH(true)
      if(this.typeCRUD === 'delete') {
        try {
          await api.partner.partnerControllerDeletePartner(this.activeId).then(r => r.data)
        } catch (e) {
          console.log(e)
        } finally {
          this.IS_LOADING_SEARCH(false)
          this.changeModal();
          await this.checkInfo();
        }
      } else {
        try {
          await api.partner.partnerControllerHide(this.activeId, !this.activeItem.hide)
        } catch (e) {
          console.log(e)
        } finally {
          this.IS_LOADING_SEARCH(false)
          this.changeModal();
          await this.checkInfo();
        }
      }

    },

    addResult(item, typeCRUD) {
      this.IS_LOADING_SEARCH(true)
      if(typeCRUD) {
        // this.ediPartners(this.activeId, item, item.photo)
      } else {
        this.addResultPartners(item, item.photo)
      }
    },

    async addResultPartners(item, itemPhoto) {
      try {
        let formData = new FormData();
        formData.append('croppedImage', itemPhoto, itemPhoto.name);
        await api.partner.partnerControllerCreate({
          photo: formData.get('croppedImage'),
          ru: item.ru,
          partnerType: "SVG"
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).finally(async () => {
          this.IS_LOADING_SEARCH(false)
          await this.checkInfo();
          this.activeId = -1
          this.activeState = false
        })
      } catch (e) {
        console.log(e)
      }
    },

    changeModal(id, type) {
      this.activeModal = !this.activeModal
      this.typeCRUD = type
      if(id !== undefined) {
        this.activeId = id
      } else {
        this.activeId = -1
      }
    },

    openCRUDBlock() {
      this.activeState = !this.activeState
    }
  }
}
</script>

<style scoped lang="scss">

.button-list-icon-container {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding-right: 8px;
  .button-icon-container {
    width: 32px;
    height: 32px;
    cursor: pointer
  }
}


  .partners-screen-container {
    justify-content: flex-start;
    row-gap: 16px;

    .text-content {
      align-self: flex-start;
    }

    .main-title-container {
      width: 100%;
      justify-content: space-between;
    }

    .info-list-container {
      width: 100%;

        .info-item-container {
          &:nth-child(2n) {
            background-color: rgba(1, 1, 1, 0);
          }
        }
    }

    .card-partners-container {
      width: 100%;
    }
  }
</style>