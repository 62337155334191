<template>
  <div class="drop-down-list-container" ref="customDropdown">
    <div class="drop-down-list-button" :class="{'active' : activeList}" @click="changeActiveList">
      <p>
        {{activePlaceholder}}
      </p>
      <button-icon :img-name="'arrow'"/>
    </div>
    <transition name="fade-translate-y">
      <div class="drop-down-list-content" v-if="activeList">
        <ul class="list-content">
          <li class="list-text"
              :class="{'active-text' : activePlaceholder === info.name}"
              @click="chooseText(info, index)"
              v-for="(info, index) in infoList.infoText"
              :key="info?.id || index">
            {{info?.name || info}}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "DropDownList",
  components: {ButtonIcon},
  props: {
    infoList: {
      type: Object,
      required: true
    },
    activeModalLogic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activePlaceholder: '',
      activeList: false,
      activeNumberList: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activePlaceholder = this.infoList.placeholder;
    })
    window.addEventListener('click', this.closeOptions);
  },
  unmounted() {
    window.removeEventListener('click', this.closeOptions)
  },
  methods: {

    closeOptions(event) {
      if (!this.$refs.customDropdown.contains(event.target)) {
        this.activeList = false;
      }
    },
    changeActiveList() {
      this.activeList = !this.activeList;
    },
    chooseText(text, index) {
      if(this.activeModalLogic) {
        this.$emit('changeDropdownList', index)
      } else {
        if(text.name === this.activePlaceholder) {
          this.$emit('changeDropdownList', -1)
          this.activePlaceholder = this.infoList.placeholder
        } else {
          this.$emit('changeDropdownList', text.id)
          this.activePlaceholder = text.name
        }
      }

     this.changeActiveList()
    }
  }
}
</script>

<style scoped lang="scss">
.drop-down-list-container {
  position: relative;
  .drop-down-list-button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    padding: 4px 8px;
    transition: opacity .5s ease;
    opacity: .6;
    background-color: var(--color-field);
    &:hover {
      opacity: 1;
    }

    &.active {
      outline: 1px solid var(--color-stroke);
      opacity: 1;
    }
  }

  .drop-down-list-content {
    margin-top: 8px;
    width: inherit;
    max-width: inherit;
    background-color: var(--color-background);
    z-index: 11;
    position: absolute;

    .list-content {
      display: flex;
      flex-direction: column;

      .list-text {
        line-height: normal;
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 10px;
        background-color: var(--color-field);
        opacity: .6;
        transition: opacity .5s ease;

        &.active-text {
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }

        &:nth-child(2n) {
          background-color: rgba(0,0,0,0);
        }
      }
    }
  }
}


@media (max-width: 769px) {
  .drop-down-list-container {
    font-size: 12px;

  }
}


</style>