<template>
  <div class="user-list-screen-container main-container" v-if="!isLoading">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                   @cancelInfo="activeModal = false"
                   :sub-title="activeModalTitle"/>
    </transition>

    <transition name="fade-scale">
      <modal-agreement v-if="activeModalPassword"
                       @cancelInfo="changeStateModalPassword"
                       @agreeInfo="changePasswordBackend"
                       :active-change-text="true"
                       :sub-title="changePasswordTitle">
        <input-main :active-glass="false"
                    :length-text-area="30"
                    :info="newPasswordInfoInput"
                    @changeTextInput="changePasswordUser"
                    :name-block="'modal-agreement-content'"/>
      </modal-agreement>
    </transition>
    <transition name="fade-scale">
      <modal-agreement v-if="activeModalRole"
                       :active-change-text="true"
                       @cancelInfo="changeStateModalRole"
                       @agreeInfo="changeRoleBackend"
                       :sub-title="changeRoleTitle"/>
    </transition>


    <transition name="fade-scale">
    <modal-delete v-if="activeModalDeleteUser"
                  @cancelModal="changeStateModalDelete"
                  @deleteBackend="deleteUserBackend"/>
    </transition>
    <div class="main-title-container row">
      <main-title>{{ mainTitle }}</main-title>
      <button-main @click="createNewUser" :active-button="false" :disabled-button="false">
        {{ nameButton }}
      </button-main>
    </div>
    <div class="search-user-container">
      <input-main :active-glass="true"
                  @changeTextInput="changeSearchText"
                  :name-block="'search-user-container'"
                  :info="infoInput"/>
    </div>
    <transition name="fade-translate-x">
    <div class="user-list-container column" v-if="getUsersAll !== null">
      <transition-group name="fade-translate-x">
      <card-user v-for="info in getUsersAll"
                 :key="info.id"
                 :active-dropdown-change="false"
                 @changePassword="changePassword"
                 @editInfo="editUser"
                 @deleteInfo="deleteUser"
                 @changeRole="changeRole"
                 :user-info="info"/>
            </transition-group>
    </div>
    </transition>
  </div>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import CardUser from "@/components/entities/CardUser.vue";
import {mapActions, mapGetters} from "vuex";
import ModalAgreement from "@/components/entities/ModalAgreement.vue";
import ModalDelete from "@/components/entities/ModalDelete.vue";
import {api} from "@/providers/api";
import ModalArea from "@/components/entities/ModalArea.vue";
import ModalAlert from "@/components/entities/ModalAlert.vue";
export default {
  name: "UserListScreen",
  components: {ModalAlert, ModalArea, ModalDelete, ModalAgreement, CardUser, InputMain, MainTitle, ButtonMain},
  data() {
    return {
      mainTitle: 'Список персон',
      nameButton: 'Добавить персону',
      infoInput: {
        id: 0,
        placeholder: 'Введите запрос',
        type: 'text',
        dataIndex: 'search'
      },


      activeModal: false,
      activeModalTitle: '',

      searchText: '',
      idDelete: -1,
      idEdit: -1,
      idPassword: -1,
      emailPassword: '',
      itemRole: {
        id: -1,
        text: ''
      },
      changePasswordTitle: 'Вы действительно хотите сменить пароль?\nОтменить действие будет невозможно.',
      changeRoleTitle: 'Вы действительно хотите сменить роль пользователя?',
      titleNewPerson: 'Новый пользователь',
      activeModalPassword: false,
      activeModalDeleteUser: false,
      activeModalRole: false,

      resultUser: {},
      newUserInfoInput: {
        id: 0,
        placeholder: 'Введите e-mail',
        dataIndex: 'text',
        type: 'textarea'
      },
      newPasswordInfoInput: {
        id: 0,
        placeholder: 'Введите новый пароль',
        dataIndex: 'text',
        type: 'textarea'
      },
      textNewPassword: ''
    }
  },

  async created() {
    await this.checkInfo();
  },
  watch: {
    async searchText() {
      await this.checkSearchQuery('getUsers', this.searchText);
    },
  },

  computed: {
    ...mapGetters(['getUsersAll']),
  },
  methods: {
    ...mapActions(['getUsers']),

    async checkInfo() {
      try {
        await this.getUsers('getUsers');
      } catch (e) {
        console.log(e)
      } finally {
          this.IS_LOADING(false)
      }
    },


    createNewUser() {
      this.$router.push({name: 'add-profile'})
    },

    changePassword(id, email) {
      this.idPassword = id
      this.emailPassword = email
      this.changeStateModalPassword();
    },

    changePasswordBackend() {
      if(this.textNewPassword !== '') {
        console.log('Пароль сброшен с помощью админа', this.idPassword)
        this.changePasswordAdmin();
      } else {
        console.log('Пароль сброшен через почту', this.idPassword, this.emailPassword)
        this.changePasswordEmail();
      }
      this.changeStateModalPassword();
      this.textNewPassword = ''
      this.emailPassword = ''
    },

    async changePasswordAdmin() {
      this.IS_LOADING_SEARCH(true)
      try {
        let response = await api.admin.userAdminControllerResetPassword(this.idPassword, {
          password: this.textNewPassword
        }).then(r => {
          this.activeModalTitle =  'Пароль успешно изменен'
          this.activeModal = true
          this.IS_LOADING_SEARCH(false)
        })
      } catch (e) {
        console.log(e)
      }
    },

    async changePasswordEmail() {
      this.IS_LOADING_SEARCH(true)
      try {
        let response = await api.auth.authControllerResetPassword({email: this.emailPassword}).then(r => {
          this.activeModalTitle =  'Запрос на смену пароль отправлен успешно'
          this.activeModal = true
          this.IS_LOADING_SEARCH(false)
        })
      } catch (e) {
        console.log(e)
      }
    },


    changeStateModalPassword() {
      this.activeModalPassword = !this.activeModalPassword
    },

    deleteUser(id) {
      this.idDelete = id
      this.changeStateModalDelete();
    },

    changeStateModalRole() {
      this.activeModalRole = !this.activeModalRole
    },

    async changeRoleBackend() {
      try {
        await api.admin.userAdminControllerPatchUserRole(this.itemRole.text, this.itemRole.id).then(r => r.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.$router.go(0)
      }
    },

    changePasswordUser(text) {
      this.textNewPassword = text
    },

    changeRole(id, item) {
      this.itemRole.id = id;
      this.itemRole.text = item
      this.changeStateModalRole()
    },

    async deleteUserBackend() {
      try {
        await api.admin.userAdminControllerRemoveUser(this.idDelete).then(r => {
          this.activeModalTitle =  'Пользователь успешно удален'
          this.activeModal = true
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.changeStateModalDelete();
        this.IS_LOADING(true)
        await this.checkInfo();
      }
    },

    changeStateModalDelete() {
      this.activeModalDeleteUser = !this.activeModalDeleteUser
    },

    editUser(id) {
      this.$router.push({name: 'edit-profile', params: {id: id}})
    }
  }
}
</script>

<style scoped lang="scss">

  .user-list-screen-container {
    justify-content: flex-start;
    .main-title-container {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 32px;
    }

    .modal-agreement-container {
      .input-container {
        margin-bottom: 32px;
      }
    }

    .search-user-container {
      width: 100%;
      margin-bottom: 32px;
    }

    .user-list-container {
      overflow-y: auto;
      padding: 8px 39px 8px 0;
      max-height: 400px;
      height: 100%;
      width: 100%;

      .user-card-container {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &::-webkit-scrollbar {
        height: 100%;
        max-width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: var(--scrollbar-color-track);
        width: 1px;
        border-radius: 30%;
      }


      &::-webkit-scrollbar-thumb {
        width: 7px;
        background-color: var(--scrollbar-color);
        border-radius: 4px;

      }

      &::-webkit-scrollbar-thumb:hover{
        background-color: var(--scrollbar-color-hover);
      }
    }
  }

  @media (max-width: 769px)  {
    .user-list-screen-container {
      justify-content: flex-start;
      .user-list-container  {
        padding: 8px 0;
      }
    }
  }
</style>