<template>
  <div class="edit-delete-button-container row" :class="{'small-icon' : smallIcon, 'active-list-user' : !activeListUser}">
    <button-icon @click="editInfo" :class="{'not-active-edit' : !activeEdit}"  :img-name="'pencil-hover'"/>
    <button-icon @click="deleteInfo"  :img-name="'trash-can-hover'"/>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "ButtonEditDelete",
  components: {ButtonIcon},
  props: {
    smallIcon: {
      type: Boolean,
      required: true
    },
    activeEdit: {
      type: Boolean,
      required: true
    },
    activeListUser: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteInfo() {
      this.$emit('deleteInfo')
    },
    editInfo() {
      if(this.activeEdit) {
        this.$emit('editInfo')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.edit-delete-button-container {

  &.small-icon {
    .button-icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .button-icon-container {
    opacity: .6;
    cursor: pointer;
    transition: .5s ease opacity;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 24px;

    &.not-active-edit {
      opacity: 0;
    }

    &:hover {
      opacity: 1;
    }


    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 769px) {
    .edit-delete-button-container {

      .button-icon-container {
        margin-right: 14px;
      }

      &.not-active-list-user {
        .button-icon-container {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }

    }
}
</style>