<template>
  <div class="industry-screen-container main-container" v-if="!isLoading">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                   @cancelInfo="activeModal = false"
                   :sub-title="activeModalTitle"/>
    </transition>


    <list-frame class="industry"
                :num-pagination="totalCountIndustryAll"
                :current-page="Number(page)"
                :active-focus="true"
                :config-list="'pagination'"
                :active-text-input="search"
                @changeSearchText="changeSearchText"
                @editItemInfo="editItemList"
                @addObject="changeStateAddItem"
                @deleteBackendInfo="deleteBackendInfo"
                @changePageList="checkInfoPage"
                :name-block="nameBlock"
                :setting-list="settingList"
                :name-button="mainNameButton"
                :active-add-object="activeState"
                :info-list="modificationsList"
                :main-title-d-o-m="mainTitle"/>
    <transition name="fade-translate-x">
      <card-add-info v-if="activeState"
                     @addResult="addResult"
                     :name-id="'industryId'"
                     :additional-title="'отрасли'"
                     :active-item-list="activeItemIndustry">
      </card-add-info>
    </transition>
  </div>
</template>

<script>
import ListFrame from "@/components/entities/FrameListContainer.vue";
import CardAddInfo from "@/components/entities/CardAddInfo.vue";
import CardRelated from "@/components/entities/ListInfo/CardRelated.vue";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/providers/api";
import ModalAlert from "@/components/entities/ModalAlert.vue";

export default {
  name: "IndustriesScreen",
  components: {ModalAlert, CardRelated, CardAddInfo, ListFrame},


  props: ['page', 'search'],

  data() {
    return {
      mainTitle: 'Список отраслей',
      searchText: '',
      titleServices: 'Связанные отрасли',

      activeModal: false,
      activeModalTitle: '',

      nameButtonType: ' отрасль',
      nameButtonCRUD: 'Добавить',

      activeState: false,
      idItem: -1,
      activeItemIndustry: undefined,
      settingList: {
        activeTitle: true,
        activeInput: true,
        activeSmallIcon: false,
        activeEdit: true,
        activeButton: true,
      },
      nameBlock: 'industry',
    }
  },



  watch: {
    async searchText() {
      await this.checkInfoPage(1);
    },

    idItem() {
      if(this.idItem !== -1) {
        this.activeItemIndustry =  this.findById(this.industryAll, this.idItem);
      } else {
        this.activeItemIndustry = undefined
      }
    }
  },
  async created() {
    await this.checkInfo();
  },

  computed: {
    ...mapGetters(['industryAll', 'totalCountIndustryAll', 'currentPageAll']),

    mainNameButton() {
      return this.nameButtonCRUD.concat(this.nameButtonType);
    },


    modificationsList() {
        try {
          return this.checkKeysList(this.industryAll, 'modifications', 'name','ru');
        } catch (e) {
          return undefined
        }
    }
  },
  methods: {
    ...mapActions(['getIndustry', 'IS_LOADING_SEARCH']),


    async checkInfo(currentPage) {
      if(currentPage !== undefined) {
        this.currentPage = currentPage
        this.IS_LOADING_SEARCH(true)
      }
        try {
          await this.getIndustry({
            params: {
              limit: this.$store.state.DEFAULT_PAGE_LIMIT,
              page: this.page,
              search: this.search || this.searchText
            }
          });
        } catch (e) {
          console.log(e)
        } finally {
          this.IS_LOADING(false)
          this.IS_LOADING_SEARCH(false)
        }
    },

    async checkInfoPage(currentPage) {
      if(this.searchText === '') {
        this.IS_LOADING_SEARCH(true)
        this.$router.push({name: 'industry', params: {page: currentPage}}).then(async () => {
          this.$store.dispatch('IS_LOADING', false)
          await this.checkInfo();
        })
      } else {
        await this.checkInfoSearch(currentPage);
      }

    },
    async checkInfoSearch(currentPage) {
      this.$router.push({name: 'industry-search', params: {page: currentPage, search: this.searchText}}).then(async () => {
        this.$store.dispatch('IS_LOADING', false)
        await this.checkInfo();
      })
    },


    addResult(item, typeCRUD) {
      this.IS_LOADING_SEARCH(true)
      if(typeCRUD) {
        this.editInfoIndustry(item);
      } else {
        this.addInfoIndustry(item);
      }
    },

    async editInfoIndustry(item) {
      try {
        await api.industry.industryControllerPatch(this.idItem, {...item}).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.nameButtonCRUD = 'Добавить'
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Отрасль успешно отредактирована'
        this.activeModal = true
      }
    },
    async addInfoIndustry(item) {
      try {
        await api.industry.industryControllerCreate({...item}).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Отрасль успешно добавлена'
        this.activeModal = true
      }
    },
    async deleteBackendInfo(id) {
      this.IS_LOADING_SEARCH(true)
      try {
        await api.industry.industryControllerDelete(id).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Отрасль успешно удалена'
        this.activeModal = true
      }
    },
  }
}
</script>

<style scoped>

</style>