<template>
  <modal-frame :button-text="buttonName"
               :main-title="mainTitle"
               @submitInfo="cropImage"
               v-if="activeCircle">
    <div class="modal-crop-image-container active-circle">
      <vue-cropper ref="cropper"
                   class="cropper-container"
                   :output-size="{ width: 300, height: 300 }"
                   :view-mode="1"
                   :drag-mode="'move'"
                   :rotatable="false"
                   :initial-aspect-ratio="1"
                   :aspect-ratio="3 / 3"
                   :src="fileSrc"
                   preview=".preview"
      />
      <div class="preview active-circle"/>
    </div>
  </modal-frame>
  <modal-frame :button-text="buttonName"
               :main-title="mainTitle"
               @submitInfo="cropImage"
               v-else-if="activeSquare">
    <div class="modal-crop-image-container active-square">
      <vue-cropper ref="cropper"
                   class="cropper-container"
                   :output-size="{ width: 300, height: 300 }"
                   :view-mode="1"
                   :drag-mode="'move'"
                   :rotatable="false"
                   :initial-aspect-ratio="1"
                   :aspect-ratio="3 / 3"
                   :src="fileSrc"
                   preview=".preview"
      />
      <div class="preview"/>
    </div>
  </modal-frame>
  <modal-frame :button-text="buttonName"
               :main-title="mainTitle"
               @submitInfo="cropImage"
               v-else-if="activeRectangle">
    <div class="modal-crop-image-container active-rectangle">
      <vue-cropper ref="cropper"
                   class="cropper-container"
                   :output-size="{ width: 374, height: 540 }"
                   :view-mode="1"
                   :drag-mode="'move'"
                   :rotatable="false"
                   :initial-aspect-ratio="1"
                   :aspect-ratio="9 / 13"
                   :src="fileSrc"
                   preview=".preview"
      />
      <div class="preview"/>
    </div>
  </modal-frame>
  <modal-frame :button-text="buttonName"
               :main-title="mainTitle"
               @submitInfo="cropImage"
               v-else>
    <div class="modal-crop-image-container">
      <vue-cropper ref="cropper"
                   class="cropper-container"
                   :output-size="{ width: 500, height: 120 }"
                   :view-mode="1"
                   :drag-mode="'move'"
                   :rotatable="false"
                   :initial-aspect-ratio="1"
                   :aspect-ratio="16 / 9"
                   :src="fileSrc"
                   preview=".preview"
      />
      <div class="preview"/>
    </div>
  </modal-frame>
</template>

<script>
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import VueCropper from 'vue-cropperjs';
export default {
  name: "ModalCropImage",
  components: {ButtonMain, ModalFrame, VueCropper},
  props: {
    fileSrc: {
      type: String,
      required: true
    },
    activeCircle: {
      type: Boolean,
      required: true
    },
    activeSquare: {
      type: Boolean,
      required: true
    },
    activeRectangle: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      mainTitle: 'Обрезать фотографию',
      buttonName: 'Сохранить',
      buttonCropName: 'Обрезать',
      showCropper: false,
        croppedImageUrl: '',
      blob: ''
    }
  },
  methods: {
    async cropImage() {

      this.croppedImageUrl = this.$refs.cropper.getCroppedCanvas({
        width: 1080,
        height: 1920,
      });
      console.log(this.croppedImageUrl)
      this.$emit('crossImage', this.croppedImageUrl)
    },
  }
}

</script>

<style scoped lang="scss">
.modal-crop-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  &.active-circle {
    .cropper-container {
      width: 300px;
      height: 400px;
    }

    .preview {
      width: 250px;
      height: 250px;
      border-radius: 50%;
    }
  }

  &.active-square {
    .preview {
      width: 250px;
      height: 250px;
    }
  }

  &.active-rectangle {
    .preview {
      width: 250px;
      height: 400px;
    }
  }

  .cropper-container {
    width: 250px;
    height: 400px;
  }

  .preview {
    overflow: hidden;
    width: 300px;
    height: 250px;
  }
}

@media (max-width: 769px) {
  .modal-crop-image-container {
    align-items: center;
    flex-direction: column;

    &.active-circle {
      .cropper-container {
        width: 200px;
        height: 250px;
      }

      .preview {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
    }

    &.active-square {
      .preview {
        width: 175px;
        height: 175px;
      }
    }

    &.active-rectangle {
      .cropper-container {
        width: 200px;
        height: 200px;
      }

      .preview {
        width: 150px;
        height: 200px;
      }
    }

    .cropper-container {
      width: 200px;
      height: 250px;
      margin-bottom: 16px;
    }

    .preview {
      width: 150px;
      height: 150px;
    }
  }
}

@media (max-width: 320px) {
  .modal-crop-image-container {
    align-items: center;
    flex-direction: column;

    &.active-circle {
      .cropper-container {
        width: 200px;
        height: 250px;
      }

      .preview {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
    }

    &.active-square {
      .cropper-container {
        width: 200px;
        height: 100px;
      }
      .preview {
        width: 100px;
        height: 100px;
      }
    }

    &.active-rectangle {
      .cropper-container {
        width: 200px;
        height: 100px;
      }

      .preview {
        width: 150px;
        height: 100px;
      }
    }

    .cropper-container {
      width: 200px;
      height: 250px;
      margin-bottom: 16px;
    }

    .preview {
      width: 150px;
      height: 150px;
    }
  }
}

</style>