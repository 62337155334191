import {createRouter, createWebHistory} from 'vue-router'

import store from '@/store/index'

import MainLayout from "@/layout/MainLayout.vue";
import MainProfileScreen from "@/components/features/MainProfileScreen.vue";
import EmptyLayout from "@/layout/EmptyLayout.vue";
import LoginScreen from "@/components/features/LoginScreen.vue";
import UserListScreen from "@/components/features/UserListScreen.vue";
import NewsScreen from "@/components/features/NewsScreen.vue";
import CaseScreen from "@/components/features/CaseScreen.vue";
import IndustriesScreen from "@/components/features/IndustriesScreen.vue";
import PracticeScreen from "@/components/features/PracticeScreen.vue";
import ServicesScreen from "@/components/features/ServicesScreen.vue";


import {
    currentAuth,
    DEFAULT_LOGIN_URL,
    DEFAULT_ALERT_SCREEN,
    DEFAULT_ROLE_URL,
    handleAuthMiddleware, ROLE,
} from "@/providers/auth";
import AlertScreen from "@/components/features/AlertScreen.vue";
import PartnerScreen from "@/components/features/PartnerScreen.vue";
import PasswordScreen from "@/components/features/PasswordScreen.vue";
import RatingsScreen from "@/components/features/RatingsScreen.vue";

const routes = [
    {
        path: '/',
        name: 'EmptyLayout',
        component: EmptyLayout,
        children: [
            {
                path: '/',
                name: 'login-screen',
                component: LoginScreen,
            },
            {
                path: '/alert-screen',
                name: 'alert-screen',
                component: AlertScreen,
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'not-found-page',
                component: AlertScreen
            },
            {
                path: '/reset-password',
                props: (route) => ({ query: route.query.q }),
                name: 'password-screen',
                component: PasswordScreen
            },
        ]
    },
    {
        path: '/admin',
        name: 'MainPage',
        component: MainLayout,
        meta: {
            auth: true,
        },
        children: [
            {
                path: '/admin/user-profile',
                name: 'main-user-profile',
                meta: {
                    adminRole: ['ADMIN', 'PERSON', 'MODERATOR']
                },
                component: MainProfileScreen
            },
            {
                path: '/admin/edit-user/:id',
                props: true,
                meta: {
                    adminRole: ['ADMIN']
                },
                name: 'edit-profile',
                component: MainProfileScreen
            },
            {
                path: '/admin/add-user',
                meta: {
                    adminRole: ['ADMIN']
                },
                name: 'add-profile',
                component: MainProfileScreen
            },
            {
                path: '/admin/user-list',
                meta: {
                    adminRole: ['ADMIN']
                },
                name: 'user-list',
                component: UserListScreen
            },
            {
                path: '/admin/news/:page',
                meta: {
                    adminRole: ['ADMIN', 'MODERATOR'],
                    pagination: true
                },
                props: true,
                name: 'news',
                component: NewsScreen,
                children: [
                    {
                        path: ':search',
                        meta: {
                            adminRole: ['ADMIN'],
                            pagination: true
                        },
                        props: true,
                        name: 'news-search',
                        component: NewsScreen
                    },
                ]
            },
            {
                path: '/admin/case/:page',
                meta: {
                    adminRole: ['ADMIN', 'MODERATOR'],
                    pagination: true
                },
                props: true,
                name: 'case',
                component: CaseScreen,
                children: [
                    {
                        path: ':search',
                        meta: {
                            adminRole: ['ADMIN'],
                            pagination: true
                        },
                        props: true,
                        name: 'case-search',
                        component: CaseScreen
                    },
                ]
            },
            {
                path: '/admin/industry/:page',
                meta: {
                    adminRole: ['ADMIN'],
                    pagination: true
                },
                props: true,
                name: 'industry',
                component: IndustriesScreen,
                children: [
                    {
                        path: ':search',
                        meta: {
                            adminRole: ['ADMIN'],
                            pagination: true
                        },
                        props: true,
                        name: 'industry-search',
                        component: PracticeScreen
                    },
                ]
            },
            {
                path: '/admin/practice/:page',
                meta: {
                    adminRole: ['ADMIN'],
                    pagination: true
                },
                props: true,
                name: 'practice',
                component: PracticeScreen,
                children: [
                    {
                        path: ':search',
                        meta: {
                            adminRole: ['ADMIN'],
                            pagination: true
                        },
                        props: true,
                        name: 'practice-search',
                        component: PracticeScreen
                    },
                ]
            },
            {
                path: '/admin/services/:page',
                meta: {
                    adminRole: ['ADMIN'],
                    pagination: true
                },
                props: true,
                name: 'service',
                component: ServicesScreen,
                children: [
                    {
                        path: ':search',
                        meta: {
                            adminRole: ['ADMIN'],
                            pagination: true
                        },
                        props: true,
                        name: 'service-search',
                        component: ServicesScreen
                    },
                ]
            },
            {
                path: '/admin/partners',
                meta: {
                    adminRole: ['ADMIN']
                },
                name: 'partners',
                component: PartnerScreen
            },
            {
                path: '/admin/rating',
                meta: {
                    adminRole: ['ADMIN']
                },
                name: 'rating',
                component: RatingsScreen
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0, behavior: 'smooth' }
        }
    },
})


router.beforeEach(async (to, from, next) => {
    await handleAuthMiddleware();
    if (to.meta?.auth === true) {
        if (currentAuth.loggedIn) {
            store.dispatch('IS_LOADING', true).then();
            if(to.path === '/') {
                return next(DEFAULT_ALERT_SCREEN)
            } else {
                if(to.meta?.adminRole.includes(ROLE)) {
                    return next()
                } else {
                    return next(DEFAULT_ROLE_URL[ROLE])
                }
            }
        } else {
            return next(DEFAULT_LOGIN_URL)
        }
    } else {
        return next()
    }
});

export default router
