import {api, isApiAuthTokenSet, removeApiAuthToken, setApiAuthToken} from "@/providers/api";
import store from "@/store";
export const AUTH_ACCESS_TOKEN_KEY = 'auth.access_token';
export const DEFAULT_LOGIN_URL = '/'
export const DEFAULT_ALERT_SCREEN = '/alert-screen'

export let ROLE = ''

export const DEFAULT_ROLE_URL = {
    ADMIN: '/admin/user-profile',
    MODERATOR: '/admin/user-profile',
    PERSON: '/admin/user-profile'
}
export const currentAuth = {
    user: null,
    loggedIn: false,
}

export function getUserAuthToken() {
    return localStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
}

export function setUserAuthToken(token) {
    return localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token)
}

export function removeUserAuthToken() {
    return localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY)
}

 export function setCurrentUser(user) {
    currentAuth.user = user;
    currentAuth.loggedIn = user !== null && user !== undefined
    ROLE = user.adminRole
}

export async function handleAuthMiddleware() {

    const authToken = getUserAuthToken()
    if (authToken) {
        if (!isApiAuthTokenSet(authToken)) {
            setApiAuthToken(getUserAuthToken())
            if (!currentAuth.user) {
                try {
                    let currentUser = await api.auth.authControllerGetMe().then(r => r.data)
                    await store.dispatch('getMe', currentUser)
                    setCurrentUser(currentUser)
                } catch (err) {
                    if (err.status === 401) {
                        removeUserAuthToken()
                        removeApiAuthToken()
                        setCurrentUser(null)
                    }
                }
            }
        }
    } else {
        if (!isApiAuthTokenSet(undefined))
            removeApiAuthToken()
    }
}

export function handleAuthorizedRoute() {
}
