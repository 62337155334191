import {api} from "@/providers/api";

export const ratingModules = {
    state: () => ({
        ratingList: []
    }),
    getters:{
        ratingAll: (state) => {
            return state.ratingList
        }
    },
    mutations: {
        SET_RATING(state, practiceInfo) {
            state.ratingList = practiceInfo
        }
    },
    actions: {
        async getRating({commit}, { params } = { params: {}}) {
            let response = await api.partner.partnerControllerGet({
                type: 'SVG'
            }).then(r => r.data);
            commit('SET_RATING', response)
        },
    }
}