import {api} from "@/providers/api";

export const practiceModules = {
    state: () => ({
        practiceList: undefined,
        totalCountPractice: 0,
        // currentPage: 0

    }),
    getters:{
        practiceAll: (state) => {
            return state.practiceList
        },
        totalCountPracticeAll: (state) => {
            return state.totalCountPractice
        },
        // currentPageAll: (state) => {
        //     return state.currentPage
        // }
    },
    mutations: {
        SET_PRACTICE(state, practiceInfo) {
            state.practiceList = practiceInfo
        },
        SET_TOTAL_COUNT_PRACTICE(state, industryInfo) {
            state.totalCountPractice = industryInfo
        },
        // SET_CURRENT_PAGE(state, industryInfo) {
        //     state.currentPage = industryInfo
        // },
    },
    actions: {
        async getPractice({commit}, { params } = { params: {}}) {
            let response = await api.practice.practiceControllerGet({
                ...params
            }).then(r => r.data);
            commit('SET_PRACTICE', response?.data)
            commit('SET_TOTAL_COUNT_PRACTICE', response?.meta?.total)
            // commit('SET_CURRENT_PAGE', response?.meta?.currentPage)
        }
    }
}