<template>
  <div class="date-publication-container">
    <sub-title>{{ titleDatePublication }}</sub-title>
    <dropdown-date>
      <VueDatePicker v-model="dateValue" :flow="flow" :placeholder="datePlaceholder" :format="format"></VueDatePicker>
    </dropdown-date>
  </div>
</template>

<script>
import DropdownDate from "@/components/shared/DropdownDate.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
export default {
  name: "DatePublication",
  components: {SubTitle, DropdownDate, VueDatePicker},
  props: {
    activeValue: {
      required: true
    }
  },
  data() {
    return {
      titleDatePublication: 'Дата публикации',
      datePlaceholder: 'Дата публикации',
      dateValue: '',
      flow: ['month', 'year', 'calendar', 'hours', 'minutes'],
      activeState: false,
      formatNew: ''
    }
  },
  computed: {
    activeEdit() {
      return this.activeValue !== undefined && this.activeValue !== null
    },
    format(){
      let newDate;
      if(this.dateValue === '') {
        newDate = new Date();
      } else {
        newDate = this.dateValue
      }
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();
      return `${day}/${month}/${year}, ${hours}:${minutes} `;
    }
  },
  mounted() {
    this.checkDatePublication();
  },
  watch: {
    dateValue() {
      if(this.activeEdit) {
        this.datePlaceholder !== this.dateValue
            ? this.$emit('changeDatePublication', this.dateValue.toISOString(), false)
            : this.$emit('changeDatePublication', this.dateValue.toISOString(), true)
      } else {
        this.$emit('changeDatePublication', this.dateValue.toISOString(), false)
      }
    }
  },
  methods: {
    checkDatePublication() {
      if(this.activeEdit) {
        let date = new Date(this.activeValue);
        let format = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          hours: date.getHours(),
          minutes: date.getMinutes()
        }
        this.datePlaceholder = `${format.day}/${format.month}/${format.year}, ${format.hours}:${format.minutes}`;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.date-publication-container {
  margin-bottom: 32px;
  .text-content {
    margin-bottom: 8px;
  }
  .dropdown-container {
    max-width: 156px;
  }
}
</style>