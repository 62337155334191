<template>
  <div class="card-partners-container">
    <frame-input-file v-if="isLoadingPhoto"
                      :activeRating="activeRating"
                      @changeFile="changeFilePreview"
                      :accept-file="'.svg'"
                      :active-cropped-modal="false"
                      :active-square="true"
                      :active-partners-content="true"
                      :active-circle="false"
                      :placeholder-text="nameInputFile"
                      :active-image="resultPhoto"/>
    <card-partners-text @changeData="checkRuInfo"
                        :activeRating="activeRating"
                   :active-partners-list="ruItemActive"
                   active-language="ru"/>
    <card-partners-text @changeData="checkEnInfo"
                        v-if="!activeRating"
                        :activeRating="activeRating"
                   :active-partners-list="enItemActive"
                   active-language="en"/>
    <div class="button-submit-container">
      <transition name="fade-opacity-lazy">
        <sub-title class="alert-content" v-if="activeStateAlert">
          {{ alertText }}
        </sub-title>
      </transition>
    <button-main :active-button="false"
                 @click="addResult"
                 :disabled-button="disabledButton">
      {{ nameSubmitButton }}
    </button-main>
    </div>
  </div>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import CardPartnersText from "@/components/entities/CardPartnersText.vue";
import FrameInputFile from "@/components/entities/FrameInputFile.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import axios from "axios";

export default {
  name: "CardPartners",
  components: {SubTitle, FrameInputFile, CardPartnersText, ButtonMain},
  props: {
    activeItemList: {
      default: undefined,
      required: true
    },
    activeRating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      resultModificationRu: {},
      resultModificationEn: {},
      resultPhoto: '',
      nameSubmitButton: 'Сохранить',
      nameInputFile: 'Загрузить лого',
      disabledButtonAll: {
        ru: true,
        en: true,
        photo: true
      },
      activeItemListState: false,
      activeStateAlert: false,
      alertText: '',
      isLoadingPhoto: false
    }
  },
  computed: {
    disabledButton() {
      if(this.activeItemListState) {
        return !Object.values(this.disabledButtonAll).includes(false)
      } else {
        return !Object.values(this.disabledButtonAll).every(item => item === false)
      }
    },
    enItemActive() {
      if(this.activeItemListState) {
        return {
          lang: 'en',
          name: this.activeItemList?.eng
        };
      }
    },
    ruItemActive() {
      if(this.activeItemListState) {
        return {
          lang: 'ru',
          name: this.activeItemList?.ru
        };
      }
    },
  },
  mounted() {
    this.checkMountedItem();
    if(this.activeRating) {
      this.disabledButtonAll.en = false
    }
  },
  methods: {
    async fetchDataPhoto() {
      await axios.get(this.activeItemList?.fileUrl).then(r => this.resultPhoto = r.data).finally(() => this.isLoadingPhoto = true)
    },

    checkMountedItem() {
      this.activeItemListState = this.changeActiveItemListState(this.activeItemList);
      if(this.activeItemList) {
        this.resultModificationRu = JSON.parse(JSON.stringify(this.ruItemActive));
        this.resultModificationEn = JSON.parse(JSON.stringify(this.enItemActive));
        this.fetchDataPhoto();
        // this.resultPhoto = this.activeItemList?.fileUrl;
      } else {
        this.isLoadingPhoto = true
      }
    },

    changeFilePreview(item) {
      this.resultPhoto = item
      this.disabledButtonAll.photo = false
    },

    checkRuInfo(item, disabledButton) {
      this.resultModificationRu = item;
      this.disabledButtonAll.ru = disabledButton

    },

    checkEnInfo(item, disabledButton) {
      this.resultModificationEn = item;
      this.disabledButtonAll.en = disabledButton
    },

    addResult() {
      if(this.disabledButton) {
        this.checkAlert();
      } else {
        let resultObj = {
          ru: this.resultModificationRu.name,
          en: this.resultModificationEn.name,
          photo: this.resultPhoto
        };
        this.$emit('addResult', resultObj, this.activeItemListState)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .card-partners-container {
    padding: 24px;
    margin-top: 32px;
    position: relative;
    .input-file-container {
      margin: 0 auto;
    }


    .button-submit-container {
      justify-content: center;
      display: flex;
      align-items: center;
      position: relative;
      .alert-content {
        position: absolute;
        bottom: -32px;
      }
    }
  }

  @media (max-width: 769px) {
    .card-partners-container {
      padding: 0;

      .button-submit-container {
        .alert-content {
          bottom: -24px;
        }
      }
    }
  }
</style>