import {api} from "@/providers/api";

export const industryModules = {
    state: () => ({
        industryList: undefined,
        totalCountIndustry: 0,
        // currentPage: 0
    }),
    getters:{
        industryAll: (state) => {
            return state.industryList
        },
        totalCountIndustryAll: (state) => {
            return state.totalCountIndustry
        },
        // currentPageAll: (state) => {
        //     return state.currentPage
        // }
    },
    mutations: {
        SET_INDUSTRY(state, industryInfo) {
            state.industryList = industryInfo
        },
        SET_TOTAL_COUNT_INDUSTRY(state, industryInfo) {
            state.totalCountIndustry = industryInfo
        },
        // SET_CURRENT_PAGE(state, industryInfo) {
        //     state.currentPage = industryInfo
        // },
    },
    actions: {
        async getIndustry({commit}, { params } = { params: {}}) {
            console.log(params)
            let response = await api.industry.industryControllerGet({
                ...params
            }).then(r => r.data);
            commit('SET_INDUSTRY', response?.data)
            commit('SET_TOTAL_COUNT_INDUSTRY', response?.meta?.total)
            // commit('SET_CURRENT_PAGE', response?.meta?.currentPage)
        }
    }
}