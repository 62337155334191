<template>
  <p class="text-content main-text">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainParagraph"
}
</script>

<style scoped lang="scss">
.text-content {
&.main-text {
   font-size: 14px;
 }
}
</style>