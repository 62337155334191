import {api} from "@/providers/api";

export default {
    methods: {
        async editInfoNewsCaseText(id,itemResult, itemActive) {
            let initialValueEnState = itemActive?.translations.find(item => item.lang === 'en');
            let ruResult = itemResult.find(item => item?.lang === 'ru');
            let enResult = itemResult.find(item => item?.lang === 'en');
            if(enResult === undefined) {
                if(initialValueEnState) {
                    if(ruResult === undefined) {
                        console.log('remove en')
                        try {
                            return await Promise.all([
                                api.post.postControllerDeleteTranslation(id, 'en').then(r => r.data)
                            ])
                        } catch (e) {
                            console.log('remove en', e)
                        }
                    } else {
                        console.log('remove en, patch ru')
                        try {
                            return await Promise.all([
                                api.post.postControllerDeleteTranslation(id, 'en').then(r => r.data),
                                api.post.postControllerPatchTranslation(id, {...ruResult}).then(r => r.data)
                            ])
                        } catch (e) {
                            console.log('remove en', e)
                        }
                    }
                } else {
                    console.log('patch ru')
                    try {
                        return await api.post.postControllerPatchTranslation(id, {...ruResult}).then(r => r.data)
                    } catch (e) {
                        console.log('patch ru', e)
                    }
                }
            } else {
                if(initialValueEnState) {
                    if(ruResult === undefined) {
                        console.log('patch en')
                        try {
                            return await Promise.all([
                                api.post.postControllerPatchTranslation(id, {...enResult}).then(r => r.data)
                            ])
                        } catch (e) {
                            console.log('patch en/ru',e)
                        }
                    } else {
                        console.log('patch en/ru')
                        try {
                            return  await Promise.all([
                                api.post.postControllerPatchTranslation(id, {...ruResult}).then(r => r.data),
                                api.post.postControllerPatchTranslation(id, {...enResult}).then(r => r.data)
                            ])
                        } catch (e) {
                            console.log('patch en/ru',e)
                        }
                    }

                } else {
                    if(ruResult !== undefined) {
                        console.log('patch ru, add en')
                        try {
                            return await api.post.postControllerPatchTranslation(id, {...ruResult}).then(r => r.data)
                        } catch (e) {
                            console.log('patch ru, add en',e)
                        } finally {
                            await this.addEn(id, enResult)
                        }
                    } else {
                        console.log('add en')
                        return await this.addEn(id, enResult)
                    }
                }
            }
        },

        async addEn(id, enResult) {
            try {
                return await api.post.postControllerCreateTranslation(id, {...enResult}).then(r => r.data)
            } catch (e) {
                console.log(e)
            }
        },

        async editPracticeDatePublish(id, item) {
            try {
                return await api.post.postControllerPatchPost(id, {...item}).then(r => r.data)
            } catch (e) {
                console.log(e)
            }
        },

        async deleteBackendInfoNewsCase(id) {
            this.IS_LOADING_SEARCH(true)
            try {
                await api.post.postControllerDeletePost(id).then(r => r.data).finally(() => {
                    this.IS_LOADING_SEARCH(false)
                    this.idItem = -1
                    this.activeState = false
                    this.checkInfoPage(this.page);
                    this.activeModalTitle =  this.typeTitle + ' успешно удалён' + this.activeEnding
                    this.activeModal = true
                });
            } catch (e) {
                console.log(e)
            }
        },

        async addInfoNewsCase(itemText, itemAll) {
            this.IS_LOADING_SEARCH(true)
            try {
                await api.post.postControllerCreate({...itemText}).then(async r => {
                        let arrayOfPromises = [];
                        let responseId = r?.data?.id
                        if(itemAll.preview !== '') {
                            let promisePreview = await this.createPhotoBlob(responseId, itemAll.preview, 'preview')
                            arrayOfPromises.push(promisePreview)
                        }
                        if(itemAll.photo.length !== 0) {
                            for (const item of itemAll.photo) {
                                let promisePhoto = await this.createPhotoBlob(responseId, item.img, 'photo');
                                arrayOfPromises.push(promisePhoto);
                            }
                        }
                        await Promise.all(arrayOfPromises).then(r => {
                            this.IS_LOADING_SEARCH(false)
                            this.idItem = -1
                            this.activeState = false
                            this.checkInfoPage(this.page);
                            this.activeModalTitle =  this.typeTitle + ' успешно добавлен' + this.activeEnding
                            this.activeModal = true
                        })
                });
            } catch (e) {
                console.log(e)
            }
        },

        async createPhotoBlob(id, { canvas, fileTarget }, destination) {
            return new Promise((resolve, reject) =>  {
                return canvas.toBlob(async blob => {
                    await this.uploadPhoto(id, {file: fileTarget, blob: blob}, destination).then((response) => {
                        resolve(response.data);
                    })
                        .catch((error) => {
                            reject(error);
                        });
                }, fileTarget.type);
            })
        },

        async uploadPhoto(id, { file, blob }, destination) {
            let formData = new FormData();
            formData.append('croppedImage', blob, file.name);
            try {
                 return await api.post.postControllerAddPhoto(id, destination, {photo: formData.get('croppedImage')}, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(r => r);
            } catch (e) {
                console.log(e)
            }
        },


        async deletePhotoCard(id) {
            try {
                return await api.post.postControllerDeletePhotoById(id);
            } catch (e) {
                console.log(e)
            }
        },

        async editInfoNewsCase(id, item,disabledButton, activeItem) {
            this.IS_LOADING_SEARCH(true)
            let resultText = item.translations;
            let resultMorePhoto = item.photo.filter(item => item.dataURL !== undefined)
            try {
                let responseArray =  {};

                if(resultMorePhoto && resultMorePhoto.length !== 0) {
                    responseArray.photoMorePhoto = []
                    for (const itemMorePhoto of resultMorePhoto) {
                        console.log('photo', itemMorePhoto)
                        const promisePhoto = await this.createPhotoBlob(id, itemMorePhoto.img, 'photo');
                        responseArray.photoMorePhoto.push(promisePhoto);
                    }
                    console.log(responseArray.photoMorePhoto)
                }


                if(item.preview?.canvas) {
                    responseArray.photoPreview = '';
                    responseArray.photoPreview = await this.createPhotoBlob(id, item.preview, 'preview');
                }

                if(item.deletePhotoId.length !== 0) {
                    responseArray.photoDelete = []
                    for (const itemDelete of item.deletePhotoId) {
                        console.log('delete', itemDelete)
                        let responseDelete = await this.deletePhotoCard(itemDelete)
                        responseArray.photoDelete.push(responseDelete)
                        console.log(responseArray.photoDelete)
                    }
                }
                if(!disabledButton.practiceRelated || !disabledButton.datePublication || !disabledButton.personRelated) {
                    console.log('activePPD')
                    responseArray.practicePersonDate = '';
                    responseArray.practicePersonDate = await this.editPracticeDatePublish(id,{
                            users: item.users,
                            practices: item.practices,
                            publishDate: item.publishDate,
                            slug: item.slug
                    })
                    console.log(responseArray.practicePersonDate)
                }
                responseArray.text = await this.editInfoNewsCaseText(id, resultText, activeItem)
                console.log(responseArray.text)
                console.log(responseArray)
                await Promise.all([
                    responseArray?.text,
                    responseArray?.practicePersonDate,
                    responseArray?.photoPreview,
                    responseArray?.photoDelete,
                    responseArray?.photoMorePhoto,
                ]).then(r => {
                    this.IS_LOADING_SEARCH(false)
                    this.idItem = -1
                    this.activeState = false
                    this.checkInfoPage(this.page);
                    this.activeModalTitle =  this.typeTitle + ' успешно отредактирован' + this.activeEnding
                    this.activeModal = true
                })
            } catch (e) {
                console.log(e)
            }
        },

        async addResultPost(id, item, typeCRUD, disabledButton, type, activeItem) {
            if(typeCRUD) {
                await this.editInfoNewsCase(id, item, disabledButton, activeItem)
            } else {
                let result = {
                    type: type,
                    translations: item.translations,
                    users: item.users,
                    practices: item.practices,
                    publishDate: item.publishDate,
                    slug: item.slug
                }
                await this.addInfoNewsCase(result, item)
            }
        },
    },
}