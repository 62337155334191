<template>
  <div class="title-info-card-container">
    <div class="title-info-content">
      <main-title>{{mainTitleDOM}}</main-title>
      <input-main :active-glass="false"
                  @changeTextInput="changeTextInput"
                  :active-text-input="activeMainTitle"
                  :name-block="activeLanguage"
                  :length-text-area="lengthInputTitle"
                  :info="inputInfo[0]"/>
    </div>
    <div class="title-info-content">
      <sub-title>{{subTitleDOM}}</sub-title>
      <input-main :active-glass="false"
                  @changeTextInput="changeTextInput"
                  :active-text-input="activeSubTitle"
                  :name-block="activeLanguage"
                  :length-text-area="500"
                  :info="inputInfo[1]"/>
    </div>
  </div>

</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import InputMain from "@/components/shared/InputMain.vue";

export default {
  name: "CardTitleInput",
  components: {InputMain, SubTitle, MainTitle},
  props: {
    mainTitleDOM: {
      type: String,
      required: true
    },
    subTitleDOM: {
      tye: String,
      required: true
    },
    inputInfo: {
      type: Array,
      required: true
    },
    lengthInputTitle: {
      type: Number,
      default: 40
    },
    activeLanguage: {
      type: String,
      required: true
    },
    activeMainTitle: {
      default: undefined,
      required: true
    },
    activeSubTitle: {
      default: undefined,
      required: true
    },
    langName: {
      type: String
    }
  },
  data() {
    return {
      inputActiveInfo: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.activeMainTitle === undefined && this.activeSubTitle === undefined) {
        this.inputActiveInfo[this.langName] = this.activeLanguage;
      }
    })
  },
  methods: {
    changeTextInput(text, dataIndex) {
      this.inputActiveInfo[dataIndex] = text;
      this.$emit('changeInfo', this.inputActiveInfo)
    },
  }
}
</script>

<style scoped lang="scss">
.title-info-card-container {
  margin-bottom: 16px;
  .title-info-content {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
    .text-content {
      margin-bottom: 12px;
    }
  }
}
</style>