import {api} from "@/providers/api";

export default {
    methods: {

        async addResultMainProfile(id, item, typeCRUD) {
            this.IS_LOADING(true)
            if(typeCRUD) {
                await this.editInfoMainProfile(id,item);
            } else {
                await this.addInfoMainProfile(item);
            }
        },

        async addInfoMainProfile(item) {
            let textInfo = {
                roles: item.profile?.roles || [],
                educations: item.profile?.educations,
                profiles: item.profile?.profiles,
                slug: item.profile?.slug,
                email: item.profile?.email
            }


            await api.admin.userAdminControllerCreateUser(textInfo).then(async r => {
                let responseProfileId = r.data.id
                let responseArray = {};
                let enResultAdd = item.publication.en.filter((item, index) => item.id === undefined)
                let ruResultAdd = item.publication.ru.filter((item, index) => item.id === undefined)
                responseArray.addPublication = await this.addResponsePublication(responseProfileId, enResultAdd, ruResultAdd);
                if(item?.avatar !== '') {
                    responseArray.avatar = await this.createPhotoBlobMainProfile(responseProfileId, item?.avatar, 'avatar');
                }

                if(item?.profilePhoto !== '') {
                    responseArray.profile = await this.createPhotoBlobMainProfile(responseProfileId, item?.profilePhoto, 'profile');
                }

                if(item?.practice.length !== 0) {
                    responseArray.practice= await this.addPractice(responseProfileId, item?.practice)
                }

                await Promise.all([
                    responseArray?.addPublication,
                    responseArray?.avatar,
                    responseArray?.profile,
                    responseArray?.practice
                ]).catch( e => {
                    console.log(e)
                }).then(r => {
                    this.$router.go(0);
                }).finally(() => {
                    this.IS_LOADING(false)
                })
            })
        },

        async addResponsePublication(id, enResultAdd, ruResultAdd) {
            let responseArray = [];
            if(enResultAdd.length !== 0 || ruResultAdd.length !== 0) {
                responseArray.addPublication = []
                if(enResultAdd.length !== 0) {
                    for (const item of enResultAdd) {
                        let response = await this.addPublication(id, item)
                        responseArray.push(response)
                    }
                }

                if(ruResultAdd.length !== 0) {
                    for (const item of ruResultAdd) {
                        let response = await this.addPublication(id, item)
                        responseArray.push(response)
                    }
                }
            }
            return responseArray
        },


        async editInfoMainProfile(id, item) {
            let responseArray = {};

            let enResultAdd = item.publication.en?.filter((item, index) => item.id === undefined)
            let ruResultAdd = item.publication.ru?.filter((item, index) => item.id === undefined)
            let enResultRemove = item.activePublication.en?.filter(itemActive => !item.publication.en?.some(item => itemActive.id === item.id))?.map(item => item.id) || [];
            let ruResultRemove = item.activePublication.ru?.filter(itemActive => !item.publication.ru?.some(item => itemActive.id === item.id))?.map(item => item.id) || [];
            let enResultEdit = item.publication.en?.filter((item) => item.id !== undefined && item.text !== undefined)
            let ruResultEdit = item.publication.ru?.filter((item) => item.id !== undefined && item.text !== undefined)
            console.log(enResultRemove)


            responseArray.addPublication = await this.addResponsePublication(id, enResultAdd, ruResultAdd);


            if(enResultEdit.length !== 0 || ruResultEdit.length !== 0) {
                responseArray.editPublication = []
                if(enResultEdit.length !== 0) {
                    for (const item of enResultEdit) {
                        let response = await this.editTranslations(item)
                        responseArray.editPublication.push(response)
                    }
                }

                if(ruResultEdit.length !== 0) {
                    for (const item of ruResultEdit) {
                        let response = await this.editTranslations(item)
                        responseArray.editPublication.push(response)
                    }
                }
            }

            if(enResultRemove?.length !== 0 || ruResultRemove?.length !== 0) {
                responseArray.removePublication = []
                if (enResultRemove.length !== 0) {
                    for (const item of enResultRemove) {
                        let response = await this.removeTranslations(item)
                        responseArray.removePublication.push(response)
                    }
                }

                if (ruResultRemove.length !== 0) {
                    for (const item of ruResultRemove) {
                        let response = await this.removeTranslations(item)
                        responseArray.removePublication.push(response)
                    }
                }
            }

            let removeIdProfile = item.profile?.profiles.map(item => {
                const { id, userId, ...newItem } = item;
                return newItem;
            })


            let textInfo = {
                        roles: item.profile?.roles,
                        educations: item.profile?.educations,
                        profiles: removeIdProfile,
                        slug: item.profile?.slug,
                        email: item.profile?.email
            }

            responseArray.text =  await api.admin.userAdminControllerPatch(id, textInfo).then(r => r.data)

            if(item?.avatar !== '') {
                responseArray.avatar = await this.createPhotoBlobMainProfile(id, item?.avatar, 'avatar');
            }

            if(item?.profilePhoto !== '') {
                responseArray.profile = await this.createPhotoBlobMainProfile(id, item?.profilePhoto, 'profile');
            }

            if(item?.practice.length !== 0) {
                responseArray.practice = await this.addPractice(id, item?.practice)
            }


            await Promise.all([
                responseArray?.text,
                responseArray?.addPublication,
                responseArray?.editPublication,
                responseArray?.removePublication,
                responseArray?.avatar,
                responseArray?.profile,
                responseArray?.practice
            ]).catch( e => {
                console.log(e)
            }).then(r => {
                this.$router.go(0);
            }).finally(() => {
                this.IS_LOADING(false)
            })
        },

        async createPhotoBlobMainProfile(id, { canvas, fileTarget }, destination) {
            if(destination === 'profile') {
                return new Promise((resolve, reject) =>  {
                    return canvas.toBlob(async blob => {
                        await this.uploadPhotoMainProfile(id, {file: fileTarget, blob: blob}, destination).then((response) => {
                            resolve(response.data);
                        })
                            .catch((error) => {
                                reject(error);
                            });
                    }, fileTarget.type);
                })
            } else if (destination === 'avatar') {
                return new Promise((resolve, reject) =>  {
                    return canvas.toBlob(async blob => {
                        await this.uploadPhotoAvatar(id, {file: fileTarget, blob: blob}).then((response) => {
                            resolve(response.data);
                        })
                            .catch((error) => {
                                reject(error);
                            });
                    }, fileTarget.type);
                })
            }
        },

        async uploadPhotoMainProfile(id, { file, blob }) {
            let formData = new FormData();
            formData.append('croppedImage', blob, file.name);
            try {
                return await api.admin.userAdminControllerUpdateProfilePhotoById(id, {avatar: formData.get('croppedImage')}, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(r => r);
            } catch (e) {
                console.log(e)
            }
        },
        async uploadPhotoAvatar(id, { file, blob }) {
            let formData = new FormData();
            formData.append('croppedImage', blob, file.name);
            try {
                return await api.admin.userAdminControllerUpdateProfileAvatarById(id, {avatar: formData.get('croppedImage')}, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(r => r);
            } catch (e) {
                console.log(e)
            }
        },


        async addPublication(id, item) {
            let resultPublication = {
                translations: item,
                userId: id,
                text: item.text,
                lang: item.lang
            }
            try {
                return await api.admin.userAdminControllerPostPublication(resultPublication)
            } catch (e) {
                console.log(e)
            }
        },

        async addPractice(id, item) {
            try {
                return await api.admin.userAdminControllerSetPractice(id,  {ids: JSON.parse(JSON.stringify(item))})
            } catch (e) {
                console.log(e)
            }
        },

        async editTranslations(item) {
            try {
                return await api.admin.userAdminControllerPatchPublication({lang: item.lang, publicationId: item.id }, {
                    text: item.text
                })
            } catch (e) {
                console.log(e)
            }
        },
        async removeTranslations(id) {
            try {
                return await api.admin.userAdminControllerDeletePublication(id)
            } catch (e) {
                console.log(e)
            }
        }
    },
}