import debounce from "debounce";
import {mapActions} from "vuex";

export default {
    methods: {
        ...mapActions(['IS_LOADING', 'IS_LOADING_SEARCH']),

        isValidInput(obj) {
            return Object.values(obj).every(v => v.length)
        },

        checkLangUserInfo(arr, lang) {
            if(arr !== undefined) {
                let result = JSON.parse(JSON.stringify(arr))
                let education = result?.educations?.filter(item => item.lang === lang)
                let profiles = result?.profiles?.find(item => item.lang === lang)
                let publication = result?.publication?.filter(item => item.lang === lang)
                result.educations = education;
                result.profiles = profiles;
                result.publication = publication;
                return result;
            }
        },

        checkKeysList(arr, nameProperty, nameText, lang) {
            if(arr !== undefined) {
                let modificationsArr = JSON.parse(JSON.stringify(arr?.map(item => ({
                    id: item.id,
                    item: item[nameProperty]
                }))));
                if (modificationsArr?.length !== 0) {
                    return modificationsArr?.map(item => ({
                        id: item?.id,
                        name: item.item?.find(item => (item?.langCode || item?.lang) === lang)?.[nameText]
                    }));
                }
            }
        },
        checkKeysListLang(arr, nameProperty, nameText, lang) {
            if(arr !== undefined) {
                let modificationsArr = JSON.parse(JSON.stringify(arr?.map(item => ({
                    id: item.id,
                    item: item[nameProperty]
                }))));
                if(modificationsArr?.length !== 0) {
                    let item = modificationsArr?.map(item => ({id: item?.id, name: item.item?.find(item => (item?.langCode || item?.lang) === lang)?.[nameText], lang: lang}));
                    return item?.filter(item => item?.name !== undefined);
                }
            }

        },

        compareObjects(parentObj, childObj) {
            const keys = Object.keys(childObj);
            for (const key of keys) {
                if (parentObj[key] !== childObj[key]) {
                    return true;
                }
            }
            return false;
        },

        compareArrays(parentObj, childObj) {
            if (parentObj.length !== childObj.length) {
                return false;
            }
            for (let i = 0; i < parentObj.length; i++) {
                if (parentObj[i] !== childObj[i]) {
                    return false;
                }
            }
            return true;
        },


        findById(array, id) {
            return id !== -1 ? array?.find(item => item.id === id) : null;
        },


        checkLangCode(array, search, nameKey, langName) {
            return array[nameKey].find(item =>
                item[langName] === search
            );
        },

        changeSearchText: debounce(function (text) {
            if(text !== this.searchText) {
                this.$store.dispatch('IS_LOADING_SEARCH', true)
                this.searchText = text
            }
        }, 500),

        deepEqual(obj1, obj2) {
            if (obj1 === obj2) {
                return true;
            }
            if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
                return false;
            }
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
            if (keys1.length !== keys2.length) {
                return false;
            }

            for (let key of keys1) {
                if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }
            return true;
},
        async checkSearchQuery(commit, searchText) {
            if(searchText.trim() !== '') {
                await this.$store.dispatch(commit, {
                    params: {
                        search: searchText.trim()
                    }
                }).finally(() => this.IS_LOADING_SEARCH(false));
            } else {
                this.checkInfo();
                this.IS_LOADING_SEARCH(false);
            }
        },
    },
    computed: {
        isLoading() {
            return this.$store.state.isLoading;
        }
    }
}