import {api} from "@/providers/api";


export const partnersModules = {
    state: () => ({
        partnersList: []
    }),
    getters:{
        partnersAll: (state) => {
            return state.partnersList
        },
        partnersAllPhoto: (state) => {
            return state.partnersPhoto;
        },
    },
    mutations: {
        SET_PARTNERS(state, practiceInfo) {
            state.partnersList = practiceInfo
        },
        SET_PARTNERS_PHOTO: (state, payload) => {
            state.partnersPhoto = payload
        },
    },
    actions: {
        async getPartners({commit}, { params } = { params: {}}) {
            let response = await api.partner.partnerControllerGet({
                type: 'DEFAULT'
            }).then(r => r.data);
            commit('SET_PARTNERS', response)
        },
    }
}