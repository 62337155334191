<template>
  <main-paragraph class="text-menu-content" :class="{'active' : activeTextMenu}">
    <slot/>
  </main-paragraph>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";

export default {
  name: "TextMenu",
  components: {MainParagraph},
  props: {
    activeTextMenu: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.text-menu-content {
  width: fit-content;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    height: 1px;
    background-color: var(--color-text);
    width: 100%;
    border-radius: 20px;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
  }

  &.active {
    position: relative;
    &:before {
      transform: scaleX(1);
    }
  }
}

@media (max-width: 769px) {
  .text-menu-content {
    &.main-text {
      font-size: 18px;
    }
  }
}


@media (max-width: 375px) {
  .text-menu-content {
    &.main-text {
      font-size: 16px;
    }
  }
}

@media (max-width: 320px) {
  .text-menu-content {
    &.main-text {
      font-size: 13px;
    }
  }
}

</style>