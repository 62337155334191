import { createStore } from 'vuex'
import {serviceModules} from "@/store/serviceModule";
import {practiceModules} from "@/store/practiceModule";
import {industryModules} from "@/store/industryModule";
import {caseModules} from "@/store/caseModule";
import {userModules} from "@/store/userModule";
import {newsModules} from "@/store/newsModule";
import {api} from "@/providers/api";
import {partnersModules} from "@/store/partnersModule";
import {ratingModules} from "@/store/ratingModule";

export default createStore({
  state: {
    activeColor: '-light',
    activeMobileMenu: false,
    isLoading: true,
    isLoadingSearch: false,
    educationLevel: [],
    DEFAULT_PAGE_LIMIT: 5,
    DEFAULT_CURRENT_PAGE: 1,
    activeTheme: ''
  },
  mutations: {
    SET_EDUCATION_LEVEL(state, education) {
      state.educationLevel = education
    },
    SET_ACTIVE_COLOR: (state, payload) => {
      state.activeColor = payload
    },
    SET_ACTIVE_MOBILE_MENU: (state, payload) => {
      state.activeMobileMenu = payload
    },
    SET_IS_LOADING: (state, payload) => {
      state.isLoading = payload
    },
    SET_IS_LOADING_SEARCH: (state, payload) => {
      state.isLoadingSearch = payload
    },
    SET_ACTIVE_THEME: (state, payload) => {
      state.activeTheme = payload
    },
  },
  actions: {
    ACTIVE_COLOR: (context, payload) => {
      context.commit('SET_ACTIVE_COLOR', payload)
    },
    ACTIVE_MOBILE_MENU: (context, payload) => {
      context.commit('SET_ACTIVE_MOBILE_MENU', payload)
    },
    IS_LOADING: (context, payload) => {
      context.commit('SET_IS_LOADING', payload)
    },
    IS_LOADING_SEARCH: (context, payload) => {
      context.commit('SET_IS_LOADING_SEARCH', payload)
    },
    async GET_EDUCATION_LEVEL({commit}, { params } = { params: {}}) {
      let response = await api.admin.userAdminControllerGetLevels({
        ...params
      }).then(r => r.data);
      commit('SET_EDUCATION_LEVEL', response)
    },
    async ACTIVE_THEME({commit}, { params } = { params: {}}) {
      let response = await api.main.mainControllerGetTheme().then(r => r.data);
      commit('SET_ACTIVE_THEME', response.theme === 'DARK')
    }
  },
  modules: {
    news: newsModules,
    user: userModules,
    case: caseModules,
    industry: industryModules,
    practice: practiceModules,
    service: serviceModules,
    partner: partnersModules,
    rating: ratingModules
  }
})
