<template>
  <div class="practice-screen-container main-container" v-if="!isLoading">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                   @cancelInfo="activeModal = false"
                   :sub-title="activeModalTitle"/>
    </transition>

    <list-frame class="practice"
                :num-pagination="totalCountPracticeAll"
                :current-page="Number(page)"
                :active-focus="true"
                :config-list="'pagination'"
                :active-text-input="search"
                @changeSearchText="changeSearchText"
                @editItemInfo="editItemList"
                @addObject="changeStateAddItem"
                @deleteBackendInfo="deleteBackendInfo"
                @changePageList="checkInfoPage"
                :setting-list="settingList"
                :name-block="nameBlock"
                :name-button="mainNameButton"
                :active-add-object="activeState"
                :info-list="modificationsList"
                :main-title-d-o-m="mainTitle"/>
    <transition name="fade-translate-x">
    <card-add-info v-if="activeState"
                   @addResult="addResult"
                   :active-related-list-button="isActiveRelatedButton"
                   :active-item-list="activePracticeItem"
                   :name-id="'practiceId'"
                   :additional-title="'практики'">
      <div class="practice-item-container industry">
        <card-related @changeRelatedCard="changeRelatedCard"
                      :name-block="'industry'"
                      :empty-info-list="industryAll"
                      :active-info-list="activeIndustryItem"
                      :main-title="titleIndustries"/>
      </div>
    </card-add-info>
    </transition>
  </div>
</template>

<script>
import ListFrame from "@/components/entities/FrameListContainer.vue";
import CardAddInfo from "@/components/entities/CardAddInfo.vue";
import CardRelated from "@/components/entities/ListInfo/CardRelated.vue";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/providers/api";
import ModalAlert from "@/components/entities/ModalAlert.vue";

export default {
  name: "PracticeScreen",
  props: ['page', 'search'],
  components: {ModalAlert, CardRelated, CardAddInfo, ListFrame},
  data() {
    return {
      mainTitle: 'Список практик',
      titleIndustries: 'Связанные отрасли',
      titleServices: 'Связанные услуги',

      searchText: '',

      nameButtonType: ' практику',
      nameButtonCRUD: 'Добавить',


      activeModal: false,
      activeModalTitle: '',


      activeState: false,
      isActiveRelatedButton: true,

      settingList: {
        activeTitle: true,
        activeInput: true,
        activeSmallIcon: false,
        activeEdit: true,
        activeButton: true,
      },
      nameBlock: 'practice',

      activePracticeItem: undefined,

      idItem: -1,
      resultIndustryList: []
    }
  },
  async created() {
    await this.checkInfo();
  },

  watch: {
    async searchText() {
      await this.checkInfoPage(1);
    },
    idItem() {
      if(this.idItem !== -1) {
        this.isActiveRelatedButton = true
        this.activePracticeItem =  this.findById(this.practiceAll, this.idItem);
        this.resultIndustryList = this.activeIndustryItem;
      } else {
        this.isActiveRelatedButton = true
        this.activePracticeItem = undefined
        this.resultIndustryList = []
      }
    }
  },

  computed: {
    ...mapGetters(['practiceAll', 'industryAll', 'totalCountPracticeAll']),

    modificationsList() {
      try {
        return this.checkKeysList(this.practiceAll, 'modifications', 'name','ru');
       } catch (e) {
        return undefined
      }
    },

    mainNameButton() {
      return this.nameButtonCRUD.concat(this.nameButtonType);
    },

    activeIndustryItem() {
      return this.activePracticeItem?.industry.map(item => item.id);
    },
  },

  methods: {
    ...mapActions(['getPractice', 'getIndustry', 'IS_LOADING_SEARCH']),

    async checkInfo() {
      try {
        let promiseIndustry;
        if(this.industryAll === undefined) {
          promiseIndustry = await this.getIndustry()
        }
        await Promise.all([this.getPractice({
          params: {
            limit: this.$store.state.DEFAULT_PAGE_LIMIT,
            page: this.page,
            search: this.search || this.searchText
          }
        }), promiseIndustry])
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING(false)
        this.IS_LOADING_SEARCH(false)
      }
    },

    async checkInfoPage(currentPage) {
      if(this.searchText === '') {
        this.IS_LOADING_SEARCH(true)
        this.$router.push({name: 'practice', params: {page: currentPage}}).then(async () => {
          this.$store.dispatch('IS_LOADING', false)
          await this.checkInfo();
        })
      } else {
        await this.checkInfoSearch(currentPage);
      }

    },
    async checkInfoSearch(currentPage) {
      this.$router.push({name: 'practice-search', params: {page: currentPage, search: this.searchText}}).then(async () => {
        this.$store.dispatch('IS_LOADING', false)
        await this.checkInfo();
      })
    },

    addResult(item, typeCRUD) {
      this.IS_LOADING_SEARCH(true)
      let resultList = {
        modifications: item.modifications,
        slug: item.slug,
        industry: this.resultIndustryList,
      }
      if(typeCRUD) {
        this.editInfoPractice(resultList);
      } else {
        this.addInfoPractice(resultList);
      }
    },

    async editInfoPractice(item) {
      try {
        await api.practice.practiceControllerPatch(this.idItem, {...item}).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.nameButtonCRUD = 'Добавить'
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Практика успешно отредактирована'
        this.activeModal = true
      }
    },
    async addInfoPractice(item) {
      try {
        await api.practice.practiceControllerCreate({...item}).then(r => r.data);
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Практика успешно добавлена'
        this.activeModal = true
      }
    },

    async deleteBackendInfo(id) {
      this.IS_LOADING_SEARCH(true)
      try {
        await api.practice.practiceControllerDelete(id).then(r => r.data);
      } catch (e) {
        console.log(e)
      }finally {
        this.IS_LOADING_SEARCH(false)
        this.idItem = -1
        this.activeState = false
        await this.checkInfoPage(this.page);
        this.activeModalTitle =  'Практика успешно удалена'
        this.activeModal = true
      }
    },

    changeRelatedCard(item, state) {
      this.resultIndustryList = item;
      this.isActiveRelatedButton = state
    },

  }
}
</script>

<style scoped lang="scss">
.practice-screen-container {
}
</style>