<template>
  <div class="button-icon-container">
      <img v-if="activeColor" :src="getUrlIcon(imgName + '-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon(imgName + '-light.svg')" alt="icon">
  </div>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: {
    imgName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeAnimation: false,
    }
  },
  mounted() {
    this.activeAnimation = true
  },
}
</script>

<style scoped lang="scss">
  .button-icon-container {
    position: relative;
      width: 24px;
      height: 24px;

    & img {
      width: 100%;
      height: 100%;
    }
  }
</style>