<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped lang="scss">
.loader {
  z-index: 999;
  width: 100%;
  height: 6px;
  display: inline-block;
  background: var(--color-field);
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
}

.loader::after {
  content: '';
  width: 0;
  height: 6px;
  background-color: var(--color-stroke);
  background-size: 15px 15px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animFw 2s ease-in infinite;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
