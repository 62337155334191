<template>
  <editor class="editor-container"
      api-key="x22iupyk0eu1jraguca40yao11g259pd5h6x8y2fb9x3w06w"
      v-model="inputText"
          toolbar_mode="scrolling"
          :init="configEditor"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import skin from '/public/editor/skins/ui/dark-theme/skin.css';
export default {
  name: "EditorContainer",
  props: {
    activeItem: {
      default: undefined,
      required: true
    }
  },
  components: {Editor},
  data() {
    return {
      inputText: '',
      activeSkin: 'oxide',
      editorKey: 0,
      colorText: '',
      configEditor: {
        width: '100%',
        height: 256 + 'px',
        menubar: false,
        resize: true,
        skin: false,
        content: false,
        skin_css: skin,
        content_style: `body {margin: 0; margin: 8px} body * {font-family: font-size: 14px; font-family: 'Raleway-Regular', sans-serif !important;}`,
        icons: 'thin',
        plugins: [
          'code', 'lists', 'checklist', 'link', 'image'
        ],
        toolbar:
            'undo redo | styles | forecolor | bold italic underline strikethrough | code removeformat bullist numlist | link image codesample alignselect blockquote | alignleft aligncenter alignright alignjustify'
      }
    }
  },
  mounted() {
    setTimeout(() => {
        if (this.activeItem !== undefined) {
          this.inputText = this.activeItem;
        }
    }, 1000)
  },
  watch: {
    inputText() {
      this.$emit('changeEditorContent', this.inputText);
    }
  }
}
</script>

<style scoped lang="scss">
.editor-container {
}
</style>