<template>
  <div class="partners-screen-container main-container" v-if="!isLoading">

    <transition name="fade-scale">
      <modal-alert v-if="activeModal"
                   @cancelInfo="activeModal = false"
                   :sub-title="activeModalTitle"/>
    </transition>

    <frame-list-container class="partner" @changeSearchText="changeSearchText"
                          :config-list="'static'"
                          @editItemInfo="editItemList"
                          @addObject="changeStateAddItem"
                          @deleteBackendInfo="deleteBackendInfo"
                          :setting-list="settingList"
                          :name-block="nameBlock"
                          :name-button="mainNameButton"
                          :active-add-object="activeState"
                          :info-list="filterInfo"
                          :main-title-d-o-m="mainTitle"/>
    <transition name="fade-translate-x">
      <card-partners v-if="activeState"
                     @addResult="addResult"
                     :active-item-list="activePartnerItem"/>
    </transition>
  </div>
</template>

<script>
import FrameListContainer from "@/components/entities/FrameListContainer.vue";
import CardPartners from "@/components/entities/CardPartners.vue";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/providers/api";
import ModalAlert from "@/components/entities/ModalAlert.vue";

export default {
  name: "PartnerScreen",
  components: {ModalAlert, FrameListContainer, CardPartners},
  data() {
    return {
      mainTitle: 'Список партнеров',
      searchText: '',

      nameButtonType: ' партнёра',
      nameButtonCRUD: 'Добавить',

      idItem: -1,
      activeState: false,


      activeModal: false,
      activeModalTitle: '',

      settingList: {
        activeTitle: true,
        activeInput: true,
        activeSmallIcon: false,
        activeEdit: true,
        activeButton: true,
      },

      nameBlock: 'partner',
    }
  },

  async created() {
    await this.checkInfo();
  },

  computed: {
    ...mapGetters(['partnersAll', 'partnersAllPhoto']),
    listPartners() {
      try {
        return this.partnersAll.map(item => {
          return {
            id: item.id,
            name: item.ru
          }
        })
      } catch (e) {
        return undefined
      }
    },
    filterInfo() {
      this.IS_LOADING_SEARCH(false)
      return this.listPartners?.filter(item =>
          item?.name.toLowerCase().indexOf(this.searchText?.trim().toLowerCase()) !== -1)
    },
    activePartnerItem() {
      return this.findById(this.partnersAll, this.idItem);
    },

    mainNameButton() {
      return this.nameButtonCRUD.concat(this.nameButtonType);
    },
  },


  methods: {
    ...mapActions(['getPartners', 'IS_LOADING_SEARCH']),
    async checkInfo() {
      try {
        await Promise.all([this.getPartners()])
      } catch (e) {
        console.log(e)
      } finally {
        this.IS_LOADING(false)
      }
    },

    addResult(item, typeCRUD) {
      this.IS_LOADING_SEARCH(true)
      if(typeCRUD) {
        this.ediPartners(this.idItem, item, item.photo)
      } else {
        this.addResultPartners(item, item.photo)
      }
    },

    async addResultPartners(item, itemPhoto) {
      try {
        let formData = new FormData();
        formData.append('croppedImage', itemPhoto, itemPhoto.name);
        await api.partner.partnerControllerCreate({
              photo: formData.get('croppedImage'),
              ru: item.ru,
              eng: item.en,
              partnerType: "DEFAULT"
            }, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(async () => {
          this.IS_LOADING_SEARCH(false)
          await this.checkInfo();
          this.idItem = -1
          this.activeState = false
          this.activeModalTitle =  'Партнер успешно добавлен'
          this.activeModal = true
        })
      } catch (e) {
        console.log(e)
      }
    },

    async ediPartners(id, item, itemPhoto) {
      try {
        let responseArr = {}
        if(typeof itemPhoto !== 'string') {
          let formData = new FormData();
          formData.append('croppedImage', itemPhoto, itemPhoto.name);
          responseArr.photo = await api.partner.partnerControllerPatchImage(id, {
            photo: formData.get('croppedImage'),
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
        responseArr.text = await api.partner.partnerControllerPatch(id, {
          ru: item.ru,
          eng: item.en,
          partnerType: "DEFAULT"
        })
        await Promise.all([responseArr?.photo, responseArr?.text]).then(async () => {
          this.IS_LOADING_SEARCH(false)
          await this.checkInfo();
          this.idItem = -1
          this.activeState = false
          this.activeModalTitle =  'Партнер успешно отредактирован'
          this.activeModal = true
        })
      } catch (e) {
        console.log(e)
      }
    },


    async deleteBackendInfo(id) {
      try {
        await api.partner.partnerControllerDeletePartner(id).then(async r => {
          this.IS_LOADING_SEARCH(false)
          await this.checkInfo();
          this.idItem = -1
          this.activeState = false
          this.activeModalTitle =  'Партнер успешно удалён'
          this.activeModal = true
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card-partners-container {
  width: 100%;
}
</style>