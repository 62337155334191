<template>
  <div class="card-main-profile-container">
    <large-title class="main-title-content">{{mainTitleLanguage}}</large-title>
    <div class="textarea-info-container" :class="activeLanguage === 'en' ? 'en' : 'ru'">
      <div class="textarea-info-content"
           v-for="item in titleTextarea"
           :key="item.id">
        <main-title v-if="item.mainTitle">{{ item.name }} – {{ subTitleLanguage.ru }}</main-title>
        <sub-title v-else>{{ item.name }} – {{ subTitleLanguage.ru }}</sub-title>

        <input-main @changeTextInput="changeTextInput"
                    :active-glass="false"
                    :activeTextInput="resultProfilesList[item.titleDB]"
                    :length-text-area="item.length"
                    :info="item.infoInput"
                    :name-block="activeLanguage"/>
      </div>
    </div>
    <div class="card-edit-list-container">
      <card-edit v-for="item in cardList"
                 @editItemInfo="editCard"
                 @deleteBackendInfo="deleteBackendInfo"
                 @addObject="addCard"
                 :key="item.id"
                 :property-item="item?.text || item"
                 :info-list="checkListContent(item.id)"/>
    </div>

    <transition name="fade-scale">
      <modal-education v-if="activeModalEducation"
                       @submitAddInfo="submitAddInfoEducation"
                       @submitEditInfo="submitEditInfoEducation"
                       @closeModal="changeStateModalEducation"
                       :sub-title-language="subTitleLanguage"
                       :active-item="activeEducationInfo"/>
    </transition>

    <transition name="fade-scale">
    <modal-area v-if="activeModalTextarea"
                @submitAddInfo="submitAddInfoTextarea"
                @submitEditInfo="submitEditInfoTextarea"
                @closeModal="changeStateModalTextarea"
                :active-item="activeTextTextarea"
                :main-title="activeTitleTextarea"/>
    </transition>
  </div>
</template>

<script>
import CardEdit from "@/components/entities/ListInfo/CardEdit.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import ModalEducation from "@/components/entities/ModalEducation.vue";
import ModalAgreement from "@/components/entities/ModalAgreement.vue";
import ModalArea from "@/components/entities/ModalArea.vue";
import {mapGetters} from "vuex";

export default {
  name: "CardMainProfile",
  components: {ModalArea, ModalAgreement, ModalEducation, LargeTitle, InputMain, SubTitle, MainTitle, CardEdit},
  props: {
    activeLanguage: {
      type: String,
      required: true
    },
    activeUserList: {
      default: undefined,
      required: true
    }
  },
  data() {
    return {
      idEdit: -1,
      activeItemListState: false,
      activeEditModal: false,
      resultPublicationList: [],
      resultEducationsList: [],
      resultProfilesList: {
        lang: '',
        fullName: '',
        status: '',
        position: '',
        caption: '',
        shortCaption: '',
        professionalInterests: [],
        awards: []
      },
      activeModalEducation: false,
      activeModalTextarea: false,
      cardList: [
        {
          id: 0,
          activeArr: true,
          title: 'Образование',
          titleDB: 'educations'
        },
        {
          id: 1,
          activeArr: false,
          title: 'Профессиональные интересы',
          titleDB: 'professionalInterests'
        },
        {
          id: 2,
          activeArr: false,
          title: 'Награды',
          titleDB: 'awards'
        },
        {
          id: 3,
          activeArr: true,
          title: 'Публикации',
          titleDB: 'publication'
        },
      ],

      titleTextarea: [
        {
          id: 0,
          name: 'ФИО',
          titleDB: 'fullName',
          mainTitle: true,
          length: 40,
          infoInput: {
            id: 0,
            placeholder: 'Введите текст',
            type: 'textarea',
            dataIndex: 'fullName'
          }
        },
        {
          id: 1,
          name: 'Статус',
          titleDB: 'status',
          mainTitle: true,
          length: 40,
          infoInput: {
            id: 1,
            placeholder: 'Введите текст',
            type: 'textarea',
            dataIndex: 'status'
          }
        },
        {
          id: 2,
          name: 'Должность',
          titleDB: 'position',
          mainTitle: true,
          length: 40,
          infoInput: {
            id: 2,
            placeholder: 'Введите текст',
            type: 'textarea',
            dataIndex: 'position'
          }
        },
        {
          id: 3,
          name: 'Краткое описание персоны – Страница «Команда»',
          titleDB: 'shortCaption',
          mainTitle: false,
          length: 500,
          infoInput: {
            id: 3,
            placeholder: 'Введите текст',
            type: 'textarea',
            dataIndex: 'shortCaption'
          }
        },
        {
          id: 4,
          name: 'Полное описание персоны',
          titleDB: 'caption',
          length: 1000,
          mainTitle: false,
          infoInput: {
            id: 4,
            placeholder: 'Введите текст',
            type: 'textarea',
            dataIndex: 'caption'
          }
        }
      ],

      mainTitleLanguage: '',
      subTitleLanguage: '',

      isNewProfile: false,

      activeTitleTextarea: {
        id: 0,
        name: '',
        length: 40
      },
    }
  },
  mounted() {
    this.checkActiveTitleText();
    this.checkMountedItem();
  },

  watch: {
    disabledButton() {
      this.$emit('changeData', this.resultProfilesList, this.resultPublicationList, this.resultEducationsList, this.disabledButton)
    }
  },

  computed: {
    ...mapGetters(['getEducationLevelAll']),


    disabledButton() {
      if(this.activeItemListState) {
          if(this.allPropertiesDone) {
             return (this.deepEqual(this.resultProfilesList, this.activeUserList?.profiles)
                && this.deepEqual(this.resultPublicationList, this.activeUserList?.publication)
                && this.deepEqual(this.resultEducationsList, this.activeUserList?.educations));
          } else {
            return true
          }
      }
      return this.isNewProfile;
    },

    allPropertiesDone() {
      return this.isValidProfiles
          && this.professionalInterestsLength > 0
          && this.awardsLength > 0
          && this.resultEducationsListLength > 0
          && this.publicationLength > 0
    },

    changeActive() {
      return this.deepEqual(this.resultProfilesList, this.activeUserList.profiles)
    },

    professionalInterestsLength() {
      return this.resultProfilesList.professionalInterests.length;
    },
    awardsLength() {
      return this.resultProfilesList.awards.length;
    },
    resultEducationsListLength() {
      return this.resultEducationsList.length;
    },
    publicationLength() {
      return this.resultPublicationList.length;
    },

    isValidProfiles() {
      return this.isValidInput(this.resultProfilesListWithoutArr)
    },

    resultProfilesListWithoutArr() {
      let { professionalInterests, awards, ...newObj } = this.resultProfilesList
      newObj.lang = this.activeLanguage
      return newObj;
    },


    activeTextTextarea() {
      if(this.idEdit !== -1) {
        let resultNameProfile = this.resultProfilesList?.[this.cardList?.[this.activeTitleTextarea.id]?.titleDB]?.[this.idEdit];
        if(resultNameProfile !== undefined) {
          return {
            id: this.idEdit,
            name: resultNameProfile
          };
        } else {
            return {
              id: this.idEdit,
              name: this.listFramePublication[this.idEdit]?.text || this.listFramePublication[this.idEdit]?.name,
              lang: this.listFramePublication[this.idEdit]?.lang
            };
        }
      } else {
        return undefined
      }
    },


    listFramePublication() {
      if(this.activeItemListState) {
        return this.resultPublicationList.map((item, index) => (
            {
              id: index,
              text: item?.text || item?.name
            }
        ))
      } else {
        return this.resultPublicationList.map((item, index) => (
            {
              id: index,
              text: item
            }
        ))
      }

    },

    listFrameEducation() {
      return this.resultEducationsList.map((item, index) => (
          {
            id: index,
            name: item.universityName
          }
          ))
    },

    activeEducationInfo() {
      if(this.idEdit !== -1) {
        return {
          id: this.idEdit,
          name: this.resultEducationsList[this.idEdit]
        }
      } else {
        return undefined
      }
    }
  },

  methods: {
    checkMountedItem() {
      this.activeItemListState = this.changeActiveItemListState(this.activeUserList);
      if(this.activeItemListState) {
        if(this.activeUserList?.educations.length !== 0) {
          this.resultEducationsList = JSON.parse(JSON.stringify(this.activeUserList.educations));
        }
        if(this.activeUserList?.profiles !== undefined) {
          this.resultProfilesList = JSON.parse(JSON.stringify(this.activeUserList.profiles));
        }
        if(this.activeUserList?.publication !== undefined) {
          this.resultPublicationList = JSON.parse(JSON.stringify(this.activeUserList.publication));
        }
        this.$emit('changeData', this.resultProfilesList, this.resultPublicationList, this.resultEducationsList, this.disabledButton)
      } else {
        this.resultEducationsList.lang = this.subTitleLanguage.en;
        this.resultProfilesList.lang = this.subTitleLanguage.en;
        this.isNewProfile = true
      }
    },


    openModalEdit(id) {
      if(!this.activeEditModal) {
        this.idEdit = -1
      }
      switch(id) {
        case 0:
          this.activeModalEducation = true
          break
        case 1:
          this.activeModalTextarea = true
          this.activeTitleTextarea.id = 1
          this.activeTitleTextarea.name = this.cardList[id].title + ' – ' + this.subTitleLanguage.ru
          this.activeTitleTextarea.length = 40
          break
        case 2:
          this.activeModalTextarea = true
          this.activeTitleTextarea.id = 2
          this.activeTitleTextarea.name = this.cardList[id].title + ' – ' + this.subTitleLanguage.ru
          this.activeTitleTextarea.length = 40
          break
        case 3:
          this.activeModalTextarea = true
          this.activeTitleTextarea.id = 3
          this.activeTitleTextarea.name = this.cardList[id].title + ' – ' + this.subTitleLanguage.ru
          this.activeTitleTextarea.length = 300
          break
      }
    },

    addCard(id) {
      this.activeEditModal = false
      this.openModalEdit(id)
    },

    deleteBackendInfo(id, propertyId) {
        switch (propertyId) {
        case 0:
          this.resultEducationsList.splice(id, 1)
          break
        case 1:
          this.resultProfilesList.professionalInterests.splice(id, 1)
          break
        case 2:
          this.resultProfilesList.awards.splice(id, 1)
          break
        case 3:
          this.resultPublicationList.splice(id, 1)
          break
      }
    },

    checkListContent(id) {
        switch(id) {
          case 0:
            return this.listFrameEducation;
          case 1:
            return this.resultProfilesList['professionalInterests']
          case 2:
            return this.resultProfilesList['awards']
          case 3:
            return this.listFramePublication
        }
    },

    changeStateModalEducation() {
      this.activeModalEducation = false
    },

    changeStateModalTextarea() {
      this.activeModalTextarea = false
    },

    changeTextInput(item, dataIndex) {
      this.resultProfilesList[dataIndex] = item;
    },


    submitEditInfoTextarea(id, propertyId, item) {
      this.changeStateModalTextarea();
      switch (propertyId) {
        case 1:
          this.resultProfilesList.professionalInterests[id] = item;
          break;
        case 2:
          this.resultProfilesList.awards[id] = item;
          break;
        case 3:
          this.resultPublicationList[id].text = item;
          break;
      }
    },

    submitAddInfoTextarea(propertyId, item) {
      this.changeStateModalTextarea();
      switch (propertyId) {
        case 1:
          this.resultProfilesList.professionalInterests.push(item);
          break;
        case 2:
          this.resultProfilesList.awards.push(item);
          break;
        case 3:
          this.resultPublicationList.push({lang: this.activeLanguage, text: item});
          break;
      }
    },

    submitAddInfoEducation(item) {
      this.changeStateModalEducation();
      this.resultEducationsList.push(item);
    },

    submitEditInfoEducation(id, item) {
      this.changeStateModalEducation();
      this.resultEducationsList[id] = item;
    },

    editCard(id, propertyId) {
      this.idEdit = id;
      this.activeEditModal = true;
      this.openModalEdit(propertyId)
    }
  }
}
</script>

<style scoped lang="scss">
  .card-main-profile-container {
    margin-bottom: 32px;
    width: 100%;
    .main-title-content {
      margin-bottom: 32px;
    }

    .textarea-info-container {
      margin-bottom: 32px;
      .textarea-info-content {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
        .text-content {
          margin-bottom: 12px;
        }
      }
    }

    .card-edit-list-container {
      .card-edit-container {
        margin-bottom: 16px;
      }
    }
  }
</style>